import React, { useEffect, useState } from 'react';
import { Col, Container, Row, SvgIcon, TourItemCard } from '../../components/common';
import { Button } from 'antd';
import 'react-calendar/dist/Calendar.css';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import { useSelector } from 'react-redux';
import apiClient from '../../apiConfig';
import Apis from '../../utility/apis';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CustomLoader from '../../components/common/Loader/CustomLoader';
import HeaderFilter from '../../components/common/HeaderFilter/HeaderFilter';
import { useHistory } from 'react-router-dom';
import { Alert } from '../../common/alert';
import { logEvent } from '../../utility/analytics';

const GroupListingPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const rgroupId = searchParams.get('groupId');

  const [groupData, setGroupData] = useState([]);
  const [groupTitle, setGroupTitle] = useState('');
  const [groupImage, setGroupImage] = useState('');

  const userData = useSelector((state) => state.user.user);
  const selectedCity = useSelector((state) => state.countryCity.selectedCity);
  const [showLoader, setshowLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedCity.isGlobaltix === 'Yes') {
          logEvent('global_group_listing', {
            group_id: rgroupId,
          });
        } else {
          logEvent('discover_group_listing', {
            group_id: rgroupId,
          });
        }

        setshowLoader(true);
        const response = await apiClient.post(
          // Fetch data from API
          Apis('listByGroup', selectedCity.vCountryName, userData ? 'loggedIn' : 'guest'),
          {
            iCountryID: selectedCity.iCountryID,
            dCurrentLat: selectedCity.vCityLatitude,
            dCurrentLong: selectedCity.vCityLongitude,
            iCityID: selectedCity.iCityID,
            Language: 'en',
            rgroup_id: rgroupId,
          },
        );
        setshowLoader(false);

        // Extracting rgroup_title and rgroup_image2023 from response
        const { rgroup_title, rgroup_image2023, DATA } = response.data;

        // Set group title and image
        setGroupTitle(rgroup_title);
        setGroupImage(rgroup_image2023);

        // Set group data
        setGroupData(DATA);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData function
    fetchData();
  }, [selectedCity]);

  const handleFavToggle = (index, value, tourId) => {
    setGroupData((prevGroupData) => {
      return prevGroupData.map((item, i) => {
        if (i === index) {
          return { ...item, isFavouriteOffer: value };
        }
        return item;
      });
    });

    apiClient
      .post(Apis('toggleFavTour', selectedCity.vCountryName, userData ? 'loggedIn' : 'guest'), {
        tourId,
        productId: tourId,
        favourite: value,
      })
      .then((response) => {
        if (response.data.status === 1) {
          Alert.success(response.data.MESSAGE);
        }
      });
  };

  return (
    <div className="twl-listing-wrapper">
      {showLoader && <CustomLoader />}
      <section className="listing-bottom">
        <HeaderFilter />
        <Container className="listing-container">
          <Row>
            <Col>
              {groupImage && groupTitle && (
                <div className="group-list-upper">
                  <div className="left-img">
                    <img src={groupImage} alt={groupTitle} />
                  </div>
                  <div className="right-dtls">
                    <h1>{groupTitle}</h1>
                    {/* <div className="bottom-row">
                      <div className="group-stats">
                        <div className="group-stats-img">
                          <img src={groupImage} alt="" />
                        </div>
                        <div>
                          <label>Next</label>
                          <h4>Trending places</h4>
                        </div>
                      </div>
                      <Button>
                        Explore{" "}
                        <SvgIcon name="arrow-right" viewbox="0 0 14 9" />
                      </Button>
                    </div> */}
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="listing-row">
                {groupData &&
                  groupData.map((item, index) => (
                    <TourItemCard
                      key={index}
                      item={item}
                      handleFavToggle={() => {
                        handleFavToggle(
                          index,
                          item.isFavouriteOffer === 0 ? 1 : 0,
                          item.tourId || item.productId,
                        );
                      }}
                    />
                  ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button
                type="primary"
                className="map-btn"
                onClick={() => history.push('/listing-page-map')}
              >
                Map <SvgIcon name="map-icon" viewbox="0 0 14.855 14.695" />
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default GroupListingPage;

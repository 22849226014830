import React from 'react';
import PropTypes from 'prop-types';

import { Navbar } from '../components/layout';

const DefaultLayout = ({ children, navbar, footer }) => (
  <React.Fragment>
    <div className="landing-wrapper">
      {!navbar && <Navbar />}
      <main>{children}</main>
    </div>
  </React.Fragment>
);

DefaultLayout.propTypes = {
  navbar: PropTypes.bool,
  footer: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  navbar: false,
  footer: false,
};

export default DefaultLayout;

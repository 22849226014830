import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import { Col, Container, DownloadSection, Row, SvgIcon } from '../../../components/common';
import { Button, Checkbox, Drawer, Dropdown, Form, Input, Popover, Progress, Select } from 'antd';
import { Link, Redirect, useHistory } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { Gallery } from 'react-grid-gallery';
import Slider from 'react-slick';
import '../../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import apiClient from '../../../apiConfig';
import Apis from '../../../utility/apis';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { debounce } from 'lodash';
import BookingErrorModal from './BookingErrorModal';
import { format } from 'date-fns';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { formatCurrency } from '../../../utility/formatter';
import { htmlToList } from '../../../utility/parser';
import dayjs from 'dayjs';
import { useLoaderContext } from '../../../components/common/Loader/LoaderContext';
import { useDetailsLayoutContext } from '../../../layouts/defaultdetails';
import { Alert } from '../../../common/alert';
import { logEvent } from '../../../utility/analytics';
import { useIsVisible } from '../../../hooks/useIsVisible';

const RaynaDetailsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [counts, setCounts] = useState({
    adult: 1,
    child: 0,
    infant: 0,
  });

  const [pickupLocation, setPickupLocationLocation] = useState('');
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesAutocompleteService({
      debounce: 500,
      apiKey: 'AIzaSyDj-TnaWgaRC17qascC5dKV21Wo0mipmI4',
    });
  const [placesAutoCompleteQuery, setPlacesAutoCompleteQuery] = useState('');

  const handleIncrement = (type) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [type]: prevCounts[type] + 1,
    }));
  };

  const handleDecrement = (type) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [type]: Math.max(prevCounts[type] - 1, 0),
    }));
  };

  // const selectedData = `${counts.adult} Adult${counts.adult > 1 ? "s" : ""}, ${
  //   counts.child
  // } Child${counts.child > 1 ? "ren" : ""}, ${counts.infant} Infant${
  //   counts.infant > 1 ? "s" : ""
  // }`;

  // // console.log(("Selected Data:", selectedData));

  const { setShowLoader } = useLoaderContext();
  const { setShowDetailsFooter } = useDetailsLayoutContext();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  const [tourDetails, setTourDetails] = useState(null);
  const [showAllImages, setShowAllImages] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [descriptionLines, setDescriptionLines] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const tourId = searchParams.get('tourId');

  const [pickupLocationDropOpen, setPickupLocationDropOpen] = useState(false);

  const user = useSelector((state) => state.user.user);

  const selectedCity = useSelector((state) => state.countryCity.selectedCity);

  useEffect(() => {
    const fetchData = async () => {
      logEvent('discover_details', {
        tour_id: tourId,
      });

      try {
        setShowLoader(true);
        setShowDetailsFooter(false);
        const response = await apiClient.post(
          // Fetch data from API
          Apis('tourDetails', 'UAE', user ? 'loggedIn' : 'guest'),
          {
            iCountryID: selectedCity.iCountryID,
            dCurrentLat: selectedCity.vCityLatitude,
            dCurrentLong: selectedCity.vCityLongitude,
            Language: 'en',
            tourId: tourId,
          },
        );

        // Set the fetched tour details to the state
        setTourDetails(response.data?.DATA || null);
        window.scrollTo(0, 0);
        // console.log(("response tourDetails: ", response));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setShowLoader(false);
      setShowDetailsFooter(true);
    };

    if (tourId) {
      fetchData();
    }
  }, []);

  const [selectedTransfer, setSelectedTransfer] = useState(null);
  const [discoverOptions, setDiscoverOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showbtn, setshowbtn] = useState(false);
  const [date, setdate] = useState();
  const [dropdownVisible2, setDropdownVisible2] = useState(false);
  const tourOptionsRef = useRef();
  const tourOptionsVisible = useIsVisible(tourOptionsRef);

  const calenderStartDate = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (selectedOption && selectedOption.specialdates) {
      const { fromDate, toDate } = selectedOption.specialdates;
      const startDate = new Date(fromDate);
      const endDate = new Date(toDate);

      endDate.setHours(0, 0, 0, 0);
      startDate.setHours(0, 0, 0, 0);

      if (selectedOption.specialdates.includerOrExclude === '2') {
        if (today < endDate) {
          endDate.setDate(endDate.getDate() + 1);
          return endDate;
        }
      } else {
        if (today < startDate) {
          return startDate;
        }
      }
    }
    return today;
  }, [selectedOption]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.post('/discover/discoverOptions', {
          tourId: tourId,
        });

        // Set the fetched tour details to the state
        const updatedResponse = response.data?.DATA.map((obj) => ({
          ...obj,
          isChecked: false,
          showCalendar: false,
        }));
        setDiscoverOptions(updatedResponse);
        // console.log(("response tourDetailsxxx: ", updatedResponse));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (user && tourId) {
      fetchData();
    }
  }, [user, tourId]);

  const transferOptions = useMemo(() => {
    if (selectedOption) {
      if (selectedOption.transferIdData.length === 1) {
        setSelectedTransfer(selectedOption.transferIdData[0]);
      }
      return selectedOption.transferIdData;
    }
    return [];
  }, [selectedOption]);

  // // console.log((transferTitles,"transferTitles"));

  // const handleCheckboxChange = (value) => {
  //   setSelectedCheckbox(value);
  //   setVisible(false); // Close the dropdown after selection
  // };

  // console.log(("selectedCheckbox data: ", selectedCheckbox));

  // Function to handle checkbox click
  const handleCheckboxClick = (option, index) => {
    // console.log((option));
    const updatedData = discoverOptions.map((opt, idx) => {
      if (idx === index) {
        return {
          ...opt,
          isChecked: !opt.isChecked,
          showCalendar: !opt.showCalendar,
        };
      } else {
        return {
          ...opt,
          isChecked: false,
          showCalendar: false,
        };
      }
    });
    setDiscoverOptions(updatedData);
    setSelectedOption(option);
    setSelectedDate();
    setBookingData(null);
    setSelectedTimeSlot(null);

    logEvent('discover_option_select', {
      tour_id: option.tourId,
      option_id: option.tourOptionId,
      option_name: option.optionName,
    });

    // setSelectedDate(null)
    // setShowCalendar(true);
    // if (showCalendar) {
    //   setShowCalendar(false);
    // } else {
    //   setSelectedOption(option);
    //   setShowCalendar(true);
    // }
  };
  // Function to handle unchecking of checkbox
  const handleCheckboxUncheck = (index) => {
    setSelectedDate(null); // Clear the selectedDate when the checkbox is unchecked
  };

  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handlePromoCodeChange = (e) => {
    // console.log(("Entered Promo Code:", promoCodeValue));
    setPromoCode(e.target.value);
    debouncedHandleChange(e.target.value);
  };

  const debouncedHandleChange = useCallback(
    debounce(async (value) => {
      // Dispatch the action to update the Redux state
      const response = await apiClient.post('/discover/checkPromoCode', {
        tPromoCode: value,
        tPromoProductId: tourId,
        tSource: 'Discover',
      });
      if (response.data.status === 1) {
        setPromoCodeValue(response.data.DATA);
      } else {
        setPromoCodeValue(null);
      }
    }, 1000), // Adjust the debounce delay as needed (e.g., 300 milliseconds)
    [],
  );
  const [bookingData, setBookingData] = useState(null);
  // console.log((bookingData, "bookingDatabookingData", selectedTimeSlot));
  // Function to fetch booking data
  const fetchBookingData = async (tourOptionId, travelDate, index) => {
    setShowLoader(true);
    try {
      // Get the transferId from the selected option
      const transferId = selectedOption.transferIdData[0]?.transferId; // Assuming the first transferId is the desired one

      const response = await apiClient.post('/discover/discoverBookingData', {
        tourOptionId,
        travelDate,
        transferId,
      });
      if (response.data?.status === 1) {
        setBookingData(response.data?.DATA || null);
        // const updatedData = discoverOptions.map((opt, idx) => {
        //   if (idx === index) {
        //     return {
        //       ...opt,
        //       isChecked: true,
        //       showCalendar: false
        //     };
        //   }
        //   return opt
        // });
        setDiscoverOptions(discoverOptions);
        localStorage.setItem('selectedProduct', JSON.stringify(response.data?.DATA));
        setDropdownVisible2(false);
      } else {
        const updatedData = discoverOptions.map((opt, idx) => {
          if (idx === index) {
            return {
              ...opt,
              isChecked: false,
              showCalendar: false,
            };
          }
          return opt;
        });
        setDiscoverOptions(updatedData);
        setShowModal(true);
        setErrorMessage(response.data?.MESSAGE || 'Oops, something went wrong.');
        setSelectedOption(null);
      }
    } catch (error) {
      setShowModal(true);
      console.error('Error fetching booking data:', error);
    }
    setShowLoader(false);
    setDropdownVisible2(false);
  };

  // console.log((selectedOption, "selectedOptionselectedOption"));
  // Function to check if a date is enabled based on specialdates and operationdays
  const isDateEnabled = (date) => {
    if (!date) return false; // Check if date object is valid
    if (selectedOption.specialdates) {
      const { fromDate, toDate } = selectedOption.specialdates;
      const startDate = new Date(fromDate);
      const endDate = new Date(toDate);
      const inDateRange = date >= startDate && date <= endDate;
      if (selectedOption.specialdates.includerOrExclude === '2') {
        return !inDateRange;
      } else {
        return inDateRange;
      }
    }
    const dayOfWeek = date.getDay(); // Get the day of the week (0 for Sunday, 1 for Monday, ...)

    const dayName = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][
      dayOfWeek
    ];
    return selectedOption.operationdays[dayName] === '1';
  };

  // Function to handle calendar change
  const handleCalendarChange = async (date, option, index) => {
    setSelectedDate(date);
    setShowCalendar(false);
    setSelectedTimeSlot(null);

    const offset = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() - offset);
    const formattedDate = localDate.toISOString().slice(0, 10);

    logEvent('discover_date_select', {
      date: formattedDate,
    });

    // Fetch booking data if the date is enabled
    if (selectedOption && isDateEnabled(date)) {
      await fetchBookingData(selectedOption.tourOptionId, formattedDate, index);

      // After fetching data, set the selected checkbox
      // setSelectedCheckbox(option);
    }
  };

  // Disabled date logic based on specialdates and operationdays
  const isDateDisabled = (date) => {
    // If selectedOption is not set, disable all dates
    const minDate = new Date();
    // Set hours, minutes, seconds, and milliseconds to 0 to compare dates without time
    minDate.setHours(0, 0, 0, 0);
    // Disable dates before today

    if (!selectedOption) return true;

    if (selectedOption.allowTodaysBooking === '0' && +selectedOption.cutOffhrs > 0) {
      minDate.setHours(+selectedOption.cutOffhrs + 24, 0, 0, 0);
    }

    if (date < minDate) {
      return true;
    }

    // Check if the date is enabled based on special dates or operation days
    return !isDateEnabled(date);
  };

  const handleOutsideClick = () => {
    // console.log(("aaya"));
    setShowCalendar(false);
  };
  const [errorMessage, setErrorMessage] = useState('');
  const autoCompleteInputRef = useRef();

  const handleBookNow = async () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const urlWithoutDomain = url.pathname + url.search;
    // console.log(("URL without domain:", urlWithoutDomain));
    if (!user) {
      history.push(`/login?redirect_to=${urlWithoutDomain}`);
      return;
    }

    if (!tourOptionsVisible && !selectedOption) {
      setTimeout(() => {
        tourOptionsRef.current?.scrollIntoView(true);
      }, 150);
      return;
    }

    if (!bookingData) return;

    const { tourOptionId, contractId } = bookingData;
    const travelDate = selectedDate.toISOString().slice(0, 10);
    const transferId = selectedOption.transferIdData[0]?.transferId;
    const grandTotal = calculateTotalPrice(selectedTransfer, counts);
    const offset = selectedDate.getTimezoneOffset() * 60000;
    const localDate = new Date(selectedDate.getTime() - offset);
    const formattedDate = localDate.toISOString().slice(0, 10);
    const requestBody = {
      tourId: tourId,
      tourOptionId,
      transferId,
      contractId,
      travelDate,
      adult: counts.adult,
      child: counts.child,
      infant: counts.infant,
    };

    try {
      setShowLoader(true);
      const response = await apiClient.post('/discover/discoverAvailability', requestBody);

      if (
        response.data &&
        response.data.DATA?.status === 1
        // response.data.message === "success"
      ) {
        // Redirect to checkout page if status is 1
        // console.log((response, "responseresponse"));
        // console.log(('Selected Place:', pickup));

        const countsParams = `&adult=${counts.adult}&child=${counts.child}&infant=${counts.infant}`;
        let payload = {
          tourId: tourId,
          person: countsParams,
          adult: counts.adult,
          child: counts.child,
          infant: counts.infant,
          tourOptionId: tourOptionId,
          travelDate: formattedDate,
          transferId: transferId,
          timeSlotId: selectedTimeSlot ? selectedTimeSlot.timeSlotId : '',
          timeSlot: selectedTimeSlot ? selectedTimeSlot.timeSlot : '',
          grandTotal: totalPrice,
          transferType: selectedTransfer?.transferTitle || '',
          reducedBy: reducedBy,
          ChildRate: ChildRate,
          AdultRate: AdultRate,
          pickup: selectedTransfer.transferTitle !== 'Without Transfer' ? pickupLocation : '',
        };
        const itemCounts = {
          adult: {
            price: parseFloat(AdultRate),
            count: counts.adult,
          },
          child: {
            price: parseFloat(ChildRate),
            count: counts.child,
          },
          infant: {
            price: 0,
            count: counts.infant,
          },
        };

        window.localStorage.setItem('payload', JSON.stringify(payload));

        history.push(`/discover/checkout?tourId=${tourId}`, {
          itemCounts: itemCounts,
          ...(promoCodeValue && { promo: { ...promoCodeValue, tPromoCode: promoCode } }),
        });
      } else {
        // window.location.href = "/discover/booking-failed";
        setShowModal(true);
        setErrorMessage(
          response.data?.MESSAGE ||
            'You cannot book this tour on selected date due to cutoff time.',
        );
      }
    } catch (error) {
      console.error('Error booking:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const showDrawer = () => {
    setShowAllImages(true);
  };

  const onClose = () => {
    setShowAllImages(false);
  };

  const detailSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleDateChange = (date, dateString) => {
    // console.log(("Selected Date:", dateString));
    setdate(dateString);
  };
  // Check if bookingData exists and has the DATA property
  if (bookingData && bookingData.DATA) {
    // Iterate over each item in the DATA array
    bookingData.DATA.forEach((item) => {
      // Check if item has transferIdData array
      if (item.transferIdData) {
        // Iterate over each transferIdData entry
        item.transferIdData.forEach((transfer) => {
          // Check if transfer has transferTitle property
          if (transfer.transferTitle) {
            // Log transferTitle
            // console.log((transfer.transferTitle));
          } else {
            // console.log(("transferTitle is undefined for:", transfer));
          }
        });
      } else {
        // console.log(("transferIdData is undefined for:", item));
      }
    });
  } else {
    // console.log(("bookingData or bookingData.DATA is undefined"));
  }

  const [totalPrice, settotalPrice] = useState();
  const [reducedBy, setreducedBy] = useState(0);
  const [AdultRate, setAdultRate] = useState(0);
  const [ChildRate, setChildRate] = useState(0);

  const calculateTotalPrice = (selectedTransfer, counts) => {
    // console.log(("Selected Checkbox:", selectedTransfer));
    // console.log(("Counts:", counts));

    if (!discoverOptions || !selectedTransfer) {
      console.log('Returning 0: Discover options or selected transfer not available');
      settotalPrice(null);
      return 0;
    }

    // const transferOption = discoverOptions.find((option) =>
    //   option.transferIdData.some(
    //     (transfer) => transfer.transferTitle === selectedTransfer
    //   )
    // );

    if (!selectedOption) return 0;

    // if (!transferOption) {
    //   // console.log(("Returning 0: Transfer option not found"));
    //   settotalPrice(null)
    //   return 0;
    // }

    // const transfer = transferOption.transferIdData.find(
    //   (transfer) => transfer.transferTitle === selectedTransfer
    // );

    if (!selectedTransfer) {
      // console.log(("Returning 0: Transfer not found"));
      settotalPrice(null);
      return 0;
    }

    // console.log(("Transfer Price: ", transfer.adultPrice));

    const { adultPrice, childPrice } = selectedTransfer;

    if (!adultPrice || !childPrice) {
      // console.log(("Returning 0: Prices not available"));
      return 0;
    }

    setChildRate(childPrice);
    setAdultRate(adultPrice);

    let perAdultPrice = parseFloat(adultPrice);
    let perChildPrice = parseFloat(childPrice);

    let totalAdultPrice = counts.adult * perAdultPrice;
    let totalChildPrice = counts.child * perChildPrice;

    const grandTotal = totalAdultPrice + totalChildPrice;

    if (promoCodeValue) {
      let perAdultDiscount = perAdultPrice * (promoCodeValue.tPromoDiscount / 100);

      if (perAdultDiscount > promoCodeValue.tMaxDiscount) {
        perAdultDiscount = promoCodeValue.tMaxDiscount;
      }
      perAdultPrice = perAdultPrice - perAdultDiscount;

      let perChildDiscount = perChildPrice * (promoCodeValue.tPromoDiscount / 100);

      if (perChildDiscount > promoCodeValue.tMaxDiscount) {
        perChildDiscount = promoCodeValue.tMaxDiscount;
      }
      perChildPrice = perChildPrice - perChildDiscount;

      totalAdultPrice = counts.adult * perAdultPrice;
      totalChildPrice = counts.child * perChildPrice;

      let discountedGrandTotal = totalAdultPrice + totalChildPrice;

      setreducedBy(grandTotal - discountedGrandTotal);
    } else {
      setreducedBy(0);
    }

    settotalPrice(grandTotal);
    return grandTotal;
  };
  // console.log((promoCodeValue, "promoCodeValuepromoCodeValue"));

  useEffect(() => {
    calculateTotalPrice(selectedTransfer, counts);

    return () => {};
  }, [selectedTransfer, counts, selectedOption, promoCodeValue]);

  useEffect(() => {
    if (!tourOptionsVisible && !selectedOption) {
      setshowbtn(true);
      return;
    }
    // console.log((counts, selectedCheckbox, selectedDate, "cehck"));
    if (counts.adult !== 0 && selectedTransfer && selectedDate) {
      if (
        selectedTransfer &&
        selectedTransfer.transferTitle !== 'Without Transfer' &&
        pickupLocation.trim().length === 0
      ) {
        setshowbtn(false);
      } else if (!Boolean(bookingData?.tourPriceTransfertimeDetails?.timeslot)) {
        setshowbtn(true);
      } else if (selectedTimeSlot) {
        setshowbtn(true);
      } else {
        setshowbtn(false);
      }
    } else {
      setshowbtn(false);
    }

    return () => {};
  }, [
    date,
    selectedDate,
    selectedTransfer,
    counts,
    bookingData,
    selectedTimeSlot,
    pickupLocation,
    selectedOption,
    tourOptionsVisible,
  ]);

  const selectedData = `${counts.adult} Adult${counts.adult > 1 ? 's' : ''}, ${
    counts.child
  } Child${counts.child > 1 ? 'ren' : ''}, ${counts.infant} Infant${counts.infant > 1 ? 's' : ''}`;

  const items = [
    {
      key: '1',
      label: (
        <div className="participants-select">
          <h3>Participants</h3>
          <div className="participants-row">
            <div className="left-col">
              <h4>Adults</h4>
              <label>Age 13+</label>
            </div>
            <div className="plusminus">
              <Button onClick={() => handleDecrement('adult')}>
                <SvgIcon name="minus" viewbox="0 0 16.638 2.405" />
              </Button>
              <div className="counts">{counts.adult}</div>
              <Button onClick={() => handleIncrement('adult')}>
                <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
              </Button>
            </div>
          </div>
          {bookingData && bookingData.tourPriceTransfertimeDetails.disableChild === '0' && (
            <div className="participants-row">
              <div className="left-col">
                <h4>Children</h4>
                <label>Ages 2-12</label>
              </div>
              <div className="plusminus">
                <Button onClick={() => handleDecrement('child')}>
                  <SvgIcon name="minus" viewbox="0 0 16.638 2.405" />
                </Button>
                <div className="counts">{counts.child}</div>
                <Button onClick={() => handleIncrement('child')}>
                  <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
                </Button>
              </div>
            </div>
          )}
          {bookingData && bookingData.tourPriceTransfertimeDetails.disableInfant === '0' && (
            <div className="participants-row">
              <div className="left-col">
                <h4>Infants</h4>
                <label>Under 2</label>
              </div>
              <div className="plusminus">
                <Button onClick={() => handleDecrement('infant')}>
                  <SvgIcon name="minus" viewbox="0 0 16.638 2.405" />
                </Button>
                <div className="counts">{counts.infant}</div>
                <Button onClick={() => handleIncrement('infant')}>
                  <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
                </Button>
              </div>
            </div>
          )}
        </div>
      ),
    },
  ];
  const items2 = [
    {
      key: '1',
      label: (
        <div className="participants-select">
          <Calendar
            onChange={(date, option) => handleCalendarChange(date, option)}
            value={selectedDate}
            // Your calendar props
            tileDisabled={({ date }) => isDateDisabled(date)}
            defaultActiveStartDate={calenderStartDate}
          />
        </div>
      ),
    },
  ];

  //   useEffect(() => {
  //     const handleClickOutside = (event) => {
  //       // console.log(("aaayyyyyyy"));
  //         const isOutside = discoverOptions?.some((option) => option.showCalendar);
  //         // console.log((document.contains(document.getElementsByClassName('calendar-container')[0]),"pop"));
  //         if (isOutside) {
  //             // setDiscoverOptions(discoverOptions.map((option) => ({ ...option, showCalendar: false })));
  //         }
  //     };

  //     document.addEventListener('click', handleClickOutside);

  //     return () => {
  //         document.removeEventListener('click', handleClickOutside);
  //     };
  // }, [discoverOptions]);
  const currencyData = JSON.parse(localStorage.getItem('selectedCurrency'));

  const handleFavToggle = (value, tourId) => {
    if (!user) {
      window.location.href = `${window.location.origin}/login`;
    }
    setTourDetails((state) => {
      return { ...state, isFavouriteOffer: value };
    });
    apiClient.post(Apis('toggleFavTour', selectedCity.vCountryName, user ? 'loggedIn' : 'guest'), {
      tourId,
      productId: tourId,
      favourite: value,
    });
  };

  const copyUrl = () => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Copy the URL to the clipboard
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        Alert.success('URL copied to clipboard');
        // console.log(('URL copied to clipboard'));
      })
      .catch((error) => {
        console.error('Failed to copy URL: ', error);
      });
  };

  function isYoutubeVideoUrl(videoUrl) {
    return (
      videoUrl &&
      videoUrl.match(/^.*?(?:youtube\.com|youtu.be)(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/)
    );
  }

  function convertToEmbedUrl(youtubeUrl) {
    if (isYoutubeVideoUrl(youtubeUrl)) {
      const match = youtubeUrl.match(
        /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?vi?=|\&vi?=))([^#&?]*).*/,
      );
      if (match) {
        return `https://www.youtube.com/embed/${match[1]}`;
      }
    }
    return youtubeUrl;
  }

  const sectionRef = useRef({});

  const scrollToSection = useCallback(
    debounce((sectionId) => {
      if (sectionRef.current[sectionId]) {
        sectionRef.current[sectionId].scrollIntoView({ behavior: 'smooth' });
      }
    }, 100),
    [],
  );

  const [knowMoreOpen, setKnowMoreOpen] = useState(false);

  const whatToExpect = useMemo(() => {
    if (tourDetails?.whatsInThisTour) {
      return htmlToList(tourDetails.whatsInThisTour);
    }
    return [];
  }, [tourDetails]);

  useEffect(() => {
    if (tourDetails && tourDetails.tourShortDescription) {
      const lines = htmlToList(tourDetails.tourShortDescription);
      if (showFullDescription) {
        setDescriptionLines(lines);
      } else {
        setDescriptionLines(lines.slice(0, 3));
      }
      if (lines.length < 2) {
        setShowFullDescription(true);
      }
    } else {
      setDescriptionLines([]);
    }
  }, [tourDetails, showFullDescription]);

  if (!tourId) {
    return <Redirect to="/" />;
  }
  return (
    <div className="twl-details-wrapper">
      {showModal && <BookingErrorModal setShowModal={setShowModal} errorMessage={errorMessage} />}
      {tourDetails && (
        <>
          <MediaQuery maxWidth={767}>
            <div className="details-mobile-header">
              <Link
                to="#"
                className="back-arrow"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (location.key) {
                    history.goBack();
                  } else {
                    history.replace('/');
                  }
                }}
              >
                <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
              </Link>
              <div className="right-action">
                <Button
                  type="link"
                  onClick={() => {
                    handleFavToggle(
                      tourDetails?.isFavouriteOffer === 0 ? 1 : 0,
                      tourDetails.tourId || tourDetails.productId,
                    );
                  }}
                >
                  {/* <SvgIcon name="heart-outline" viewbox="0 0 13.269 12.168" 
                   /> */}
                  <SvgIcon
                    name={tourDetails.isFavouriteOffer === 0 ? 'heart-outline' : 'heart'}
                    viewbox="0 0 10.238 9.131"
                    fill={tourDetails.isFavouriteOffer === 0 ? '#000' : '#FF5D5D'}
                  />
                </Button>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <div className="mobile-redeem-action">
              <div className="left-actions">
                <Button type="link" onClick={copyUrl}>
                  <SvgIcon name="share-icon" viewbox="0 0 10.314 11.942" />
                </Button>
                {/*<Button type="link">
                  <SvgIcon name="call-icon" viewbox="0 0 14.993 14.993" />
                </Button>*/}
              </div>
              <Button
                type="primary"
                onClick={handleBookNow}
                disabled={!showbtn && user ? true : false}
              >
                Book Now
              </Button>
              {/* {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>} */}
            </div>
          </MediaQuery>
          <section>
            <Drawer
              className="moreimages-drawer"
              height={'100vh'}
              placement="bottom"
              title={
                <div className="right-action">
                  <Button
                    type="link"
                    onClick={() => {
                      handleFavToggle(
                        tourDetails?.isFavouriteOffer === 0 ? 1 : 0,
                        tourDetails.tourId || tourDetails.productId,
                      );
                    }}
                  >
                    Favorite {/* <SvgIcon name="heart-outline" viewbox="0 0 13.269 12.168" /> */}
                    <SvgIcon
                      name={tourDetails.isFavouriteOffer === 0 ? 'heart-outline' : 'heart'}
                      viewbox="0 0 10.238 9.131"
                      fill={tourDetails.isFavouriteOffer === 0 ? '#000' : '#FF5D5D'}
                    />
                  </Button>
                  <Button type="link" onClick={copyUrl}>
                    Share <SvgIcon name="share-icon" viewbox="0 0 10.314 11.942" />
                  </Button>
                </div>
              }
              closeIcon={<SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />}
              onClose={onClose}
              open={showAllImages}
            >
              {/* <Gallery images={imagesArray} enableImageSelection={false} /> */}
              <Gallery
                images={
                  tourDetails &&
                  tourDetails.rTourImageList &&
                  tourDetails.rTourImageList.map((image, index) => ({
                    src: image,
                    alt: `Image ${index + 1}`,
                  }))
                }
                enableImageSelection={false}
              />
            </Drawer>
            <Container className="container-upper">
              {/* <MediaQuery minWidth={768}>
                <div className="details-images">
                  <div className="showphotos-btn" onClick={showDrawer}>
                    <span>10</span> SHOW PHOTOS
                  </div>
                  <div className="left-image">
                    <img src={DetailsImg1} alt="Experience London skyline" />
                  </div>
                  <div className="right-image">
                    <img src={DetailsImg2} alt="Experience London skyline" />
                    <img src={DetailsImg3} alt="Experience London skyline" />
                    <img src={DetailsImg4} alt="Experience London skyline" />
                    <img src={DetailsImg5} alt="Experience London skyline" />
                  </div>
                </div>
              </MediaQuery> */}
              {tourDetails &&
                tourDetails.rTourImageList &&
                tourDetails.rTourImageList.length > 1 && (
                  <MediaQuery minWidth={768}>
                    <div className="details-images">
                      <div className="showphotos-btn" onClick={showDrawer}>
                        <span>{tourDetails.rTourImageList.length}</span> SHOWs PHOTOS
                      </div>
                      <div className="left-image">
                        {tourDetails.videoUrl ? (
                          <div className="square-image-container">
                            <iframe
                              src={convertToEmbedUrl(tourDetails.videoUrl)}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              className="detail-img1"
                            ></iframe>
                          </div>
                        ) : (
                          <Zoom>
                            <div className="square-image-container">
                              <img
                                src={tourDetails.rTourImageList[0]}
                                className="detail-img1"
                                alt="Tour Image 1"
                              />
                            </div>
                          </Zoom>
                        )}
                      </div>
                      <div className="right-image">
                        {showAllImages
                          ? tourDetails.rTourImageList.map((image, index) => (
                              <Zoom key={index}>
                                <div className="square-image-container">
                                  <img src={image} alt={`Tour Image ${index + 1}`} />
                                </div>
                              </Zoom>
                            ))
                          : tourDetails.rTourImageList.slice(1, 5).map((image, index) => (
                              <Zoom key={index}>
                                <div className="square-image-container">
                                  <img
                                    src={image}
                                    alt={`Tour Image ${index + 2}`}
                                    className="detail-img-sub"
                                  />
                                </div>
                              </Zoom>
                            ))}
                      </div>
                    </div>
                  </MediaQuery>
                )}

              {/* <MediaQuery maxWidth={767}>
                <div className="mobile-details-slider">
                  <Slider {...detailSlider}>
                    <div>
                      <img src={DetailsImg1} alt="Experience London skyline" />
                    </div>
                    <div>
                      <img src={DetailsImg2} alt="Experience London skyline" />
                    </div>
                    <div>
                      <img src={DetailsImg3} alt="Experience London skyline" />
                    </div>
                    <div>
                      <img src={DetailsImg4} alt="Experience London skyline" />
                    </div>
                    <div>
                      <img src={DetailsImg5} alt="Experience London skyline" />
                    </div>
                  </Slider>
                </div>
              </MediaQuery> */}

              <MediaQuery maxWidth={767}>
                <div className="mobile-details-slider">
                  <Slider {...detailSlider}>
                    {tourDetails.rTourImageList &&
                      tourDetails.rTourImageList.map((image, index) => (
                        <div key={index}>
                          <img src={image} alt={`Experience London skyline ${index + 1}`} />
                        </div>
                      ))}
                  </Slider>
                </div>
              </MediaQuery>

              <div className="details-row">
                <div className="details-left">
                  {tourDetails &&
                    tourDetails.rTourImageList &&
                    tourDetails.rTourImageList.length === 1 && (
                      <MediaQuery minWidth={767}>
                        <div>
                          <div className="square-image-container">
                            <img
                              src={tourDetails.rTourImageList[0]}
                              alt={`Tour Image`}
                              className="detail-img-sub"
                            />
                          </div>
                        </div>
                      </MediaQuery>
                    )}
                  <div className="dtl-upper">
                    <div className="dtl-upperleft">
                      {tourDetails.tourRating && (
                        <div className="rating">
                          {[
                            ...Array(Math.round(parseFloat(tourDetails.tourRating.averageRating))),
                          ].map((_, index) => (
                            <SvgIcon key={index} name="star-filled" viewbox="0 0 15 15" />
                          ))}
                          {[
                            ...Array(
                              5 - Math.round(parseFloat(tourDetails.tourRating.averageRating)),
                            ),
                          ].map((_, index) => (
                            <SvgIcon key={index} name="star-outline" viewbox="0 0 15.999 16" />
                          ))}
                        </div>
                      )}
                      <h1>{tourDetails.tourName}</h1>
                      <div className="location">
                        <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                        {tourDetails.cityName}, {tourDetails.countryName}
                      </div>
                    </div>
                    <MediaQuery minWidth={768}>
                      <div className="dtl-upperright">
                        <Button
                          type="link"
                          onClick={() => {
                            handleFavToggle(
                              tourDetails?.isFavouriteOffer === 0 ? 1 : 0,
                              tourDetails.tourId || tourDetails.productId,
                            );
                          }}
                        >
                          Favorite{' '}
                          {/* <SvgIcon
                            name="heart-outline"
                            viewbox="0 0 13.269 12.168"
                          /> */}
                          <SvgIcon
                            name={tourDetails.isFavouriteOffer === 0 ? 'heart-outline' : 'heart'}
                            viewbox="0 0 10.238 9.131"
                            fill={tourDetails.isFavouriteOffer === 0 ? '#000' : '#FF5D5D'}
                          />
                        </Button>
                        <Button type="link" onClick={copyUrl}>
                          Share <SvgIcon name="share-icon" viewbox="0 0 10.314 11.942" />
                        </Button>
                      </div>
                    </MediaQuery>
                  </div>
                  {descriptionLines.length > 0 && (
                    <div className="overview-row">
                      <h2>Overview</h2>
                      <div>
                        <div style={{ display: 'inline-block' }}>
                          {descriptionLines.map((text, index) => (
                            <p key={index}>{text}</p>
                          ))}
                        </div>
                        {!showFullDescription && (
                          <span>
                            <a onClick={toggleDescription} to="#" style={{ cursor: 'pointer' }}>
                              Read More
                            </a>
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="highlights-row highlights-row-rayna">
                    <h2>Highlights</h2>
                    <ul>
                      {tourDetails?.tourHighLights.map(
                        (highlight, index) =>
                          highlight.rHighlights && (
                            <li key={index}>
                              <div className="left-icon">
                                <img
                                  className="icon"
                                  src={highlight.rHighlightsIcon}
                                  alt={highlight.rHighlightsTitle}
                                />
                              </div>
                              <div>
                                <label>{highlight.rHighlightsTitle}</label>
                                {highlight.rHighlights}
                              </div>
                            </li>
                          ),
                      )}
                    </ul>
                  </div>
                </div>
                <div className="details-right">
                  <div className="details-right-inner">
                    <Form name="basic" autoComplete="off" layout="vertical">
                      <div className="prive-details-form">
                        <div className="heading">
                          <h3>
                            <span>
                              {' '}
                              {discoverOptions?.length > 1 ? 'FROM' : ''} {currencyData?.uCurrency}{' '}
                              {formatCurrency(tourDetails.adultPrice)}{' '}
                            </span>
                            / Person
                          </h3>
                        </div>

                        {discoverOptions && (
                          <div ref={tourOptionsRef} className="options-colum">
                            <h4>Options</h4>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                              {discoverOptions.map((option, index) => (
                                <li key={index} style={{ position: 'relative' }}>
                                  <Popover
                                    placement="bottomLeft"
                                    arrow={false}
                                    overlayClassName="options-list-popover"
                                    content={option.optionDescription}
                                    title={option.optionName}
                                  >
                                    <Checkbox
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          handleCheckboxClick(option, index);
                                        } else {
                                          handleCheckboxUncheck(index);
                                        }
                                      }}
                                      // checked={selectedOption === option && selectedDate !== null}
                                      checked={option.isChecked}
                                    >
                                      {option.optionName.length < 2
                                        ? option.optionName
                                        : `${option.optionName.slice(0, 20)}...`}
                                    </Checkbox>
                                  </Popover>
                                  <div
                                    className="right-col"
                                    style={{
                                      display: 'inline-block',
                                      position: 'absolute',
                                      right: '0',
                                    }}
                                  >
                                    <span className="off-price">{option.price}</span>
                                    {/* Display the adultPrice from transferIdData */}
                                    {option.transferIdData && option.transferIdData.length > 0 && (
                                      <>
                                        {currencyData?.uCurrency}{' '}
                                        {formatCurrency(option.transferIdData[0].adultPrice)}
                                        <span>/ Person</span>
                                      </>
                                    )}
                                  </div>
                                  {/* <OutsideClickHandler onOutsideClick={handleOutsideClick}> */}
                                  {/* {option.showCalendar && (
                                    <div style={{ position: "relative", }}>
                                      <div
                                        className="calendar-container"
                                        style={{
                                          position: 'absolute',
                                          top: '-00px', // Adjust the negative value as needed
                                          left: '0',
                                          zIndex: 50,
                                        }}
                                      >
                                        <Calendar
                                          onChange={(date, option) => handleCalendarChange(date, option, index)}
                                          value={selectedDate}
                                          // Your calendar props
                                          tileDisabled={({ date }) => isDateDisabled(date)}
                                        />
                                      </div>
                                    </div>
                                  )} */}
                                  {/* </OutsideClickHandler> */}
                                </li>
                              ))}
                              {/* Render the calendar overlapping the selected option */}
                              {/* <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                                {showCalendar && (
                                  <div
                                    className="calendar-container"
                                    style={{
                                      position: 'absolute',
                                      top: '-240px', // Adjust the negative value as needed
                                      left: '0',
                                      zIndex: 50,
                                    }}
                                  >
                                    <Calendar
                                      onChange={handleCalendarChange}
                                      value={selectedDate}
                                      // Your calendar props
                                      tileDisabled={({ date }) => isDateDisabled(date)}
                                    />
                                  </div>
                                )}
                              </OutsideClickHandler> */}
                            </ul>
                          </div>
                        )}

                        {/* Render the Calendar component conditionally */}
                        {/* <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                          {showCalendar && (
                            <div
                              className="calendar-container"
                              style={{ position: 'absolute', zIndex: 1, top: '-200px', left: '0' }}
                            >
                              <Calendar
                                onChange={handleCalendarChange}
                                value={selectedDate}
                                // Disabled date logic
                                tileDisabled={({ date }) => isDateDisabled(date)}
                              />
                            </div>
                          )}
                        </OutsideClickHandler> */}

                        {selectedOption && (
                          <>
                            <div className="availability-colum">
                              <h4>Availability</h4>
                              <Row>
                                <Col>
                                  <Form.Item name="transferOptions" label="TRANSFER OPTIONS">
                                    <Dropdown
                                      menu={{
                                        items: transferOptions.map((item) => ({
                                          key: item.transferId,
                                          label: (
                                            <Checkbox
                                              checked={
                                                selectedTransfer &&
                                                selectedTransfer.transferId === item.transferId
                                              }
                                              onChange={() => {
                                                setSelectedTransfer(item);
                                              }}
                                            >
                                              {item.transferTitle}
                                            </Checkbox>
                                          ),
                                        })),
                                      }}
                                      overlayClassName="transferoptions-drop"
                                      trigger={['click']}
                                      placement="bottom"
                                      dropdownRender={(menu) => (
                                        <>
                                          <h3 className="title">Transfer Options</h3>
                                          {menu}
                                        </>
                                      )}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <Input
                                          value={
                                            selectedTransfer ? selectedTransfer.transferTitle : null
                                          }
                                          readOnly
                                          suffix={<SvgIcon name="down-arrow" viewbox="0 0 18 9" />}
                                          placeholder="Transfer Type"
                                        />
                                      </div>
                                    </Dropdown>
                                  </Form.Item>
                                </Col>
                              </Row>
                              {/* <Row>
                                <Col>
                                  <Form.Item name="date" label="DATE">
                                    <DatePicker
                                      popupClassName="pickdate-drop"
                                      onChange={handleDateChange}
                                      value={selectedDate}
                                      icon={false}
                                      suffixIcon={false}
                                      placeholder="DD / MM / YYYY"
                                    />
                                  </Form.Item>
                                </Col>
                                {Boolean(bookingData
                                  ?.tourPriceTransfertimeDetails
                                  ?.timeslot) && bookingData
                                    ?.tourPriceTransfertimeDetails
                                    ?.timeslot?.length !== 0 && <Col>
                                    <Form.Item name="time" label="TIME">
                                      <Select
                                        value={
                                          bookingData &&
                                          bookingData.tourPriceTransfertimeDetails &&
                                          bookingData.tourPriceTransfertimeDetails
                                            .timeslot &&
                                          bookingData.tourPriceTransfertimeDetails
                                            .timeslot[0] &&
                                          bookingData.tourPriceTransfertimeDetails
                                            .timeslot[0].timeSlotId
                                        }
                                        onChange={(value) =>
                                          setSelectedTimeSlot(value)
                                        }
                                        placement="bottomRight"
                                        // defaultValue="12:00"
                                        popupMatchSelectWidth={false}
                                        popupClassName="timeselect"
                                        suffixIcon={
                                          <SvgIcon
                                            name="down-arrow"
                                            viewbox="0 0 18 9"
                                          />
                                        }
                                        dropdownRender={(menu) => (
                                          <>
                                            <h3 className="title">
                                              Pick the Time
                                            </h3>
                                            {menu}
                                          </>
                                        )}
                                        options={
                                          (
                                            bookingData
                                              ?.tourPriceTransfertimeDetails
                                              ?.timeslot || []
                                          ).map((slot, index) => ({
                                            value: slot.timeSlotId,
                                            label: (
                                              <div
                                                className="time-row"
                                                key={index}
                                              >
                                                <div className="time-left">
                                                  {slot.timeSlot}
                                                </div>
                                                <div className="right-price">
                                                  {slot.adultPrice}
                                                </div>
                                              </div>
                                            ),
                                          })) || []
                                        }
                                      />
                                    </Form.Item>
                                  </Col>}

                              </Row> */}
                              {selectedTransfer &&
                                selectedTransfer.transferTitle !== 'Without Transfer' && (
                                  <Row>
                                    <Col>
                                      <Form.Item name="pickupLocation" label="PICKUP LOCATION">
                                        <Dropdown
                                          overlayClassName="transferoptions-drop"
                                          trigger={['click']}
                                          open={pickupLocationDropOpen}
                                          onOpenChange={(nextOpen, info) => {
                                            if (info.source === 'trigger' || nextOpen) {
                                              setPickupLocationDropOpen(nextOpen);

                                              if (nextOpen) {
                                                setTimeout(() => {
                                                  autoCompleteInputRef.current?.focus();
                                                }, 150);
                                              } else {
                                                autoCompleteInputRef.current?.blur();
                                              }
                                            }
                                          }}
                                          placement="bottom"
                                          dropdownRender={(menu) => (
                                            <>
                                              <h3 className="title">Pickup Location</h3>

                                              <div className="search-wrapper">
                                                <Input.Search
                                                  ref={autoCompleteInputRef}
                                                  value={placesAutoCompleteQuery}
                                                  placeholder="Search"
                                                  onChange={(evt) => {
                                                    getPlacePredictions({
                                                      input: evt.target.value,
                                                    });
                                                    setPlacesAutoCompleteQuery(evt.target.value);
                                                  }}
                                                  loading={isPlacePredictionsLoading}
                                                />
                                              </div>
                                              {!isPlacePredictionsLoading &&
                                                placePredictions.length > 0 && (
                                                  <div className="transfers-list">
                                                    <ul>
                                                      {placePredictions.map((item) => {
                                                        return (
                                                          <li
                                                            onClick={() => {
                                                              setPickupLocationLocation(
                                                                item.description,
                                                              );
                                                              setPickupLocationDropOpen(false);
                                                            }}
                                                          >
                                                            <div className="icons">
                                                              <SvgIcon
                                                                name="map"
                                                                viewbox="0 0 8.358 12.537"
                                                              />
                                                            </div>
                                                            {item.description}
                                                          </li>
                                                        );
                                                      })}
                                                    </ul>
                                                  </div>
                                                )}
                                            </>
                                          )}
                                        >
                                          <div style={{ position: 'relative' }}>
                                            <Input
                                              value={pickupLocation}
                                              readOnly
                                              suffix={
                                                <SvgIcon name="down-arrow" viewbox="0 0 18 9" />
                                              }
                                              placeholder="Location"
                                            />
                                          </div>
                                        </Dropdown>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                )}
                              <Row>
                                <Col>
                                  <Form.Item name="date" label="DATE">
                                    <Dropdown
                                      menu={{ items: items2 }}
                                      overlayClassName="participants-drop"
                                      trigger={['click']}
                                      // visible={dropdownVisible}
                                      // onVisibleChange={(flag) =>
                                      //   setDropdownVisible(flag)
                                      // }
                                      visible={dropdownVisible2}
                                      onVisibleChange={(flag) => setDropdownVisible2(flag)}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <Input
                                          value={
                                            selectedDate &&
                                            format(new Date(selectedDate), 'dd/MM/yyyy')
                                          }
                                          readOnly
                                          suffix={<SvgIcon name="down-arrow" viewbox="0 0 18 9" />}
                                          placeholder="DD/MM/YYYY"
                                        />
                                      </div>
                                    </Dropdown>
                                  </Form.Item>
                                </Col>

                                {bookingData?.tourPriceTransfertimeDetails.timeslot &&
                                  bookingData?.tourPriceTransfertimeDetails.timeslot.length !==
                                    0 && (
                                    <Col>
                                      <Form.Item label="TIME">
                                        <Select
                                          placeholder="00:00"
                                          value={
                                            selectedTimeSlot?.timeSlot
                                              ? selectedTimeSlot?.timeSlot
                                              : ''
                                          }
                                          onChange={(value) =>
                                            setSelectedTimeSlot(
                                              bookingData.tourPriceTransfertimeDetails.timeslot.filter(
                                                (e) => e.timeSlot === value,
                                              )[0],
                                            )
                                          }
                                          placement="bottomRight"
                                          popupMatchSelectWidth={false}
                                          popupClassName="timeselect"
                                          suffixIcon={
                                            <SvgIcon name="down-arrow" viewbox="0 0 18 9" />
                                          }
                                          dropdownRender={(menu) => (
                                            <>
                                              <h3 className="title">Pick the Time</h3>
                                              {menu}
                                            </>
                                          )}
                                          options={
                                            bookingData?.tourPriceTransfertimeDetails.timeslot
                                              ? bookingData.tourPriceTransfertimeDetails.timeslot
                                                  .sort((a, b) =>
                                                    a.timeSlot.localeCompare(b.timeSlot),
                                                  )
                                                  .map((time, index) => ({
                                                    value: time.timeSlot,
                                                    label: (
                                                      <div className="time-row" key={index}>
                                                        <div className="time-left">
                                                          {dayjs(time.timeSlot, 'HH:mm').format(
                                                            'hh:mm A',
                                                          )}
                                                        </div>
                                                      </div>
                                                    ),
                                                  }))
                                              : []
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                              </Row>
                              <Row>
                                <Col>
                                  <Form.Item name="person" label="PERSON">
                                    <Dropdown
                                      menu={{ items }}
                                      overlayClassName="participants-drop"
                                      trigger={['click']}
                                      visible={dropdownVisible}
                                      onVisibleChange={(flag) => setDropdownVisible(flag)}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <Input
                                          disabled={!bookingData}
                                          value={selectedData}
                                          readOnly
                                          suffix={<SvgIcon name="down-arrow" viewbox="0 0 18 9" />}
                                        />
                                      </div>
                                    </Dropdown>
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Form.Item name="pcode" label="PROMO CODE">
                                    <Input
                                      value=""
                                      placeholder="Enter"
                                      onChange={handlePromoCodeChange}
                                      onInput={(e) =>
                                        (e.target.value = e.target.value.toUpperCase())
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>

                            <div className="price-info">
                              {/* <Row>
                                <Col className="price-left">Service Charge</Col>
                                <Col className="price-right">AED 23</Col>
                              </Row> */}
                              {Boolean(reducedBy) && reducedBy !== 0 && (
                                <>
                                  <Row className="total-row">
                                    <Col className="price-left">Calculated Amount</Col>
                                    <Col className="price-right">
                                      {currencyData?.uCurrency}
                                      <b>{formatCurrency(totalPrice)}</b>{' '}
                                    </Col>
                                  </Row>
                                  <Row className="total-row">
                                    <Col className="price-left">Coupon Amount</Col>
                                    <Col className="price-right">
                                      {currencyData?.uCurrency}
                                      <b> -{formatCurrency(reducedBy)}</b>{' '}
                                    </Col>
                                  </Row>
                                </>
                              )}
                              <Row className="total-row">
                                <Col className="price-left">Grand Total</Col>
                                <Col className="price-right">
                                  {currencyData?.uCurrency}{' '}
                                  <b>{formatCurrency(totalPrice - reducedBy)}</b>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="bottom-action mt-3">
                        <MediaQuery minWidth={767}>
                          <Button
                            type="primary"
                            onClick={handleBookNow}
                            block
                            disabled={!showbtn && user ? true : false}
                          >
                            Book Now
                          </Button>
                          {/* {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>} */}
                        </MediaQuery>
                        {/* <Button
                          type="link"
                          icon={<SvgIcon name="play" viewbox="0 0 41.93 41.965" />}
                          block
                        >
                          Learn How to book
                        </Button>*/}
                      </div>
                      <div className="getit-touch" data-aos="fade-in" data-aos-duration="1400">
                        <div className="upper-row">
                          <h3>Get in touch</h3>
                        </div>
                        <p>
                          If you have any doubts, please reach out at{' '}
                          <a target="_blank" href="mailto:contact@dyc.global">
                            contact@dyc.global
                          </a>
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              {whatToExpect.length !== 0 && (
                <div className="whatexpect-row-rayna">
                  <h2>What to Expect</h2>
                  <ul className="wta-wrapper">
                    {whatToExpect.map((text, index) => (
                      <li key={index}>{text}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="details-experiences">
                {tourDetails &&
                  tourDetails.rTourImageList &&
                  tourDetails.rTourImageList.length > 1 && (
                    <div className="gallery-row">
                      <h2>Gallery</h2>
                      <div className="details-images">
                        <div className="showphotos-btn" onClick={showDrawer}>
                          <span>{tourDetails.rTourImageList.length}</span> SHOW PHOTOS
                        </div>
                        <div className="left-image">
                          {/* <button className="play-btn" onClick={showDrawer}>
                        <SvgIcon name="play-icon" viewbox="0 0 48 61" />
                      </button> */}

                          {tourDetails.videoUrl ? (
                            <div className="square-image-container">
                              <iframe
                                src={convertToEmbedUrl(tourDetails.videoUrl)}
                                title="YouTube video player"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                frameBorder="0"
                                className="detail-img1"
                              ></iframe>
                            </div>
                          ) : (
                            <Zoom>
                              <div className="square-image-container">
                                <img
                                  src={tourDetails.rTourImageList[0]}
                                  alt="Tour Image 1"
                                  className="detail-img1"
                                />
                              </div>
                            </Zoom>
                          )}
                        </div>
                        <div className="right-image">
                          {showAllImages
                            ? tourDetails.rTourImageList.map((image, index) => (
                                <div key={index} className="square-image-container">
                                  <img src={image} alt={`Tour Image ${index + 1}`} />
                                </div>
                              ))
                            : tourDetails.rTourImageList.slice(1, 5).map((image, index) => (
                                <Zoom>
                                  <div key={index} className="square-image-container">
                                    <img
                                      src={image}
                                      alt={`Tour Image ${index + 2}`}
                                      className="detail-img-sub"
                                    />
                                  </div>
                                </Zoom>
                              ))}
                        </div>
                      </div>
                      {/* <MediaQuery maxWidth={767}>
                                <div className='mobile-details-slider'>
                                    <Slider {...detailSlider}>
                                        <div>
                                            <img src={DetailsImg1} alt="Experience London skyline" />
                                        </div>
                                        <div>
                                            <img src={DetailsImg2} alt="Experience London skyline" />
                                        </div>
                                        <div>
                                            <img src={DetailsImg3} alt="Experience London skyline" />
                                        </div>
                                        <div>
                                            <img src={DetailsImg4} alt="Experience London skyline" />
                                        </div>
                                        <div>
                                            <img src={DetailsImg5} alt="Experience London skyline" />
                                        </div>
                                    </Slider>
                                </div>
                            </MediaQuery> */}
                    </div>
                  )}
                <div className="moreDetails-row">
                  <h2>More Details</h2>
                  <ul>
                    {Boolean(tourDetails.howToRedeem) && (
                      <li>
                        <Checkbox checked />
                        <div>
                          <h3>How to Redeem</h3>
                          <p>In this tour</p>
                        </div>

                        <Button
                          type="secondary"
                          size="medium"
                          onClick={() => {
                            setKnowMoreOpen(true);

                            scrollToSection('htr');
                          }}
                        >
                          Know More
                        </Button>
                      </li>
                    )}
                    {Boolean(tourDetails.tourInclusion) && (
                      <li>
                        <Checkbox checked />
                        <div>
                          <h3>Tour Inclusion</h3>
                          <p>In this tour</p>
                        </div>

                        <Button
                          type="secondary"
                          size="medium"
                          onClick={() => {
                            setKnowMoreOpen(true);

                            scrollToSection('ti');
                          }}
                        >
                          Know More
                        </Button>
                      </li>
                    )}
                    {Boolean(tourDetails.tourExclusion) && (
                      <li>
                        <Checkbox checked />
                        <div>
                          <h3>Tour Exclusion</h3>
                          <p>In this tour</p>
                        </div>

                        <Button
                          type="secondary"
                          size="medium"
                          onClick={() => {
                            setKnowMoreOpen(true);

                            scrollToSection('te');
                          }}
                        >
                          Know More
                        </Button>
                      </li>
                    )}
                    {Boolean(tourDetails.importantInformation) && (
                      <li>
                        <Checkbox checked />
                        <div>
                          <h3>Important Information</h3>
                          <p>In this tour</p>
                        </div>

                        <Button
                          type="secondary"
                          size="medium"
                          onClick={() => {
                            setKnowMoreOpen(true);

                            scrollToSection('if');
                          }}
                        >
                          Know More
                        </Button>
                      </li>
                    )}
                    {Boolean(tourDetails.usefulInformation) && (
                      <li>
                        <Checkbox checked />
                        <div>
                          <h3>Useful Information</h3>
                          <p>In this tour</p>
                        </div>

                        <Button
                          type="secondary"
                          size="medium"
                          onClick={() => {
                            setKnowMoreOpen(true);

                            scrollToSection('ui');
                          }}
                        >
                          Know More
                        </Button>
                      </li>
                    )}
                    {Boolean(tourDetails.faqDetails) && (
                      <li>
                        <Checkbox checked />
                        <div>
                          <h3>Faq Details</h3>
                          <p>In this tour</p>
                        </div>

                        <Button
                          type="secondary"
                          size="medium"
                          onClick={() => {
                            setKnowMoreOpen(true);

                            scrollToSection('faq');
                          }}
                        >
                          Know More
                        </Button>
                      </li>
                    )}
                    {Boolean(tourDetails.cancellationPolicyDescription) && (
                      <li>
                        <Checkbox checked />
                        <div>
                          <h3>Cancellation Policy</h3>
                          <p>In this tour</p>
                        </div>

                        <Button
                          type="secondary"
                          size="medium"
                          onClick={() => {
                            setKnowMoreOpen(true);

                            scrollToSection('cp');
                          }}
                        >
                          Know More
                        </Button>
                      </li>
                    )}
                    {Boolean(tourDetails.termsAndConditions) && (
                      <li>
                        <Checkbox checked />
                        <div>
                          <h3>Terms And Conditions</h3>
                          <p>In this tour</p>
                        </div>

                        <Button
                          type="secondary"
                          size="medium"
                          onClick={() => {
                            setKnowMoreOpen(true);

                            scrollToSection('tc');
                          }}
                        >
                          Know More
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
                {tourDetails.tourReviews && tourDetails.tourReviews.length !== 0 && (
                  <div className="visitorreview-row">
                    <h2>Visitor Reviews</h2>
                    {/* <Row className="rating-section">
                    <Col sm="6" className="rating-left">
                      <div className="rating-row">
                        <label className="left-label">5 Stars</label>
                        <Progress
                          strokeColor="#18D39E"
                          trailColor="#F7F7F7"
                          size="small"
                          percent={84}
                        />
                      </div>
                      <div className="rating-row">
                        <label className="left-label">4 Stars</label>
                        <Progress
                          strokeColor="#18D39E"
                          trailColor="#F7F7F7"
                          size="small"
                          percent={65}
                        />
                      </div>
                      <div className="rating-row">
                        <label className="left-label">3 Stars</label>
                        <Progress
                          strokeColor="#18D39E"
                          trailColor="#F7F7F7"
                          size="small"
                          percent={40}
                        />
                      </div>
                      <div className="rating-row">
                        <label className="left-label">2 Stars</label>
                        <Progress
                          strokeColor="#18D39E"
                          trailColor="#F7F7F7"
                          size="small"
                          percent={32}
                        />
                      </div>
                      <div className="rating-row">
                        <label className="left-label">1 Stars</label>
                        <Progress
                          strokeColor="#18D39E"
                          trailColor="#F7F7F7"
                          size="small"
                          percent={11}
                        />
                      </div>
                    </Col>
                    <Col sm="6" className="rating-right">
                      <h3>4.5 Out of 5</h3>
                      <div className="rating">
                        <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                        <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                        <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                        <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                        <SvgIcon name="star-outline" viewbox="0 0 15.999 16" />
                      </div>
                    </Col>
                  </Row> */}
                    {tourDetails.tourRating && (
                      <Row className="rating-section">
                        <Col sm="6" className="rating-left">
                          {Object.keys(tourDetails.tourRating).map((key, index) => {
                            // Exclude the 'averageRating' key
                            if (key !== 'averageRating') {
                              return (
                                <div className="rating-row" key={index}>
                                  <label className="left-label">{key.charAt(4)} Stars</label>
                                  <Progress
                                    strokeColor="#18D39E"
                                    trailColor="#F7F7F7"
                                    size="small"
                                    percent={tourDetails.tourRating[key]}
                                  />
                                </div>
                              );
                            }
                            return null; // Skip rendering if it's the 'averageRating' key
                          })}
                        </Col>
                        <Col sm="6" className="rating-right">
                          <h3>{tourDetails.tourRating.averageRating} Out of 5</h3>
                          <div className="rating">
                            {[
                              ...Array(
                                Math.round(parseFloat(tourDetails.tourRating.averageRating)),
                              ),
                            ].map((_, index) => (
                              <SvgIcon key={index} name="star-filled" viewbox="0 0 15 15" />
                            ))}
                            {[
                              ...Array(
                                5 - Math.round(parseFloat(tourDetails.tourRating.averageRating)),
                              ),
                            ].map((_, index) => (
                              <SvgIcon key={index} name="star-outline" viewbox="0 0 15.999 16" />
                            ))}
                          </div>
                        </Col>
                      </Row>
                    )}

                    {/* <Row>
                    <Col>
                      <ul className="reviewer-list">
                        {reviewerData.map((item) => (
                          <li key={item.key}>
                            <div className="reviewer-img">
                              <img src={item.image} alt={item.name} />
                            </div>
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                            <label>
                              BY {item.name} / {item.date}
                            </label>
                          </li>
                        ))}
                      </ul>
                      <div className="allreviewbtn-row">
                        <Button type="primary" ghost>
                          View all Reviews
                        </Button>
                      </div>
                    </Col>
                  </Row> */}
                    {/* Render tour reviews */}
                    {tourDetails.tourReviews && tourDetails.tourReviews.length > 0 && (
                      <Row>
                        <Col>
                          <ul className="reviewer-list">
                            {tourDetails.tourReviews
                              .slice(0, showAll ? tourDetails.tourReviews.length : 6)
                              .map((item, index) => (
                                <li key={index}>
                                  <div className="reviewer-img">
                                    <img src={item.imagePath} alt={item.guestName} />
                                  </div>
                                  <h3>{item.reviewTitle}</h3>
                                  <p>{item.reviewContent}</p>
                                  <label>
                                    BY {item.guestName} / {item.visitMonth}
                                  </label>
                                </li>
                              ))}
                          </ul>
                          {!showAll && (
                            <div className="allreviewbtn-row">
                              <Button type="primary" ghost onClick={toggleShowAll}>
                                View all Reviews
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )}
                  </div>
                )}

                {/* <div className="address-row">
                  <h2 className="mb-2">Address</h2>
                  <p className="mb-4 pb-2">
                    1 Sheikh Mohammed bin Rashid Blvd, Downtown Dubai, Dubai
                  </p>
                  <img className="w-100" src={MapAddress} alt="map" />
                </div> */}
                <div className="address-row">
                  <h2 className="mb-2">Address</h2>
                  <p className="mb-4 pb-2">{tourDetails.areaAddress}</p>
                  {/* <img className="w-100" src={MapAddress} alt="map" /> */}
                  <div className="map-row">
                    <div className="map-area">
                      <iframe
                        className="map-area-detail"
                        title="map"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        src={`https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${tourDetails.areaAddress}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>
          <DownloadSection />
          <Drawer
            height="100%"
            placement="bottom"
            open={knowMoreOpen}
            onClose={() => setKnowMoreOpen(false)}
            title={null}
            footer={null}
            closeIcon={null}
          >
            <div className="knowmore-wrapper">
              <Container className="position-relative">
                <div
                  className="close-icon"
                  onClick={() => setKnowMoreOpen(false)}
                  style={{ cursor: 'pointer' }}
                >
                  <SvgIcon name="close" viewbox="0 0 7.481 7.521" />
                </div>
                <h1>More Details</h1>
                {tourDetails?.howToRedeem && (
                  <div
                    className="mb-5"
                    ref={(el) => {
                      sectionRef.current['htr'] = el;
                    }}
                  >
                    <h2>How To Redeem</h2>
                    <ul className="list1">
                      {htmlToList(tourDetails.howToRedeem).map((text, index) => (
                        <li key={index}>{text}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {tourDetails?.tourInclusion && (
                  <div
                    className="mb-5"
                    ref={(el) => {
                      sectionRef.current['wi'] = el;
                    }}
                  >
                    <h2 className="mb-3">What’s Included</h2>
                    <h3>Tour Inclusion :</h3>
                    <ul className="list1 mb-4">
                      {htmlToList(tourDetails.tourInclusion).map((text, index) => (
                        <li key={index}>{text}</li>
                      ))}
                    </ul>
                    {/* <h3>For Levels 148 + 125 + 124 :</h3>
                    <ul className='list1 mb-4'>
                        <li>Enjoy a personalized tour, guided by a Guest Ambassador. </li>
                        <li>Step out onto the world’s highest observation deck with an outdoor terrace at 555 meters. </li>
                        <li>Refresh yourself with signature refreshments at the SKY lounge. </li>
                        <li>Explore Dubai’s most famous landmarks with a unique interactive experience, using motion senses.</li>
                        <li>Continue your journey to levels 125 and 124.</li>
                    </ul>
                    <h3>For Levels 125 + 124 :</h3>
                    <ul className='list1 mb-4'>
                        <li>Be thrilled by the world’s fastest double-deck elevators, cruising at 10 m/s. </li>
                        <li>Take a closer look at the world below through avant-garde, high-powered, telescopes. </li>
                        <li>Level 125 offers a spacious deck tastefully decorated in Arabic mashrabiya for stunning 360-degree views. </li>
                        <li>Set off on a virtual reality experience to the pinnacle of Burj Khalifa.</li>
                    </ul>
                    <h3>Burj Khalifa At the Top Tickets</h3>
                    <ul className='list1'>
                        <li>Access to At The Top Level 124th Floor</li>
                        <li>Sky View Observatory tickets.</li>
                    </ul> */}
                  </div>
                )}

                {tourDetails?.tourExclusion && (
                  <div
                    className="mb-5"
                    ref={(el) => {
                      sectionRef.current['we'] = el;
                    }}
                  >
                    <h2>What’s Excluded</h2>
                    <ul className="list2">
                      {htmlToList(tourDetails.tourExclusion).map((text, index) => (
                        <li key={index}>{text}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {tourDetails?.importantInformation && (
                  <div
                    className="mb-5"
                    ref={(el) => {
                      sectionRef.current['if'] = el;
                    }}
                  >
                    <h2>Important Information</h2>
                    <ul className="list3">
                      {htmlToList(tourDetails.importantInformation).map((text, index) => (
                        <li key={index}>{text}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {tourDetails?.usefulInformation && (
                  <div
                    className="mb-5"
                    ref={(el) => {
                      sectionRef.current['ui'] = el;
                    }}
                  >
                    <h2>UsefuI Information</h2>
                    <ul className="list3">
                      {htmlToList(tourDetails.usefulInformation).map((text, index) => (
                        <li key={index}>{text}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* <div className='mb-5' ref={(el) => { sectionRef.current['faq'] = el; }}>
                    <h2 >FAQ</h2>
                    <Collapse accordion
                        defaultActiveKey={['1']}
                        expandIconPosition='end'
                        expandIcon={({ isActive }) => isActive ? <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" /> : <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                    >
                        <Panel header="What is TravellerPass?" key="1">
                            <p>
                                TravellerPass is a mobile application that gives you access to over 3000+ venues in the UAE and Oman through unlimited 2 for 1 offers or flat discounts across categories such as Dining, Experiences, Lifestyle, Wellness and our luxury segement, Prive.
                            </p>
                        </Panel>
                        <Panel header="Is there a limit to the number of redemption ?" key="2">
                            <p>
                                95% of all our offers are unlimited, which means you get your discount as many times as you want.
                            </p>
                        </Panel>
                        <Panel header="Where can I download TravellerPass?" key="3">
                            <p>
                                You can purchase our Annual Bundle membership plan to get started right away! What's more, you get all the days as promised based on the membership you choose from the date of activation, so you can keep availing our offers without any interruption.
                            </p>
                        </Panel>
                        <Panel header="When can I start my membership with Traveller" key="4">
                            <p>
                                Once you activate your membership, start saving by following the below steps:
                            </p>
                        </Panel>
                    </Collapse>
                </div> */}

                {tourDetails?.cancellationPolicyDescription && (
                  <div
                    className="mb-5"
                    ref={(el) => {
                      sectionRef.current['cp'] = el;
                    }}
                  >
                    <h2>{tourDetails.cancellationPolicyName}</h2>
                    <ul className="list3 mb-2 pb-2">
                      {htmlToList(tourDetails.cancellationPolicyDescription).map((text, index) => (
                        <li key={index}>{text}</li>
                      ))}
                    </ul>
                    {/* <h3>Tour Policy (3 -12)-Global Village</h3>
                    <ul className='list3'>
                        <li>Children under 3 years will be considered as Infant and entry will be free of cost. </li>
                        <li>People aged between 3 - 65 years will be considered as an adult and charged adult rate.</li>
                    </ul> */}
                  </div>
                )}

                {tourDetails?.termsAndConditions && (
                  <div
                    className="mb-5"
                    ref={(el) => {
                      sectionRef.current['tc'] = el;
                    }}
                  >
                    <h2>Terms & Conditions</h2>
                    <ul className="list4">
                      {htmlToList(tourDetails.termsAndConditions).map((text, index) => (
                        <li key={index}>{text}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </Container>
            </div>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default RaynaDetailsPage;

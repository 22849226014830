import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../common';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import './Footer.scss';

import logoImage from '../../../assets/images/logo.svg';
import { openChatSupport } from '../../../utility/chat';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="upper-footer">
          <Col lg="5" className="footer-col1">
            <img className="logo" src={logoImage} alt="logo" />
            <ul className="social-btn">
              <li>
                <Button
                  type="link"
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/discoveryourcity.io/',
                      '_blank',
                      'noopener noreferrer',
                    )
                  }
                >
                  <SvgIcon name="instagram" viewbox="0 0 17.137 17.137" />
                </Button>
              </li>
              <li>
                <Button
                  type="link"
                  onClick={() =>
                    window.open(
                      'https://www.facebook.com/discoveryourcity.io/',
                      '_blank',
                      'noopener noreferrer',
                    )
                  }
                >
                  <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                </Button>
              </li>
              <li>
                <Button
                  type="link"
                  onClick={() =>
                    window.open(
                      'https://www.youtube.com/@discoveryourcityio',
                      '_blank',
                      'noopener noreferrer',
                    )
                  }
                >
                  <SvgIcon name="youtube" viewbox="0 0 11 9" />
                </Button>
              </li>
              <li>
                <Button
                  type="link"
                  onClick={() =>
                    window.open(
                      'https://www.tiktok.com/@discoveryourcity.io',
                      '_blank',
                      'noopener noreferrer',
                    )
                  }
                >
                  <SvgIcon name="tiktok" viewbox="0 0 11 13" />
                </Button>
              </li>
            </ul>
            <p>Download the app</p>
            <div className="download-btns">
              <a
                href="https://apps.apple.com/ae/app/discover-your-city/id6473774812"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIcon name="apple" viewbox="0 0 22.982 28.234" />{' '}
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.traveller.discover&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIcon name="googleplay" viewbox="0 0 22.416 24.786" />
              </a>
              {/*<a
                href="https://appgallery.huawei.com/app/C109741589"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIcon name="app-gallery" viewbox="0 0 22.132 21.799" />
              </a>*/}
            </div>
          </Col>
          <Col lg="7">
            <Row>
              <Col sm="4" xs="6">
                <h4>ABOUT</h4>
                <ul>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <a
                      href="https://medium.com/@discoveryourcity.io"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Blog
                    </a>
                  </li>
                </ul>
              </Col>
              <Col sm="4" xs="6">
                <h4>COMPANY</h4>
                <ul>
                  <li>
                    <Link to="/terms-conditions">Terms {'&'} Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </Col>
              <Col sm="4">
                <h4>SUPPORT</h4>
                <ul>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        openChatSupport();
                      }}
                    >
                      Chat With Us
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="mailto:contact@dyc.global">
                      Contact Us
                    </a>
                    {/* <Link to="/contact"></Link>*/}
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="bottom-footer">
        <p>© 2024 TravellerPass LLC. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

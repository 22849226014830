import React from 'react';
import { Button, Modal } from 'antd';

const CancelErrorModal = ({ setShowModal, msg }) => {
  return (
    <>
      <Modal
        title=""
        open={true}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        closeIcon={false}
        footer={false}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p>{msg}</p>
          <Button
            type="primary"
            block
            style={{
              backgroundColor: 'black',
            }}
            onClick={() => setShowModal(false)}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CancelErrorModal;

import axios from 'axios';
import store from './store';
import { removeUser } from './slice/userSlice';

const apiClient = axios.create({
  baseURL: 'https://discover-city.com/wsDiscoverLiveWeb1.1',
  headers: {
    'Content-Type': 'application/json',
    'X-API-KEY': '7t9f798b765gge89f45e5ef9150fa073f8a61b78',
    uCurrency: 'AED',
  },
});

apiClient.interceptors.request.use((config) => {
  const xApiKey = JSON.parse(localStorage.getItem('xApiKey'));
  const { user, accessToken } = store.getState().user;
  const currency = store.getState().currency.selectedCurrency;

  config.headers = {
    ...config.headers,
    ...(xApiKey && { 'X-API-KEY': xApiKey }),
    ...(accessToken && { accesstoken: accessToken }),
    ...(user && { iUserID: user.iUserID }),
    ...(currency && { uCurrency: currency.uCurrency }),
  };

  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('AxiosInterceptorError', error);
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch(removeUser());
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export default apiClient;

import React, { useMemo, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Modal } from 'antd';
import apiClient from '../../../apiConfig';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert } from '../../../common/alert';
import { logEvent } from '../../../utility/analytics';

// const stripePromise = loadStripe(
//   'pk_test_51L5k11Ige6jHU45t9At0DFGCHueTidcYty0Sv8xmx5dUkVPgDfQVSqJ3OoiuNS5NzEpQ8w1BeBQlDSh4h3DDkWTU00Uu23f3lr',
// );
const stripePromise = loadStripe(
  'pk_live_51L5k11Ige6jHU45t7uZfxb1nc3eVjUEsqvvglO71b6XlUUpLGujhxbp9xYT5ieU4zqNNU0BRSYfG3JGr2jsElyr200UHMS2jI8',
);

function CheckoutForm({ stripeData, setGotoStripe, rTempBookingID }) {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const countryTypeParam = searchParams.get('countryType');
  const productIdParam = searchParams.get('productId');
  const productOPtionIdParam = searchParams.get('productOPtionId');
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const selectedCurrency = useSelector((state) => state.currency.selectedCurrency);

  const [cardFields, setCardFields] = useState({});
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const disabled = useMemo(() => {
    // card number, card cvv & card expiry 3 elements
    if (Object.keys(cardFields).length < 3) {
      return true;
    }

    for (const key in cardFields) {
      if (cardFields.hasOwnProperty(key) && cardFields[key] !== true) {
        return true;
      }
    }
    return false;
  }, [cardFields]);

  const handleCardNumberChange = async (event) => {
    console.log(event);

    setCardFields({
      ...cardFields,
      [event.elementType]: event.complete,
    });

    setError(event.error ? event.error.message : '');
  };

  const payMoney = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    const paymentResult = await stripe.confirmCardPayment(stripeData.stripeData.payment_intent, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });
    // console.log((paymentResult, "payment_intenttt"));
    if (paymentResult.error) {
      alert(paymentResult.error.message);
    } else {
      try {
        let response;
        if (countryTypeParam === 'global') {
          logEvent('global_payment_success');
          response = await apiClient.post('/globaltix/productBooking', {
            // productId:productIdParam,
            // productOPtionId:productOPtionIdParam,
            pTempBookingID: stripeData.pTempBookingID,
            paymentMethod: 'Card',
            currency: selectedCurrency?.uCurrency,
          });
          // console.log((response, "responseresponseresponse"));
        } else {
          logEvent('discover_payment_success');
          response = await apiClient.post('/discover/discoverBooking', {
            currency: selectedCurrency?.uCurrency,
            rTempBookingID: stripeData.rTempBookingID,
            payType: 'Card',
          });
        }
        if (response.data.status === 1) {
          if (countryTypeParam === 'global') {
            logEvent('global_booking_success');
          } else {
            logEvent('discover_booking_success');
          }
          let result = response.data.BookingData;
          localStorage.removeItem('payload');
          localStorage.removeItem('prices');
          history.replace('/discover/successfully', {
            img: result.rTourImage || result.gProductImage,
            start: result.startTime || result.visitTime,
            countryName: result.countryName || result.vCountryName,
            cityName: result.cityName || result.vCityName,
            tPersons: result.tPersons,
            tourName: result.tourName || result.productName,
            tServiceTotal: result.tServiceTotal,
            tourDate: result.tourDate || result.visitDate,
            tDayRemain: result.tDayRemain || result.pDayRemain,
          });
        } else {
          Alert.error(response.data.MESSAGE);
          // window.location.href = `/discover/booking-failed`;
        }
      } catch (error) {
        // window.location.href = `/discover/booking-failed`;
        // console.log((error));
      } finally {
        setGotoStripe(false);
      }
    }
    setPaymentLoading(false);
  };

  const cardStyle = {
    base: {
      color: '#242424',
      fontFamily: 'Hanken Grotesk, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#9b9ca3',
      },
    },
    invalid: {
      fontFamily: 'Hanken Grotesk, sans-serif',
      color: '#ff0050',
      iconColor: '#ff0050',
    },
  };

  return (
    <Modal
      title=""
      open={true}
      onCancel={() => {
        if (!isPaymentLoading) {
          setGotoStripe(false);
        }
      }}
      closeIcon={false}
      closable={false}
      footer={false}
    >
      <form className="payment-form" onSubmit={payMoney}>
        <label htmlFor="card-number-element">Card Number</label>

        <CardNumberElement
          id="card-number-element"
          options={{
            showIcon: true,
            iconStyle: 'solid',
            style: { ...cardStyle },
          }}
          onChange={handleCardNumberChange}
        />
        <div style={{ display: 'flex', marginTop: 16, marginBottom: 16 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginRight: 8,
            }}
          >
            <label htmlFor="card-cvv-element">CVC</label>

            <CardCvcElement
              id="card-cvv-element"
              options={{
                style: { ...cardStyle },
              }}
              onChange={handleCardNumberChange}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginLeft: 8,
            }}
          >
            <label htmlFor="card-expiry-element">Expiration</label>

            <CardExpiryElement
              id="card-expiry-element"
              options={{
                style: { ...cardStyle },
              }}
              onChange={handleCardNumberChange}
            />
          </div>
        </div>
        <button className="pay-button" disabled={isPaymentLoading || disabled}>
          {isPaymentLoading ? 'Processing...' : 'Pay'}
        </button>
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
      </form>
    </Modal>
  );
}

const PaymentComponent = ({ stripeData, setGotoStripe, rTempBookingID }) => {
  // console.log((stripeData, "stripeDatastripeData"));
  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CheckoutForm setGotoStripe={setGotoStripe} stripeData={stripeData} />
        </Elements>
      )}
    </>
  );
};

export default PaymentComponent;

import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../../components/common';
import { Button } from 'antd';
import './index.scss';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { formatCurrency } from '../../../utility/formatter';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Bookingsuccessfully = () => {
  const { state } = useLocation();
  const {
    img,
    start,
    cityName,
    countryName,
    tPersons,
    tourName,
    tServiceTotal,
    tourDate,
    tDayRemain,
  } = state || {};

  const selectedCurrency = useSelector((state) => state.currency.selectedCurrency);

  if (!state) {
    return <Redirect to="/" />;
  }
  return (
    <div className="checkout-discover-wrapper">
      <div className="checkoutdiscover-inner">
        <Container>
          <Row>
            <Col>
              <div className="checkout-discover-success">
                <div className="upper-section">
                  <div className="success-check" data-aos="zoom-in" data-aos-duration="1000">
                    <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                  </div>
                  <h2>Congratulations</h2>
                  <p>Booking received successfully</p>
                  <Link to="/" replace>
                    <Button type="primary">Done</Button>
                  </Link>
                </div>
                <div>
                  <div className="card">
                    <div className="amount-right">
                      <p>
                        {selectedCurrency.uCurrency} {formatCurrency(tServiceTotal)}
                      </p>
                      <small>Paid</small>
                    </div>
                    <div className="card-upper">
                      <div className="card-image">
                        <img src={img} alt="" />
                      </div>
                      <h3>{tourName}</h3>
                      <div className="address-row">
                        <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> {cityName}, {countryName}
                      </div>
                      <div className="datetime">
                        <div className="item">
                          {/* <h4>25th Jun 22</h4> */}
                          <h4>{tourDate}</h4>
                          <label>Date</label>
                        </div>
                        <div className="item">
                          {/* <h4>01:20 PM</h4> */}
                          <h4>{start}</h4>
                          <label>Time</label>
                        </div>
                        <div className="item">
                          <h4> {tPersons} </h4>
                          <label>Person</label>
                        </div>
                      </div>
                    </div>
                    <div className="card-bottom">
                      <Button type="secondary">
                        <SvgIcon name="check" viewbox="0 0 10.289 9.742" /> Confirmed
                      </Button>
                      <div className="right-tag">
                        <p>{tDayRemain}</p>
                        <small>DAYS</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-section">
                  <p>You can access ticket from My Booking section under profile</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Bookingsuccessfully;

import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Button, Form } from 'antd';
import OtpInput from 'react-otp-input';
import { Alert } from '../../common/alert';
import logoImage from '../../assets/images/logo-light.svg';
import logoImage1 from '../../assets/images/logo.svg';
import AwesomeImg from '../../assets/images/tp-left.png';
import VideoOne from '../../assets/video/bg_auth.mp4';
import apiClient from '../../apiConfig';
import { SHA256 } from 'crypto-js';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { logEvent } from '../../utility/analytics';
import { useDispatch } from 'react-redux';
import { setAccessToken, setUser } from '../../slice/userSlice';
import { useLoaderContext } from '../../components/common/Loader/LoaderContext';

const EnterOTP = () => {
  const history = useHistory();
  const [OTP, setOTP] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();
  const { setShowLoader } = useLoaderContext();

  const { from, formData } = location.state || {};
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const redirect_to = searchParams.get('redirect_to');

  const generateRandomID = () => {
    // Generate a random number between 100000 and 999999 (inclusive)
    return Math.floor(Math.random() * 900000) + 100000;
  };
  const handleResend = async () => {
    try {
      const { email } = formData;
      const response = await apiClient.post('/admin/checkEmail', { vEmail: email });

      // Handle the response and proceed accordingly
      if (response.data) {
        if (response.data.status === 0) {
          Alert.error(response.data.MESSAGE);
        } else {
          Alert.success(response.data.MESSAGE);
        }
      }
    } catch (e) {
    } finally {
      setShowLoader(false);
    }
  };
  const handleDoneClick = async () => {
    try {
      setShowLoader(true);
      const { firstName, email, mobile, mobileCountryCode } = formData;

      // Hash the appOTP using SHA256
      const hashedAppOTP = SHA256(OTP + 'Imc@$01tma$sa1@').toString();

      const body = {
        vEmail: email,
        vUserName: firstName,
        appOTP: hashedAppOTP,
        UDID: generateRandomID().toString(),
        eDeviceType: 'web',
        vDeviceToken: '',
        vMobileCountryCode: `+${mobileCountryCode}`,
        vMobileNo: mobile,
      };

      // Send the signup request
      const response = await apiClient.post(
        '/admin/prelogin1',
        body,
        // { headers }
      );

      if (response.data && response.data.status === 0) {
        logEvent('register_error', { message: response.data.MESSAGE });
        Alert.error(response.data.MESSAGE);
      } else if (response.data && response.data.status === 1) {
        logEvent('register_success', { user_id: response.data.DATA.iUserID });

        dispatch(setAccessToken(response.data.DATA.accesstoken));
        dispatch(setUser(response.data.DATA));

        // console.log(("Signup successful DATA:", response.data));
        // history.replace("/");
        let redirectUrl = window.location.origin;
        if (redirect_to && redirect_to !== 'null') {
          if (redirect_to.startsWith('/')) {
            redirectUrl = redirect_to;
          } else {
            redirectUrl = '/' + redirect_to;
          }
        }
        window.location.href = redirectUrl;
      } else {
        console.error('Unknown status:', response.data.status);
      }
    } catch (error) {
      console.error('Error signing up:', error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    logEvent('otp_verify_screen');
  }, []);

  if (!formData) {
    return <Redirect to={from?.pathname || '/'} />;
  }
  return (
    <div className="auth-wrapper">
      <div className="auth-left">
        <div className="authleft-inner">
          <div>
            <div className="dextop-logo">
              <Link to="/">
                <img src={logoImage} alt={logoImage} />
              </Link>
            </div>
            <h1>
              Discover <br />
              Your City
            </h1>
          </div>
          <div>
            <div className="awse-img">
              <img src={AwesomeImg} alt="Awesome" />
            </div>
            <h3>Awesome!</h3>
            <p>
              Awesome! It’s easy to use and very handy. It helps a lot to save more. Worth every
              penny
            </p>
            <div className="left-name">Nikol</div>
          </div>
        </div>
        <video playsInline autoPlay muted loop className="banner-video">
          <source src={VideoOne} />
        </video>
      </div>
      <div className="auth-right">
        <div className="authright-inner">
          <div className="right-upper">
            <div className="mobile-logo">
              <Link to="/">
                <img src={logoImage1} alt={logoImage1} />
              </Link>
            </div>
          </div>
          <div className="right-form right-form-top">
            <Form name="basic" autoComplete="off" layout="vertical">
              <div className="otpform-inner">
                <div className="otpform-upper">
                  <h2>Enter OTP</h2>
                  <p>OTP has been sent to your email</p>
                  <Form.Item className="mt-5">
                    <OtpInput
                      className="otp-input"
                      containerStyle="otp-input-container"
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                      secure
                    />
                  </Form.Item>
                  <div className="optsreend">
                    <span>Didn’t get it?</span>
                    <div
                      onClick={handleResend}
                      style={{ display: 'inline-block', cursor: 'pointer' }}
                      className="auth-upper-btn ant-btn resend-btn"
                    >
                      Resend
                    </div>
                  </div>
                </div>
                <div className="mt-auto">
                  <Form.Item className="m-0 py-3">
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={handleDoneClick}
                      block
                      disabled={OTP.length !== 4}
                    >
                      Done
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterOTP;

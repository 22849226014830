import Lottie from 'react-lottie';
import React from 'react';
import AnimationOne from '../../../assets/json/no_results.json';
import './index.scss';
import { Button } from 'antd';

export default function NoDataFoundListing(props) {
  const { onExploreClick } = props;
  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };

  return (
    <div className="empty-data-listing-card-wrapper">
      <div data-aos="fade-in" data-aos-duration="800">
        <Lottie options={animationLottie} height={280} width={280} />
        <h2>No exact matches</h2>
        <p>
          Try changing or removing some of your
          <br />
          filters or adjusting your search area.
        </p>
        <Button type="secondary" onClick={onExploreClick}>
          Explore
        </Button>
      </div>
    </div>
  );
}

import React, { useCallback, useState } from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Button } from 'antd';
import 'react-calendar/dist/Calendar.css';
import Sliders from 'react-slick';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import HeaderFilter from '../../components/common/HeaderFilter/HeaderFilter';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { debounce } from 'lodash';
import Apis from '../../utility/apis';
import { useSelector } from 'react-redux';
import apiClient from '../../apiConfig';
import { useHistory } from 'react-router-dom';
import mapStyle from './mapStyle';
import { useLoaderContext } from '../../components/common/Loader/LoaderContext';
import { Alert } from '../../common/alert';
import { TourItemCardMap } from '../../components/common/TourItemCardMap';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

const ListingPageMap = () => {
  const history = useHistory();
  const { setShowLoader } = useLoaderContext();
  const containerStyle = {
    width: '100%',
    height: '400px',
    maxHeight: 'calc(100vh - 205px)',
    minHeight: 'calc(100vh - 205px)',
  };

  const sliderRef = React.useRef(null);
  const settingsMapSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: 10,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const user = useSelector((state) => state.user.user);
  const selectedCity = useSelector((state) => state.countryCity.selectedCity);

  const [locationData, setlocationData] = useState([]);

  const fetchData = (selectedCategory, selectedDate, priceRange) => {
    setShowLoader(true);
    apiClient
      .post(Apis('byLocation', selectedCity.vCountryName, user ? 'loggedIn' : 'guest'), {
        iCountryID: selectedCity.iCountryID,
        dCurrentLat: selectedCity.vCityLatitude,
        dCurrentLong: selectedCity.vCityLongitude,
        vCityName: selectedCity.vCityName,
        iCityID: selectedCity.iCityID,
        dUserCurrentLat: newCenter.lat,
        dUserCurrentLong: newCenter.lng,
        radius: '20.0',
        rCategoryID: selectedCategory?.rCategoryID || '',
        gCategoryID: selectedCategory?.gCategoryID || '',
        FirstLaunch: 1,
      })
      .then((res) => {
        let result = res.data.DATA || [];
        if (priceRange && priceRange.start > 0 && priceRange.end > 0) {
          result = result.filter((item) => {
            return (
              Number(item.adultPrice) >= priceRange.start &&
              Number(item.adultPrice) <= priceRange.end
            );
          });
        }

        setlocationData(result);
        let mar = result.map((res) => ({ lat: Number(res.latitude), lng: Number(res.longitude) }));
        setMarkers(mar);

        if (result.length > 0) {
          setTimeout(() => {
            if (sliderRef.current) {
              sliderRef.current.slickGoTo(0);
            }
          }, 150);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setShowLoader(false));
  };

  const handleMarkerClick = (m, i) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(i);
    }
  };

  const initialMarkers = [
    // { lat: 40.7128, lng: -74.006 }, // New York City
    // { lat: 34.0522, lng: -118.2437 }, // Los Angeles
    // { lat: 41.8781, lng: -87.6298 } // Chicago
  ];

  // console.log((selectedCity.vCityLongitude, 'lolop'));
  const [center, setCenter] = useState({
    lat: Number(selectedCity.vCityLatitude),
    lng: Number(selectedCity.vCityLongitude),
  });
  let newCenter = {
    lat: Number(selectedCity.vCityLatitude),
    lng: Number(selectedCity.vCityLongitude),
  };
  const [markers, setMarkers] = useState(initialMarkers);

  const debouncedHandleChange = useCallback(
    debounce(async (value) => {
      //fetchData(0);
      if (mapRef.current?.center) {
        newCenter = {
          lat: mapRef.current.center.lat(),
          lng: mapRef.current.center.lng(),
        };

        // setCenter(newCenter);
      }
    }, 1500), // Adjust the debounce delay as needed (e.g., 300 milliseconds)
    [],
  );
  const handleCenterChanged = (E) => {
    debouncedHandleChange();
  };

  const mapRef = React.useRef();

  const handleFavToggle = (value, tourId) => {
    if (!user) {
      history.push('/login');
      return;
    }
    setlocationData(
      locationData.map((tour) => {
        // If the tourId matches, toggle isFavouriteOffer
        if (tour.tourId === tourId) {
          return {
            ...tour,
            isFavouriteOffer: tour.isFavouriteOffer === 1 ? 0 : 1,
          };
        }
        // Otherwise, return the tour unchanged
        return tour;
      }),
    );
    apiClient
      .post(Apis('toggleFavTour', selectedCity.vCountryName, user ? 'loggedIn' : 'guest'), {
        tourId,
        productId: tourId,
        favourite: value,
      })
      .then((response) => {
        if (response.data.status === 1) {
          Alert.success(response.data.MESSAGE);
        }
      });
  };
  return (
    <div className="twl-listing-wrapper">
      <section className="map-section">
        <HeaderFilter
          mapListingPage
          onTriggerDataFetch={(category, date, price) => {
            fetchData(category, date, price);
          }}
        />
        <div className="map-row">
          {/* <img className='map-img' src={MapImg} alt="map" /> */}
          {/* <div className='map-area'><iframe title='map' frameBorder="0" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Dubai+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div> */}
          <div className="map-area">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={11}
              onLoad={(map) => (mapRef.current = map)}
              onCenterChanged={handleCenterChanged}
              options={{ styles: mapStyle, maxZoom: 13, minZoom: 11 }}
            >
              {/* <MarkerF position={center} /> */}
              {markers.map((marker, index) => (
                <MarkerF
                  key={index}
                  position={marker}
                  onClick={(m) => handleMarkerClick(m, index)}
                />
              ))}
            </GoogleMap>
          </div>

          <div className="map-lists">
            <Container>
              <Row>
                <Col>
                  <Sliders className="map-slider" {...settingsMapSlider} ref={sliderRef}>
                    {locationData.map((item, index) => (
                      <TourItemCardMap
                        key={index}
                        item={item}
                        handleFavToggle={() => {
                          handleFavToggle(
                            item.isFavouriteOffer === 0 ? 1 : 0,
                            item.tourId || item.productId,
                          );
                        }}
                      />
                    ))}
                    {/* <div>
                                            <div className='map-card'>
                                                <div className='card-img'>
                                                    <img src={MapDiningimg2} alt="Map" />
                                                </div>
                                                <div className='map-card-right'>
                                                    <Button><SvgIcon name='heart' viewbox='0 0 10.238 9.131' /></Button>
                                                    <div className='upper-col'>
                                                        <h3>Dinner Junctions</h3>
                                                        <p><SvgIcon name='map' viewbox='0 0 8.358 12.537' /> 12km  /  Al Karama Center</p>
                                                    </div>
                                                    <div className='bottom-col'>
                                                        50% OFF
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='map-card'>
                                                <div className='card-img'>
                                                    <img src={MapDiningimg3} alt="Map" />
                                                </div>
                                                <div className='map-card-right'>
                                                    <Button><SvgIcon name='heart' viewbox='0 0 10.238 9.131' /></Button>
                                                    <div className='upper-col'>
                                                        <h3>Fish Dubai</h3>
                                                        <p><SvgIcon name='map' viewbox='0 0 8.358 12.537' /> 12km  /  Al Karama Center</p>
                                                    </div>
                                                    <div className='bottom-col'>
                                                        50% OFF
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='map-card'>
                                                <div className='card-img'>
                                                    <img src={MapDiningimg3} alt="Map" />
                                                </div>
                                                <div className='map-card-right'>
                                                    <Button><SvgIcon name='heart' viewbox='0 0 10.238 9.131' /></Button>
                                                    <div className='upper-col'>
                                                        <h3>Fish Dubai</h3>
                                                        <p><SvgIcon name='map' viewbox='0 0 8.358 12.537' /> 12km  /  Al Karama Center</p>
                                                    </div>
                                                    <div className='bottom-col'>
                                                        50% OFF
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                  </Sliders>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <Button
                    className="list-btn"
                    onClick={() => {
                      history.replace({
                        pathname: '/listing-page',
                        search: history.location.search,
                      });
                    }}
                  >
                    List <SvgIcon name="list" viewbox="0 0 18.156 12.109" />
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ListingPageMap;

// checkedItemsSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../apiConfig';
import { LoadState } from '../constants';
import { categorySlice } from './categorySlice';

const fetchData = async (state, firstPart) => {
  const { selectedCity } = state.countryCity;
  const { user } = state.user;
  if (!selectedCity) {
    throw Error('City not selected.');
  }

  let url1;
  let url2;
  if (user && selectedCity.isGlobaltix === 'Yes') {
    url1 = '/globaltix/featuredOfferList';
    url2 = '/globaltix/featuredOfferList2';
  } else if (!user && selectedCity.isGlobaltix === 'Yes') {
    url1 = '/deal/productFeaturedOfferList';
    url2 = '/deal/productFeaturedOfferList2';
  } else if (user && selectedCity.isDiscover === 'Yes') {
    url1 = '/discover/featuredOfferList';
    url2 = '/discover/featuredOfferList2';
  } else {
    url1 = '/deal/discoverFeaturedOfferList';
    url2 = '/deal/discoverFeaturedOfferList2';
  }

  const payload = {
    iCountryID: selectedCity.iCountryID,
    dCurrentLat: selectedCity.vCityLatitude,
    dCurrentLong: selectedCity.vCityLongitude,
    vCityName: selectedCity.vCityName,
    iCityID: selectedCity.iCityID,
    Language: 'en',
  };
  const response = firstPart
    ? await apiClient.post(url1, payload)
    : await apiClient.post(url2, payload);
  if (response.data.status !== 1) {
    throw Error(`${url1} received non zero status.`);
  }

  return response.data;
};

export const getHomePageData = createAsyncThunk('fetch/home', async (arg, thunkAPI) => {
  return fetchData(thunkAPI.getState(), true);
});

export const getHomePageCategoryData = createAsyncThunk('fetch/home2', async (arg, thunkAPI) => {
  return fetchData(thunkAPI.getState(), false);
});

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    headerImage: [],
    topTen: [],
    groupBanner: [],
    categoryTopTen: [],
    state: LoadState.IDLE,
    state2: LoadState.IDLE,
  },
  reducers: {
    toggleFav: (state, action) => {
      const { index, innerIndex, value } = action.payload;

      for (let i = 0; i < state.categoryTopTen.length; i++) {
        if (i === index) {
          state.categoryTopTen[index].topTenDeals[innerIndex].isFavouriteOffer = value;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHomePageData.pending, (state, action) => {
      state.state = LoadState.LOADING;
    });
    builder.addCase(getHomePageData.fulfilled, (state, action) => {
      const dataArr = action.payload.DATA;
      dataArr.forEach((item) => {
        if (item.type === 'Header Banner') {
          state.headerImage = item.DATA.hBannerImage || [];
        } else if (item.type === 'Group Banner') {
          state.groupBanner = item.DATA.discoverbanner || [];
        } else if (item.type === 'Top Ten Deals') {
          state.topTen = item.DATA.topTenDeals || [];
        }
      });
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getHomePageData.rejected, (state, action) => {
      state.state = LoadState.ERROR;
    });

    builder.addCase(getHomePageCategoryData.pending, (state, action) => {
      state.state2 = LoadState.LOADING;
    });
    builder.addCase(getHomePageCategoryData.fulfilled, (state, action) => {
      state.categoryTopTen = action.payload.DATA;
      state.state2 = LoadState.SUCCESS;
    });
    builder.addCase(getHomePageCategoryData.rejected, (state, action) => {
      state.state2 = LoadState.ERROR;
    });
  },
});

export const { toggleFav } = homeSlice.actions;
export default homeSlice.reducer;

import { Button, Checkbox, Divider, Form, Input } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SvgIcon } from '../../components/common';
import './index.scss';

import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { auth } from '../../utility/firebase';
import 'firebase/compat/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import apiClient from '../../apiConfig';
import logoImage from '../../assets/images/logo-light.svg';
import logoImage1 from '../../assets/images/logo.svg';
import AwesomeImg from '../../assets/images/tp-left.png';
import VideoOne from '../../assets/video/bg_auth.mp4';
import { setAccessToken, setUser } from '../../slice/userSlice';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../utility/analytics';
import { useLoaderContext } from '../../components/common/Loader/LoaderContext';
import { Alert } from '../../common/alert';

const Signup = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { setShowLoader } = useLoaderContext();

  const searchParams = new URLSearchParams(location.search);
  const redirect_to = searchParams.get('redirect_to');

  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    mobile: '',
    mobileCountryCode: '',
    agree: false,
  });

  const [mobileNumberValid, setMobileNumberValid] = useState(false);

  const isValid = useMemo(() => {
    return (
      mobileNumberValid &&
      formData.email &&
      /\S+@\S+\.\S+/.test(formData.email) &&
      formData.firstName &&
      formData.mobile &&
      formData.mobileCountryCode &&
      formData.agree
    );
  }, [mobileNumberValid, formData]);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    let inputValue = value;

    // Handle select input separately
    if (type === 'checkbox') {
      inputValue = checked;
    } else if (type === 'select-one') {
      inputValue = e;
    }

    setFormData({ ...formData, [name]: inputValue });
  };

  const handleCheckEmail = async () => {
    try {
      const { email } = formData;
      if (!email) {
        console.error('Please provide an email address.');
        return;
      }

      logEvent('email_register_initiated');

      setShowLoader(true);
      // Send checkEmail request
      const response = await apiClient.post(
        '/admin/checkEmail',
        { vEmail: email },
        // { headers: headers }
      );

      // Handle the response and proceed accordingly
      if (response.data) {
        // Check the MESSAGE and status conditions
        if (response.data.status === 0) {
          logEvent('signup_check_email_error', { message: response.data.MESSAGE });
          Alert.error(response.data.MESSAGE);
          // Navigate to login screen
          history.push('/login');
        } else {
          logEvent('signup_check_email_success');
          // Navigate to enter-otp screen
          history.push(`/verifyOtp?redirect_to=${redirect_to}`, {
            from: location,
            formData: formData,
          });
        }
      } else {
        console.error('Check email failed: DATA not found in response');
      }
    } catch (error) {
      console.error('Error checking email:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const authUser = async (token) => {
    try {
      setShowLoader(true);
      const response = await apiClient.post('/admin/doLoginWithIdToken', {
        idToken: token,
      });
      if (response.data) {
        if (response.data.status === 1) {
          dispatch(setAccessToken(response.data.DATA.accesstoken));
          dispatch(setUser(response.data.DATA));

          let redirectUrl = window.location.origin;
          if (redirect_to && redirect_to !== 'null') {
            if (redirect_to.startsWith('/')) {
              redirectUrl = redirect_to;
            } else {
              redirectUrl = '/' + redirect_to;
            }
          }
          window.location.href = redirectUrl;
        } else {
          console.error('Unknown status:', response.data.status);
        }
      }
    } catch (error) {
      console.error('Error login with idToken:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleGoogle = () => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        user
          .getIdToken()
          .then((token) => {
            //console.log(token);
            authUser(token);
          })
          .catch((error) => {});
        // ...
        //console.log(token);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const handleFacebook = () => {
    signInWithPopup(auth, new FacebookAuthProvider())
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        user
          .getIdToken()
          .then((token) => {
            //console.log(token);
            authUser(token);
          })
          .catch((error) => {});
        // ...
        //console.log(token);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const handleApple = () => {
    signInWithPopup(auth, new OAuthProvider('apple.com'))
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = OAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        user
          .getIdToken()
          .then((token) => {
            console.log(token);
            authUser(token);
          })
          .catch((error) => {});
        // ...
        //console.log(token);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = OAuthProvider.credentialFromError(error);
        // ...
      });
  };

  useEffect(() => {
    logEvent('register_screen');
  }, []);

  return (
    <div className="auth-wrapper">
      <div className="auth-left">
        <div className="authleft-inner">
          <div>
            <div className="dextop-logo">
              <Link to="/">
                <img src={logoImage} alt={logoImage} />
              </Link>
            </div>
            <h1>
              Discover <br />
              Your City
            </h1>
          </div>
          <div>
            <div className="awse-img">
              <img src={AwesomeImg} alt="Awesome" />
            </div>
            <h3>Awesome!</h3>
            <p>It's easy to use and very handy. It helps a lot to save more. Worth every penny</p>
            <div className="left-name">Nikol</div>
          </div>
        </div>
        <video playsInline autoPlay muted loop className="banner-video">
          <source src={VideoOne} />
        </video>
      </div>
      <div className="auth-right">
        <div className="authright-inner">
          <div className="right-upper">
            <div className="mobile-logo">
              <Link to="/">
                <img src={logoImage1} alt={logoImage1} />
              </Link>
            </div>
            <div className="upper-inner">
              <h2>Register</h2>
              <div className="right-head">
                <span>Already have an account?</span>
                <Link
                  to={
                    '/login' +
                    (redirect_to && redirect_to !== 'null' ? `?redirect_to=${redirect_to}` : '')
                  }
                  className="auth-upper-btn ant-btn"
                  replace
                >
                  Log In
                </Link>
              </div>
            </div>
          </div>
          <div className="right-form">
            <Form name="basic" autoComplete="off" layout="vertical">
              <Form.Item label="First Name">
                <Input
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  prefix={<SvgIcon name="user" viewbox="0 0 15.381 15.381" />}
                  placeholder="Enter"
                />
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  prefix={<SvgIcon name="email-icon" viewbox="0 0 30 30" />}
                  placeholder="Enter"
                />
              </Form.Item>
              <Form.Item label="Mobile">
                <PhoneInput
                  country={'ae'}
                  countryCodeEditable={false}
                  preferredCountries={['ae', 'in', 'om']}
                  autoFormat
                  enableSearch
                  onChange={(value, country) => {
                    const countryCode = country.dialCode;
                    const phoneNumber = value.slice(country.dialCode.length);
                    setFormData({
                      ...formData,
                      mobile: phoneNumber,
                      mobileCountryCode: countryCode,
                    });
                  }}
                  isValid={(value, country) => {
                    const countryCode = country.dialCode;
                    const phoneNumber = value.slice(countryCode.length);

                    const length = phoneNumber.replace(/\s+/, '').length;

                    if (
                      (countryCode === '971' && length === 9) ||
                      (countryCode === '968' && length === 8) ||
                      (countryCode !== '971' && countryCode !== '968' && length === 10)
                    ) {
                      setMobileNumberValid(true);
                      return true;
                    }

                    if (
                      (countryCode === '971' && length > 9) ||
                      (countryCode === '968' && length > 8) ||
                      (countryCode !== '971' && countryCode !== '968' && length > 10)
                    ) {
                      setMobileNumberValid(false);
                      return 'Invalid phone number.';
                    }

                    setMobileNumberValid(false);
                    return true;
                  }}
                />
              </Form.Item>
              <Form.Item className="agree-check">
                <Checkbox name="agree" checked={formData.agree} onChange={handleInputChange}>
                  I agree to the{' '}
                  <a href="/terms-conditions" className="terms-link">
                    Terms and Conditions
                  </a>
                </Checkbox>
              </Form.Item>
              <Form.Item className="m-0 py-3">
                <Button
                  type="primary"
                  htmlType="button"
                  block
                  onClick={handleCheckEmail}
                  disabled={!isValid}
                >
                  Sign Up
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="auth-right-footer">
            <Divider>Or Sign up with your details</Divider>
            <ul>
              {/* <li>
                <Button onClick={handleApple}>
                  <SvgIcon name="apple" viewbox="0 0 22.982 28.234" />
                </Button>
              </li>*/}
              <li>
                <Button onClick={handleGoogle}>
                  <SvgIcon name="google" viewbox="0 0 28.688 29.243" />
                </Button>
              </li>
              <li>
                <Button onClick={handleFacebook}>
                  <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;

import React, { useEffect, useState } from 'react';
import { Col, Container, FloatLabel, Row, SvgIcon } from '../../../components/common';
import { Button, Form, Input, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import './index.scss';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import apiClient from '../../../apiConfig';
import Apis from '../../../utility/apis';
import { useSelector } from 'react-redux'; // import { loadStripe } from '@stripe/stripe-js';
import PaymentComponent from './PaymentComponent';
import { formatCurrency, toCamelCase } from '../../../utility/formatter';
import { useLoaderContext } from '../../../components/common/Loader/LoaderContext';
import { htmlToList } from '../../../utility/parser';
import dayjs from 'dayjs';
import { logEvent } from '../../../utility/analytics';
import { cloneDeep } from 'lodash';

const CheckoutDiscover = () => {
  const history = useHistory();
  const { state, search } = useLocation();
  const { itemCounts, promo } = state || {};

  const searchParams = new URLSearchParams(search);
  let payload = JSON.parse(localStorage.getItem('payload'));
  // Get individual parameters
  const tourId = searchParams.get('tourId');
  const adult = payload.adult;
  const child = payload.child;
  const infant = payload.infant;
  const tourOptionId = payload.tourOptionId;
  const travelDate = payload.travelDate;
  const transferId = payload.transferId;
  const timeSlotId = payload.timeSlotId;
  const timeSlot = payload.timeSlot;
  const pickup = payload.pickup;
  const AdultRateParam = payload.AdultRate;
  const ChildRateParam = payload.ChildRate;
  const countryTypeParam = searchParams.get('countryType');
  const productOPtionId = searchParams.get('productOPtionId');
  const productId = searchParams.get('productId');
  const transferType = decodeURIComponent(payload.transferType);

  const { setShowLoader } = useLoaderContext();

  const userObj = useSelector((state) => state.user.user);

  const selectedCity = useSelector((state) => state.countryCity.selectedCity);
  const selectedCurrency = useSelector((state) => state.currency.selectedCurrency);

  const [title, setTitle] = useState('Mr.');
  const [name, setName] = useState(userObj.vUserName);
  const [mobile, setMobile] = useState(userObj.vMobileNo);
  const [email, setEmail] = useState(userObj.vEmail);
  const [cardnumber, setCardnumber] = useState('');
  const [expiration, setExpiration] = useState('');
  const [cvv, setCvv] = useState('');
  const [promoCodeValue, setPromoCodeValue] = useState(promo);
  const [promocode, setPromocode] = useState(promo?.tPromoCode || '');
  const [reducedBy, setReducedBy] = useState(0);
  const [bookingData, setBookingData] = useState(null);
  const [grandTotal, setgrandTotal] = useState(0);
  const [gotoStripe, setGotoStripe] = useState(false);
  const [showPromoValidator, setshowPromoValidator] = useState(false);

  useEffect(() => {
    if (!itemCounts) {
      history.replace('/');
      return;
    }
    const tmpItemsCount = cloneDeep(itemCounts);

    let grandTotal = Object.keys(tmpItemsCount).reduce((total, key) => {
      return total + tmpItemsCount[key].count * tmpItemsCount[key].price;
    }, 0);

    if (promoCodeValue) {
      Object.keys(tmpItemsCount).forEach((key) => {
        let discount = tmpItemsCount[key].price * (promoCodeValue.tPromoDiscount / 100);
        if (discount > promoCodeValue.tMaxDiscount) {
          discount = promoCodeValue.tMaxDiscount;
        }
        tmpItemsCount[key] = { ...tmpItemsCount[key], price: tmpItemsCount[key].price - discount };
      });

      let discountedGrandTotal = Object.keys(tmpItemsCount).reduce((total, key) => {
        return total + tmpItemsCount[key].count * tmpItemsCount[key].price;
      }, 0);

      setReducedBy(grandTotal - discountedGrandTotal);
    } else {
      setReducedBy(0);
    }
    setgrandTotal(grandTotal);
  }, [promoCodeValue]);

  useEffect(() => {
    const fetchBookingData = async () => {
      setShowLoader(true);
      try {
        logEvent('discover_checkout', {
          option_id: tourOptionId,
          date: travelDate,
          transfer_id: transferId,
        });
        const response = await apiClient.post('/discover/discoverBookingData', {
          tourOptionId,
          travelDate,
          transferId,
        });
        // console.log(("response.data?.DATA: ", response.data?.DATA));
        setBookingData(response.data?.DATA || null);
      } catch (error) {
        console.error('Error fetching booking data:', error);
      } finally {
        setShowLoader(false);
      }
    };
    const fetchGlobalBookingData = async () => {
      setShowLoader(true);
      try {
        logEvent('discover_checkout', {
          product_id: productId,
          product_option_id: productOPtionId,
        });
        const response = await apiClient.post('/globaltix/productBookingData', {
          productId: productId,
          productOPtionId: productOPtionId,
        });
        // console.log(("response.data?.DATA: ", response.data?.DATA));
        setBookingData(response.data?.DATA || null);
      } catch (error) {
        console.error('Error fetching booking data:', error);
      } finally {
        setShowLoader(false);
      }
    };

    // Call fetchBookingData function
    if (countryTypeParam !== 'global') {
      fetchBookingData();
    } else {
      fetchGlobalBookingData();
    }
  }, []);

  // console.log(("bookingData bookingData bookingData: ", bookingData));

  console.log('startTime:', bookingData?.tourPriceTransfertimeDetails?.startTime);
  const startTime = bookingData?.tourPriceTransfertimeDetails?.startTime;
  const [stripeData, setStripeData] = useState({});
  // console.log((AdultRateParam, "AdultRateParam"));
  const fetchData = async () => {
    setShowLoader(true);
    try {
      logEvent('begin_checkout', {
        source: 'discover',
        tour_id: tourOptionId,
        total_price: reducedBy ? grandTotal - reducedBy : grandTotal,
        promo_applied: promocode ? 'Yes' : 'No',
      });
      const response = await apiClient.post(
        // Fetch data from API
        Apis(
          'transactionPrepare',
          countryTypeParam !== 'global' ? 'UAE' : 'OTHERS',
          userObj ? 'loggedIn' : 'guest',
        ),
        {
          tourId: tourId,
          tourOptionId: tourOptionId,
          adultCount: String(adult),
          childCount: String(child),
          infantCount: String(infant),
          tourDate: travelDate,
          timeSlotId: timeSlotId ?? 0,
          startTime: timeSlot ? timeSlot : startTime ? startTime : '16:00:00',
          pickup: pickup,
          adultRate: AdultRateParam,
          childRate: ChildRateParam,
          serviceTotal: reducedBy ? grandTotal - reducedBy : grandTotal,
          serviceType: 'Tour',
          prefix: title,
          firstName: name,
          lastName: '',
          email: email,
          mobile: mobile,
          nationality: '',
          message: '',
          leadPassenger: '1',
          paxType: 'Adult',
          clientReferenceNo: '3252628751',
          currency: selectedCurrency.uCurrency || 'AED',
          tPromoCode: promocode,
          transferId: transferId,
          tSource: countryTypeParam !== 'global' ? 'Discover' : 'Globaltix',
        },
      );

      console.log('transactionPrepare response status : ', response?.data?.DATA.stripeData);
      if (response?.data?.status === 1) {
        setStripeData(response?.data?.DATA);
        setGotoStripe(true);
      }

      if (response.data?.status === 1) {
        // history.push("/discover/successfully");
      } else {
        history.replace('/discover/booking-failed');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setShowLoader(false);
    }
  };
  const fetchDataGlobal = async () => {
    setShowLoader(true);
    try {
      logEvent('begin_checkout', {
        source: 'global',
      });

      const response = await apiClient.post(
        // Fetch data from API
        Apis(
          'transactionPrepare',
          countryTypeParam !== 'global' ? 'UAE' : 'OTHERS',
          userObj ? 'loggedIn' : 'guest',
        ),
        {
          ...JSON.parse(localStorage.getItem('payload')),
          pTicketTypeIdDetails: JSON.stringify(
            JSON.parse(localStorage.getItem('payload')).pTicketTypeIdDetails,
          ),
          customerName: name,
          email: email,
          mobilePrefix: '+971',
          mobileNumber: mobile,
          currency: selectedCurrency.uCurrency || 'AED',
          uPrefix: title,
          tPromoCode: promocode,
        },
        //   {
        //     "productId": "4661",
        //     "productOPtionId": "8926",
        //     "visitDate": "2024-04-25T00:00:00",
        //     "pTicketTypeIdDetails": "[{\"event_id\":0,\"fromResellerId\":\"\",\"id\":13413,\"packageItems\":[],\"quantity\":8,\"questionList\":[],\"sellingPrice\":88,\"typeName\":\"ADULT\",\"visitDate\":\"2024-05-01T00:00:00\",\"visitDateSettings\":[]}]",
        //     "eventId": "",
        //     "available": 999999,
        //     "customerName": "Ansari",
        //     "email": "abdullahansari9768@gmail.com",
        //     "mobilePrefix": "+971",
        //     "mobileNumber": "1234567890",
        //     "currency": "AED",
        //     "uPrefix": "Mr."
        // }
      );

      console.log('transactionPrepare response status : ', response?.data?.DATA.stripeData);
      if (response?.data?.status === 1) {
        setStripeData(response?.data?.DATA);
        setGotoStripe(true);
      }

      if (response.data?.status === 1) {
        // history.push("/discover/successfully");
      } else {
        history.replace('/discover/booking-failed');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const handlePayAndConfirm = () => {
    if (countryTypeParam === 'global') {
      fetchDataGlobal(); // Call fetchData function
    } else {
      fetchData(); // Call fetchData function
    }
  };

  // Function to format time or default to 12pm
  const formatTime = (time) => {
    if (!time) return '12pm';
    // Add formatting logic if needed
    return time;
  };

  const handlePromo = async () => {
    setShowLoader(true);
    try {
      const response = await apiClient.post('/discover/checkPromoCode', {
        tPromoCode: promocode,
        tPromoProductId: tourId,
        tSource: countryTypeParam !== 'global' ? 'Discover' : 'Globaltix',
      });
      if (response.data.status === 1) {
        setshowPromoValidator(true);
        setPromoCodeValue(response.data.DATA);
      } else {
        setReducedBy(0);
        setshowPromoValidator(true);
        setPromoCodeValue(null);
      }
    } catch (e) {
    } finally {
      setShowLoader(false);
    }
  };
  const currencyData = useSelector((state) => state.currency.selectedCurrency);
  const goBack = () => {
    history.goBack();
  };

  const [separatedString, setseparatedString] = useState();
  useEffect(() => {
    if (countryTypeParam === 'global') {
      const typeCounts = {};

      JSON.parse(localStorage.getItem('payload')).pTicketTypeIdDetails.forEach((item) => {
        if (item.typeName in typeCounts) {
          typeCounts[item.typeName] = item.quantity;
        } else {
          typeCounts[item.typeName] = item.quantity;
        }
      });
      // Create the string dynamically
      let result = '';
      for (const typeName in typeCounts) {
        result += ` ${toCamelCase(typeName)} (${typeCounts[typeName]})  `;
      }
      setseparatedString(result.trim());
    }

    return () => {};
  }, []);

  return (
    <div className="checkout-discover-wrapper">
      {gotoStripe && <PaymentComponent stripeData={stripeData} setGotoStripe={setGotoStripe} />}
      {bookingData && (
        <div className="checkoutdiscover-inner">
          <Container>
            <Row>
              <Col>
                <Form name="basic" autoComplete="off" layout="vertical">
                  <div className="twl-row">
                    <div className="checkout-left">
                      <div className="twl-header" data-aos="fade-in" data-aos-duration="800">
                        <span onClick={goBack} style={{ cursor: 'pointer' }}>
                          <SvgIcon name="arrow-left" viewbox="0 0 12.251 8.653" />
                        </span>
                        <h2>Confirm and Pay</h2>
                      </div>
                      <div className="twl-content">
                        <div className="twlcontent-inner">
                          <div className="confirm-details">
                            <h3>Details</h3>
                            <div className="dtl-row">
                              <div className="dtl-left">
                                <p>
                                  {travelDate ||
                                    JSON.parse(localStorage.getItem('payload')).visitDate.split(
                                      'T',
                                    )[0]}
                                </p>
                                <label>Date</label>
                              </div>
                              <div className="dtl-right">
                                {/* <Button type="text">Edit</Button> */}
                              </div>
                            </div>
                            {/* {bookingData?.tourPriceTransfertimeDetails?.startTime && bookingData?.tourPriceTransfertimeDetails?.startTime !== '00:00:00' && */}
                            {timeSlot && (
                              <div className="dtl-row">
                                <div className="dtl-left">
                                  <p>{dayjs(timeSlot, 'HH:mm').format('hh:mm A')} </p>
                                  <label>Time</label>
                                </div>
                              </div>
                            )}
                            {JSON.parse(localStorage.getItem('payload')).visitDate &&
                              JSON.parse(localStorage.getItem('payload')).visitDate.split(
                                'T',
                              )[1] !== '00:00:00' && (
                                <div className="dtl-row">
                                  <div className="dtl-left">
                                    <p>
                                      {bookingData?.tourPriceTransfertimeDetails?.startTime ||
                                        dayjs(
                                          JSON.parse(
                                            localStorage.getItem('payload'),
                                          ).visitDate.split('T')[1],
                                          'HH:mm',
                                        ).format('hh:mm A')}{' '}
                                    </p>
                                    <label>Time</label>
                                  </div>
                                </div>
                              )}
                            <div className="dtl-row">
                              <div className="dtl-left">
                                <p>
                                  {countryTypeParam === 'global'
                                    ? separatedString
                                    : `${parseInt(adult) !== 0 ? `Adult (${parseInt(adult)})` : ''}   ${parseInt(child) !== 0 ? `Child (${parseInt(child)})` : ''}  ${parseInt(infant) !== 0 ? `Infant (${parseInt(infant)})` : ''} `}
                                </p>
                                <label>Participants</label>
                              </div>
                              <div className="dtl-right">
                                {/* <Button type="text">Edit</Button> */}
                              </div>
                            </div>
                            {countryTypeParam !== 'global' && (
                              <div className="dtl-row">
                                <div className="dtl-left">
                                  <p>{pickup ? pickup : transferType}</p>
                                  <label>{pickup ? transferType : 'Transfer Type'}</label>
                                </div>
                                <div className="dtl-right">
                                  {/* <Button type="text">Edit</Button> */}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="participants-details">
                            <h3>Participants</h3>
                            <Row className="pt-2">
                              <Col className="colum" sm="2">
                                <Form.Item className="icon-less">
                                  <FloatLabel label="TITLE" name="title" value={title}>
                                    <Select
                                      value={title}
                                      suffixIcon={<SvgIcon name="down-arrow" viewbox="0 0 18 9" />}
                                      onChange={(value) => setTitle(value)}
                                      options={[
                                        { value: 'Mr', label: 'Mr' },
                                        { value: 'Mrs', label: 'Mrs' },
                                      ]}
                                    />
                                  </FloatLabel>
                                </Form.Item>
                              </Col>
                              <Col className="colum" sm="4">
                                <Form.Item className="icon-less">
                                  <FloatLabel label="Name" name="name" value={name}>
                                    <Input value={name} onChange={(e) => setName(e.target.value)} />
                                  </FloatLabel>
                                </Form.Item>
                              </Col>
                              <Col className="colum" sm="6">
                                <Form.Item className="icon-less">
                                  <FloatLabel label="MOBILE" name="mobile" value={mobile}>
                                    <Input
                                      value={mobile}
                                      onChange={(e) => setMobile(e.target.value)}
                                    />
                                  </FloatLabel>
                                </Form.Item>
                              </Col>
                              <Col className="colum" sm="6">
                                <Form.Item className="icon-less">
                                  <FloatLabel label="EMAIL" name="email" value={email}>
                                    <Input
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                  </FloatLabel>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>

                          {/* <div className="payment-details">
                          <h3>Payment</h3>
                          <img
                            className="paymenttype-img"
                            src={PaymentsType}
                            alt="Payment"
                          />
                          <Form.Item>
                            <Select
                              defaultValue="cc"
                              suffixIcon={
                                <SvgIcon name="down-arrow" viewbox="0 0 18 9" />
                              }
                              menuItemSelectedIcon={
                                <SvgIcon
                                  name="check"
                                  viewbox="0 0 9.344 7.199"
                                />
                              }
                              popupClassName="payment-drop"
                              options={[
                                {
                                  value: "mc",
                                  label: (
                                    <div className="paymenttype-item">
                                      <img src={MasterCard} alt="Credit Card" />{" "}
                                      ****3759{" "}
                                    </div>
                                  ),
                                },
                                {
                                  value: "cc",
                                  label: (
                                    <div className="paymenttype-item">
                                      <img src={CreditCard} alt="Credit Card" />{" "}
                                      Credit Card or debit Card{" "}
                                    </div>
                                  ),
                                },
                                {
                                  value: "gpay",
                                  label: (
                                    <div className="paymenttype-item">
                                      <img src={GpayIcon} alt="Credit Card" />{" "}
                                      Google Pay{" "}
                                    </div>
                                  ),
                                },
                                {
                                  value: "aply",
                                  label: (
                                    <div className="paymenttype-item">
                                      <img src={AppleIcon} alt="Credit Card" />{" "}
                                      Apple Pay
                                    </div>
                                  ),
                                },
                              ]}
                            />
                          </Form.Item>
                          <Row>
                            <Col className="colum" sm="12">
                              <Form.Item className="icon-less">
                                <FloatLabel
                                  label="Name"
                                  name="cardnumber"
                                  value={cardnumber}
                                >
                                  <Input
                                    value={cardnumber}
                                    onChange={(e) =>
                                      setCardnumber(e.target.value)
                                    }
                                  />
                                </FloatLabel>
                              </Form.Item>
                            </Col>
                            <Col className="colum" sm="6">
                              <Form.Item className="icon-less">
                                <FloatLabel
                                  label="Expiration"
                                  name="expiration"
                                  value={expiration}
                                >
                                  <Input
                                    value={expiration}
                                    onChange={(e) =>
                                      setExpiration(e.target.value)
                                    }
                                  />
                                </FloatLabel>
                              </Form.Item>
                            </Col>
                            <Col className="colum" sm="6">
                              <Form.Item className="icon-less">
                                <FloatLabel label="CVV" name="cvv" value={cvv}>
                                  <Input
                                    value={cvv}
                                    onChange={(e) => setCvv(e.target.value)}
                                  />
                                </FloatLabel>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="checkout-right">
                      <div className="twl-content">
                        <div className="twlcontent-inner">
                          <ul className="summary-list" data-aos="fade-left" data-aos-duration="800">
                            <li>
                              <div className="image-col">
                                <img
                                  src={bookingData?.rTourImage || bookingData?.pImage}
                                  alt="Explore Magic Imagica"
                                />
                              </div>
                              <div className="list-left">
                                <h3>{bookingData?.optionName}</h3>
                                <p>
                                  <SvgIcon name="map" viewbox="0 0 8.358 12.537" />{' '}
                                  {bookingData?.cityName || bookingData?.vCityName},{' '}
                                  {bookingData?.countryName || bookingData?.vCountryName}
                                </p>
                              </div>
                            </li>
                          </ul>
                          <div
                            className="payment-details"
                            data-aos="fade-left"
                            data-aos-duration="1200"
                          >
                            {/* <Row className="discount-row">
                            <Col>Service Charge</Col>
                            <Col className="text-right">AED 23</Col>
                          </Row>
                          <Row className="discount-row">
                            <Col>Tax</Col>
                            <Col className="text-right">AED 78</Col>
                          </Row> */}
                            {Boolean(reducedBy) && reducedBy !== 0 && (
                              <>
                                <Row className="willpay-row">
                                  <Col className="">Calculated Amount</Col>
                                  <Col className="text-right">
                                    {currencyData?.uCurrency}
                                    <b>{formatCurrency(grandTotal)}</b>
                                  </Col>
                                </Row>
                                <Row className="willpay-row">
                                  <Col className="">Coupon Amount</Col>
                                  <Col className="text-right">
                                    {currencyData?.uCurrency}
                                    <b> -{formatCurrency(reducedBy)}</b>
                                  </Col>
                                </Row>
                              </>
                            )}
                            <Row className="willpay-row">
                              <Col>Grand Total</Col>
                              <Col className="text-right">
                                {currencyData?.uCurrency}{' '}
                                <b>{formatCurrency(grandTotal - reducedBy)}</b>
                              </Col>
                            </Row>
                          </div>
                          {/* <Link to="/discover/successfully"> */}
                          <Button
                            className="pay-btn"
                            data-aos="fade-left"
                            data-aos-duration="1250"
                            type="secondary"
                            block
                            onClick={handlePayAndConfirm}
                          >
                            Pay and Confirm
                          </Button>
                          {/* </Link> */}
                          <div data-aos="fade-left" data-aos-duration="800" className="w-100">
                            <Form.Item className="">
                              <div className="coupon-wrapper">
                                <FloatLabel
                                  label="ENTER PROMO CODE"
                                  name="promocode"
                                  value={promocode}
                                >
                                  <Input
                                    prefix={
                                      <SvgIcon name="promo-code" viewbox="0 0 14.611 10.163" />
                                    }
                                    value={promocode}
                                    onChange={(e) => setPromocode(e.target.value)}
                                    onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                  />
                                </FloatLabel>
                                <Button type="primary" onClick={handlePromo}>
                                  Check
                                </Button>
                              </div>
                              {showPromoValidator && (
                                <>
                                  {reducedBy === 0 ? (
                                    <div className="error-msg">
                                      <SvgIcon name="error-icon" viewbox="0 0 12 12" /> Code is
                                      invalid
                                    </div>
                                  ) : (
                                    <div className="success-msg">
                                      <SvgIcon name="success-icon" viewbox="0 0 12 12" /> Code is
                                      valid
                                    </div>
                                  )}
                                </>
                              )}
                            </Form.Item>
                          </div>
                          {/* <Button
                            className="learn-btn"
                            data-aos="fade-left"
                            data-aos-duration="1300"
                            type="link"
                            icon={<SvgIcon name="play" viewbox="0 0 41.93 41.965" />}
                            block
                          >
                            Learn How to book
                          </Button>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
            {bookingData &&
              bookingData.tourPriceTransfertimeDetails?.tourHighLights?.length > 0 && (
                <Row>
                  <Col className="highlights-row">
                    <h3>Highlights</h3>
                    <ul>
                      {bookingData.tourPriceTransfertimeDetails.tourHighLights.map(
                        (highlight, index) =>
                          highlight.rHighlights && (
                            <li key={index}>
                              <div className="left-icon">
                                <img
                                  className="icon"
                                  src={highlight.rHighlightsIcon}
                                  alt={highlight.rHighlightsTitle}
                                />
                              </div>
                              <div>
                                <label>{highlight.rHighlightsTitle}</label>
                                {highlight.rHighlights}
                              </div>
                            </li>
                          ),
                      )}
                    </ul>
                  </Col>
                </Row>
              )}
            <Row>
              <Col className="cancelpolicy-info">
                <h3>Description</h3>
                <p className="mb-4">
                  {bookingData?.optionDescription || bookingData?.pDescription}
                </p>

                {/* <h3>How to use</h3>
                <p className="mb-4">
                  Get Ready to enjoy At The Top Level 124th Floor & Sky View
                  Observatory tickets at the sky view Address Towers
                </p> */}

                {bookingData?.inclusion && (
                  <>
                    <h3>Inclusions</h3>
                    <ul>
                      {htmlToList(bookingData.inclusion).map((text, index) => (
                        <li key={index}>{text}</li>
                      ))}
                    </ul>
                  </>
                )}
                {bookingData?.pinclusions && bookingData?.pinclusions.length !== 0 && (
                  <>
                    <h3>Inclusions</h3>
                    <ul>
                      {bookingData?.pinclusions.map((e) => (
                        <li>{e}</li>
                      ))}
                    </ul>
                  </>
                )}
                {/* <ul>
                  <li>
                    Familiarize yourself with cancellation and refund policies.
                  </li>
                  <li>
                    Check if the package can be customized to your needs.{" "}
                  </li>
                  <li>Compare the bundled price with individual costs.</li>
                </ul> */}
                {bookingData?.exclusion && (
                  <>
                    <h3>Exclusions</h3>
                    <ul>
                      {htmlToList(bookingData.exclusion).map((text, index) => (
                        <li key={index}>{text}</li>
                      ))}
                    </ul>
                  </>
                )}
                {bookingData?.pexclusions && bookingData?.pexclusions.length !== 0 && (
                  <>
                    <h3>Exclusions</h3>
                    <ul>
                      {bookingData?.pexclusions.map((e) => (
                        <li>{e}</li>
                      ))}
                    </ul>
                  </>
                )}
                {/* 
                <ul>
                  <li>
                    Familiarize yourself with cancellation and refund policies.{" "}
                  </li>
                  <li>
                    Check if the package can be customized to your needs.{" "}
                  </li>
                  <li>Compare the bundled price with individual costs.</li>
                </ul> */}
                {bookingData?.tourPriceTransfertimeDetails?.cancellationPolicyDescription && (
                  <>
                    <h3>{bookingData?.tourPriceTransfertimeDetails?.cancellationPolicyName}</h3>
                    <p className="mb-4">
                      {htmlToList(
                        bookingData.tourPriceTransfertimeDetails.cancellationPolicyDescription,
                      ).map((text, index) => (
                        <p key={index}>{text}</p>
                      ))}
                    </p>
                  </>
                )}
                {bookingData?.pCancellationPolicy && (
                  <>
                    <h3>Cancellation Policy</h3>
                    <p className="mb-4">
                      {htmlToList(bookingData.pCancellationPolicy).map((text, index) => (
                        <p key={index}>{text}</p>
                      ))}
                    </p>
                  </>
                )}

                {/* <p className="mb-4">
                  {
                    bookingData?.tourPriceTransfertimeDetails
                      ?.cancellationPolicy
                  }
                </p> */}
                {/* <ul>
                  {bookingData?.tourPriceTransfertimeDetails?.cancellationPolicyDescription
                    ?.replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
                    ?.split("\n") // Split the string by new lines
                    .filter((item) => item.trim() !== "") // Remove empty strings
                    .map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                </ul> */}
                {bookingData?.termsandcondition && (
                  <>
                    <h3>Terms and Condition</h3>
                    <ul>
                      {bookingData?.termsandcondition.split('\n').map((e) => (
                        <li>{e}</li>
                      ))}
                    </ul>
                  </>
                )}
                {bookingData?.ptermsAndConditions &&
                  bookingData?.ptermsAndConditions.length !== 0 && (
                    <>
                      <h3>Terms and Condition</h3>
                      <ul>
                        {bookingData?.ptermsAndConditions.split('\n').map((e) => (
                          <li>{e}</li>
                        ))}
                      </ul>
                    </>
                  )}
                {/* <ul>
                  <li>Capacity Ticket</li>
                  <li>Non cancellable and non refundable</li>
                  <li>
                    All baggage and personal items MUST go through the Security
                    Scanning Machine
                  </li>
                </ul> */}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default CheckoutDiscover;

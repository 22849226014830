import React, { useState } from 'react';
import { Col, Container, Row, SvgIcon, TourItemCard } from '../../components/common';
import { Button } from 'antd';
import 'react-calendar/dist/Calendar.css';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import apiClient from '../../apiConfig';
import Apis from '../../utility/apis';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { format } from 'date-fns';
import { useLoaderContext } from '../../components/common/Loader/LoaderContext';
import HeaderFilter from '../../components/common/HeaderFilter/HeaderFilter';
import { Alert } from '../../common/alert';
import NoDataFoundListing from '../../components/common/NoDataFoundListing';

const ListingPage = () => {
  const history = useHistory();

  const user = useSelector((state) => state.user.user);

  const { setShowLoader } = useLoaderContext();
  const selectedCity = useSelector((state) => state.countryCity.selectedCity);
  const selectedCurrency = useSelector((state) => state.currency.selectedCurrency);

  const [listData, setListData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);

  const fetchData = async (selectedCategory, selectedDate, priceRange) => {
    setShowLoader(true);
    setNoDataFound(false);
    try {
      const response = await apiClient.post(
        // Fetch data from API
        Apis('tourList', selectedCity.vCountryName, user ? 'loggedIn' : 'guest'),
        {
          iCountryID: selectedCity.iCountryID,
          dCurrentLat: selectedCity.vCityLatitude,
          dCurrentLong: selectedCity.vCityLongitude,
          vCityName: selectedCity.vCityName,
          iCityID: selectedCity.iCityID,
          rCategoryID: selectedCategory?.rCategoryID || '',
          gCategoryID: selectedCategory?.gCategoryID || '',
          bookingDate: selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '',
          Language: 'en',
          // start:selectedPriceRange.start,
          // limit: selectedPriceRange.limit
          // start:1,
          // limit: 1
        },
      );

      let data = response.data?.DATA || [];
      if (priceRange && priceRange.end > 0) {
        data = data.filter((item) => {
          return (
            Number(item.adultPrice) >= priceRange.start && Number(item.adultPrice) <= priceRange.end
          );
        });
      }
      if (data.length === 0) {
        setNoDataFound(true);
      }
      setListData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setShowLoader(false);
  };

  const handleFavToggle = (index, value, tourId) => {
    if (!user) {
      history.push('/login');
      return;
    }
    setListData((prevListData) => {
      return prevListData.map((item, i) => {
        if (i === index) {
          return { ...item, isFavouriteOffer: value };
        }
        return item;
      });
    });

    apiClient
      .post(Apis('toggleFavTour', selectedCity.vCountryName, user ? 'loggedIn' : 'guest'), {
        tourId,
        productId: tourId,
        favourite: value,
      })
      .then((response) => {
        if (response.data.status === 1) {
          Alert.success(response.data.MESSAGE);
        }
      });
  };

  return (
    <div className="twl-listing-wrapper">
      <section className="listing-bottom">
        <HeaderFilter
          onTriggerDataFetch={(category, date, price) => {
            fetchData(category, date, price);
          }}
        />
        <Container className="listing-container">
          {noDataFound && (
            <NoDataFoundListing
              onExploreClick={() => {
                history.replace({ search: '' });
              }}
            />
          )}
          <Row>
            <Col>
              <div className="listing-row">
                {listData &&
                  listData.length !== 0 &&
                  listData.map((item, index) => (
                    <TourItemCard
                      key={index}
                      item={item}
                      handleFavToggle={() => {
                        handleFavToggle(
                          index,
                          item.isFavouriteOffer === 0 ? 1 : 0,
                          item.tourId || item.productId,
                        );
                      }}
                    />
                  ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button
                type="primary"
                className="map-btn"
                onClick={() => {
                  history.replace({
                    pathname: '/listing-page-map',
                    search: history.location.search,
                  });
                }}
              >
                Map <SvgIcon name="map-icon" viewbox="0 0 14.855 14.695" />
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default ListingPage;

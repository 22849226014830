import SvgSprite from './utility/SvgSpriteLoader';
import { rotues } from './routes';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import history from './common/history';
import AOS from 'aos';
import ScrollToTop from './ScrollToTop';
import 'antd/dist/reset.css';
import 'aos/dist/aos.css';
import { Provider } from 'react-redux';
//Svg Sprite
import svgFile from './assets/images/svg/svg-sprite.svg';
import apiClient from './apiConfig';
import CustomLoader from './components/common/Loader/CustomLoader';
import { removeUser, setAccessToken, setLatLng, setUser } from './slice/userSlice';
import { setCurrencies, setInitialSelectedCurrency } from './slice/currencySlice';
import store from './store';
import { setCities, setInitialSelectedCity } from './slice/countryCitySlice';
import { setCategories } from './slice/categorySlice';
import { LoaderContextProvider } from './components/common/Loader/LoaderContext';
import logoImage from './assets/images/logo.svg';
import AddCityName from './AddCityName';

function App() {
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    AOS.init({});
    (async () => {
      try {
        await init();
      } catch (e) {
        console.error('Failed to initialize app', e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isInitialized) {
      if (!navigator.geolocation) {
        console.error('Geolocation is not supported by your browser');
      } else {
        const success = (position) => {
          store.dispatch(
            setLatLng({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }),
          );
        };
        const failure = (err) => {
          console.error(err.message);
        };
        navigator.geolocation.getCurrentPosition(success, failure);
      }
    }
  }, [isInitialized]);

  const init = async () => {
    console.log(':::init():::');

    const searchParams = new URLSearchParams(window.location.search);
    const { selectedCurrency } = store.getState().currency;
    const { selectedCity } = store.getState().countryCity;

    const xApiKey = JSON.parse(localStorage.getItem('xApiKey'));

    if (!xApiKey) {
      console.log('Api key not found fetching.');
      const keyResponse = await apiClient.post('/key');
      const data = keyResponse.data;
      localStorage.setItem('xApiKey', JSON.stringify(data.key));
      console.log('Api key fetched ', data.key);
    }

    if (window.location.pathname === '/appLogin') {
      const appToken = searchParams.get('appToken');
      store.dispatch(removeUser());
      try {
        if (appToken) {
          console.log('Logging in using appToken');
          const response = await apiClient.post('/admin/doLoginWithAppTokenWeb', {
            appToken: appToken,
          });
          if (response.data) {
            if (response.data.status === 1) {
              console.log('Login success using appToken');
              store.dispatch(setAccessToken(response.data.DATA.accesstoken));
              store.dispatch(setUser(response.data.DATA));
            } else {
              console.error('Unknown status:', response.data.status);
            }
          }
        }
      } catch (error) {
        console.error('Error login with appToken:', error);
      }
    }

    const { user, accessToken } = store.getState().user;

    // if appLogin failed user & accessToken will be null at this point
    if (window.location.pathname === '/appLogin' && (!user || !accessToken)) {
      window.location.replace('/');
      return;
    }

    if (accessToken && user) {
      console.log('User credentials found refreshing user data.');
      const userResponse = await apiClient.get('/user/setting');
      if (userResponse.data.status === 1) {
        store.dispatch(setUser(userResponse.data.DATA));
      }
    }

    const fetchCategory = async (city) => {
      let categoryUrl;
      if (user && city.isGlobaltix === 'Yes') {
        categoryUrl = '/globaltix/categoryList';
      } else if (!user && city.isGlobaltix === 'Yes') {
        categoryUrl = '/deal/categoryListProduct';
      } else if (user && city.isDiscover === 'Yes') {
        categoryUrl = '/discover/categoryList';
      } else {
        categoryUrl = '/deal/categoryListDiscover';
      }

      const categoryResponse = await apiClient.post(categoryUrl, {
        iCountryID: city.iCountryID,
        iCityID: city.iCityID,
      });

      if (categoryResponse.data.status === 1) {
        store.dispatch(setCategories(categoryResponse.data.DATA));
      }
    };

    const fetchCity = async () => {
      const cityListUrl = user ? '/offer/countryCityList' : '/deal/countryCityList';

      const cityResponse = await apiClient.post(cityListUrl);

      if (cityResponse.data.status === 1) {
        const countries = cityResponse.data.DATA;
        const cities = countries.flatMap((country) => country.cityList);

        if (cities.length === 0) {
          throw new Error(`Received empty city list from api response`);
        }

        store.dispatch(setCities(cities));

        if (!selectedCity) {
          store.dispatch(setInitialSelectedCity(cities[0]));
          await fetchCategory(cities[0]);
        } else {
          let cityName = searchParams.get('vCityName');
          if (cityName) {
            cityName = cityName.replace(/\s+/g, '').toLowerCase();
            const city = cities.find(
              (city) => city.vCityName.replace(/\s+/g, '').toLowerCase() === cityName,
            );
            if (city && selectedCity.iCityID !== city.iCityID) {
              store.dispatch(setInitialSelectedCity(city));
            }
          }
        }
      } else {
        throw new Error(`Api call ${cityListUrl} failed.`);
      }
    };

    const fetchCurrency = async () => {
      const currencyUrl = user ? '/discover/currencyList' : '/deal/currencyList';

      const currencyResponse = await apiClient.get(currencyUrl);

      if (currencyResponse.data.status === 1) {
        const currencies = currencyResponse.data.DATA;

        if (currencies.length === 0) {
          throw new Error(`Received empty currency list from api response`);
        }

        store.dispatch(setCurrencies(currencies));

        if (!selectedCurrency) {
          store.dispatch(setInitialSelectedCurrency(currencies[0]));
        }
      } else {
        throw new Error(`Api call ${currencyUrl} failed.`);
      }
    };

    if (selectedCity && selectedCurrency) {
      await Promise.all([fetchCity(), fetchCurrency(), fetchCategory(selectedCity)]);
    } else {
      // once city loaded it will fetch category
      await Promise.all([fetchCity(), fetchCurrency()]);
    }

    if (window.location.pathname === '/appLogin') {
      history.replace('/');
    }

    setInitialized(true);
  };

  if (!isInitialized) {
    if (window.location.pathname === '/appLogin') {
      return (
        <div className="fullscreen-loader-wrapper">
          <div className="top-section">
            <p className="title">
              Book
              <br />
              experiences
              <br />
              online
            </p>
            <p className="redirect-text">Redirecting to</p>
            <img src={logoImage} alt="Discover Your City Logo" style={{ maxWidth: 200 }} />
            <p className="sub-text">
              Powered By <b>Discover Your City</b>
            </p>
          </div>
          <div className="bottom-section">
            <div>
              <div />
            </div>
          </div>
        </div>
      );
    }
    return <CustomLoader />;
  }

  return (
    <Provider store={store}>
      <LoaderContextProvider>
        <SvgSprite url={svgFile} />
        <Router history={history} basename={process.env.REACT_APP_BASENAME || ''}>
          {rotues.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => {
                  return (
                    <route.layout {...props}>
                      <ScrollToTop>
                        <AddCityName>
                          <route.component {...props} />
                        </AddCityName>
                      </ScrollToTop>
                    </route.layout>
                  );
                }}
              />
            );
          })}
          {/* <Route
            path="*"
            component={(props) => {
              return <Redirect to="/" />;
            }}
          />*/}
        </Router>
      </LoaderContextProvider>
    </Provider>
  );
}

export default App;

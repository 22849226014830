// store.js
import { configureStore, isAnyOf } from '@reduxjs/toolkit';
import userReducer, { removeUser, setAccessToken, setLatLng, setUser } from '../slice/userSlice';
import currencyReducer, {
  setInitialSelectedCurrency,
  setSelectedCurrency,
} from '../slice/currencySlice';
import cityReducer, { setInitialSelectedCity, setSelectedCity } from '../slice/countryCitySlice';
import categoryReducer from '../slice/categorySlice';
import homeReducer from '../slice/homeSlice';

const localStorageMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);
    if (isAnyOf(setUser, removeUser)(action)) {
      if (!action.payload) {
        localStorage.removeItem('userLatLng');
        localStorage.removeItem('userData');
        localStorage.removeItem('accesstoken');
      } else {
        localStorage.setItem('userData', JSON.stringify({ DATA: getState().user.user }));
      }
    } else if (isAnyOf(setAccessToken)(action)) {
      if (action.payload) {
        localStorage.setItem('accesstoken', JSON.stringify(getState().user.accessToken));
      }
    } else if (isAnyOf(setLatLng)(action)) {
      if (action.payload) {
        localStorage.setItem('userLatLng', JSON.stringify(getState().user.latLng));
      }
    } else if (isAnyOf(setSelectedCurrency, setInitialSelectedCurrency)(action)) {
      if (action.payload) {
        localStorage.setItem(
          'selectedCurrency',
          JSON.stringify(getState().currency.selectedCurrency),
        );
        if (isAnyOf(setSelectedCurrency)(action)) {
          window.location.reload();
        }
      }
    } else if (isAnyOf(setSelectedCity, setInitialSelectedCity)(action)) {
      if (action.payload) {
        localStorage.setItem('selectedCity', JSON.stringify(getState().countryCity.selectedCity));
        if (isAnyOf(setSelectedCity)(action)) {
          window.location.href = '/';
        }
      }
    }
    return result;
  };
};
const store = configureStore({
  reducer: {
    currency: currencyReducer,
    countryCity: cityReducer,
    category: categoryReducer,
    user: userReducer,
    home: homeReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware),
});

export default store;

import { Button, Card } from 'antd';
import { SvgIcon } from '../index';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './index.scss';
import { formatCurrency } from '../../../utility/formatter';

export function TourItemCardMap(props) {
  const history = useHistory();
  const { item, handleFavToggle } = props;
  const selectedCurrency = useSelector((state) => state.currency.selectedCurrency);

  return (
    <div style={{ color: 'black' }}>
      <div style={{ position: 'relative' }}>
        <Button
          style={{
            width: '24px',
            height: '24px',
            borderRadius: '24px',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '8px',
            right: '12px',
            zIndex: '1',
          }}
          onClick={() => {
            handleFavToggle(item.isFavouriteOffer === 0 ? 1 : 0, item.tourId || item.productId);
          }}
        >
          <SvgIcon
            name="heart"
            viewbox="0 0 10.238 9.131"
            fill={item.isFavouriteOffer === 0 ? '#000' : '#FF5D5D'}
          />
        </Button>
        <div
          className="map-item-card"
          onClick={() => {
            if (item.tourId) {
              history.push(`/discover-details?tourId=${item.tourId}`);
            } else {
              history.push(`/global-details?productId=${item.productId}`);
            }
          }}
        >
          <div className="card-img">
            <img src={item.rTourImage || item.gProductImage} alt="Map" />
          </div>
          <div className="map-card-right">
            {/* <Button><SvgIcon name='heart' viewbox='0 0 10.238 9.131' /></Button> */}
            <div className="upper-col">
              <h3>{item.tourName || item.productName}</h3>
              {/*<p>*/}
              {/*  <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> {item.distance} /{' '}*/}
              {/*  {item.areaName}*/}
              {/*</p>*/}
            </div>
            <div className="bottom-col">
              From{' '}
              <span className="bottomprice">
                {selectedCurrency.uCurrency} {formatCurrency(item.adultPrice)}
              </span>{' '}
              / person{' '}
              {item.orginalPrice && +item.orginalPrice !== 0 && (
                <span className="off-price">
                  {selectedCurrency.uCurrency} {formatCurrency(item.orginalPrice)}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

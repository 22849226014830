import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../../components/common';
import { Button } from 'antd';
import Lottie from 'react-lottie';
import './index.scss';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import AnimationOne from '../../../assets/json/female-tourist-with-luggage.json';
import { useSelector } from 'react-redux';

const Bookingsuccessfully = () => {
  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const img = searchParams.get('img');
  const start = searchParams.get('start');
  const countryName = searchParams.get('countryName');
  const cityName = searchParams.get('cityName');
  const tPersons = searchParams.get('tPersons');
  const tourName = searchParams.get('tourName');
  const tServiceTotal = searchParams.get('tServiceTotal');
  const tourDate = searchParams.get('tourDate');
  const tDayRemain = searchParams.get('tDayRemain');
  const currencyData = useSelector((state) => state.currency.selectedCurrency);
  return (
    <div className="checkout-discover-wrapper">
      <div className="checkoutdiscover-inner">
        <Container>
          <Row>
            <Col>
              <div className="checkout-discover-success">
                <div className="upper-section">
                  <div className="anime-booking" data-aos="zoom-in" data-aos-duration="1000">
                    {/* <SvgIcon name='check' viewbox='0 0 10.289 9.742' /> */}
                    <Col md="12">
                      <Lottie options={animationLottie} height={200} width={280} />
                    </Col>
                  </div>
                  <h2>Confirming...</h2>
                  <p>If the booking is confirmed, we will notify you by email.</p>
                  <Link to="/">
                    <Button type="primary">Done</Button>
                  </Link>
                </div>
                <div>
                  <div className="card">
                    <div className="amount-right">
                      <p>
                        {currencyData?.uCurrency} {tServiceTotal}
                      </p>
                      <small>Paid</small>
                    </div>
                    <div className="card-upper">
                      <div className="card-image">
                        <img src={img} alt="" />
                      </div>
                      <h3>{tourName}</h3>
                      <div className="address-row">
                        <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> {cityName}, {countryName}
                      </div>
                      <div className="datetime">
                        <div className="item">
                          {/* <h4>25th Jun 22</h4> */}
                          <h4>{tourDate}</h4>
                          <label>Date</label>
                        </div>
                        <div className="item">
                          {/* <h4>01:20 PM</h4> */}
                          <h4>{start}</h4>
                          <label>Time</label>
                        </div>
                        <div className="item">
                          <h4> {tPersons} </h4>
                          <label>Person</label>
                        </div>
                      </div>
                    </div>
                    <div className="card-bottom">
                      <Button type="secondary">
                        <SvgIcon name="check" viewbox="0 0 10.289 9.742" /> Confirmed
                      </Button>
                      <div className="right-tag">
                        <p>{tDayRemain}</p>
                        <small>DAYS</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-section">
                  <p>You can access ticket from My Booking section under profile</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Bookingsuccessfully;

import React, { useEffect, useState } from 'react';
import { SvgIcon } from '../../components/common';
import { Button, Radio } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import AnimationOne from '../../assets/json/empty-offer.json';
import apiClient from '../../apiConfig';
import CustomLoader from '../../components/common/Loader/CustomLoader';
import NoDataFound from '../../components/common/NoDataFound';
import dayjs from 'dayjs';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

const MyBookingsTab = () => {
  const [value, setValue] = useState('Upcoming');

  const [data, setData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);

  // console.log((upcomingData,"upcomingDataa"));
  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };

  const showData = () => {
    //  setIsShowData(true);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 467,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      let data = await fetchDiscoverData(value);
      if (data.length !== 0) {
        data = data.sort((a, b) => {
          const dateA = new Date(`${a.tourDate} ${a.startTime}`);
          const dateB = new Date(`${b.tourDate} ${b.startTime}`);
          return dateB - dateA;
        });
      }
      setData(data);
    };

    fetchData(value);
  }, [value]);

  const fetchDiscoverData = async (status) => {
    try {
      setshowLoader(true);
      const [discoverResponse, globalResponse] = await Promise.all([
        apiClient.post('/discover/myBookingList', {
          tourStatus: status,
          bookingStatus: status,
        }),
        apiClient.post('/globaltix/myBookingList', {
          tourStatus: status,
          bookingStatus: status,
        }),
      ]);

      let data = [];
      if (discoverResponse.data.status === 1) {
        data = [...data, ...discoverResponse.data.DATA];
      }
      if (globalResponse.data.status === 1) {
        data = [...data, ...globalResponse.data.DATA];
      }
      return data;
    } catch (error) {
      console.log('Failed to fetch ticket list', error);
    } finally {
      setshowLoader(false);
    }
    return [];
  };

  return (
    <div className="right-tab-inner">
      {showLoader && <CustomLoader />}
      <>
        <h1 className="tab-heading">My Bookings</h1>
        <div className="tab-content mybookings-tab">
          <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
            <Radio value="Upcoming">Upcoming</Radio>
            <Radio value="Completed">Completed</Radio>
            <Radio value="Cancelled">Cancelled</Radio>
          </Radio.Group>

          {value === 'Upcoming' &&
            (data.length !== 0 ? (
              <div className="tab-content booking-tab" data-aos="fade-in" data-aos-duration="800">
                <div className="listing-row">
                  {data.map((e, index) => (
                    <div key={index}>
                      <Link
                        to={`/discover/booking-details?rBookingID=${e.rBookingID || e.pBookingID}&type=${e.rBookingID ? 'discover' : 'global'}`}
                      >
                        <div className="card">
                          <div className="amount-right">
                            <p>
                              {e.uCurrency} {e.tServiceTotal}
                            </p>
                            <small>Paid</small>
                          </div>
                          <div className="card-upper">
                            <div className="card-image">
                              <img src={e.rTourImage || e.gProductImage} alt="" />
                            </div>
                            <h3>{e.tourName || e.productName}</h3>
                            <div className="address-row">
                              <SvgIcon name="map" viewbox="0 0 8.358 12.537" />{' '}
                              {e.cityName || e.vCityName}, {e.countryName || e.vCountryName}
                            </div>
                            <div className="datetime">
                              <div className="item">
                                <h4>{e.tourDate || e.visitDate}</h4>
                                <label>Date</label>
                              </div>
                              <div className="item">
                                <h4>{e.startTime || e.visitTime}</h4>
                                <label>Time</label>
                              </div>
                              <div className="item">
                                <h4>{e.tPersons}</h4>
                                <label>Person</label>
                              </div>
                            </div>
                          </div>
                          <div className="card-bottom">
                            <Button type="secondary">
                              <SvgIcon name="check" viewbox="0 0 10.289 9.742" /> Confirmed
                            </Button>
                            {Number(e.tDayRemain || e.pDayRemain) > 0 && (
                              <div className="right-tag">
                                <p>{e.tDayRemain || e.pDayRemain}</p>
                                <small>DAYS</small>
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                  {/* <div>
                                    <Link to={`/discover/booking-details?rBookingID=${e.rBookingID}`'}>
                                        <div className='card'>
                                            <div className='amount-right'>
                                                <p>AED 340</p>
                                                <small>Paid</small>
                                            </div>
                                            <div className='card-upper'>
                                                <div className='card-image'>
                                                    <img src={BookingImg2} alt='' />
                                                </div>
                                                <h3>Discover Tower Bridge</h3>
                                                <div className='address-row'><SvgIcon name="map" viewbox="0 0 8.358 12.537" /> London, United Kingdom</div>
                                                <div className='datetime'>
                                                    <div className='item'>
                                                        <h4>25th Jun 22</h4>
                                                        <label>Date</label>
                                                    </div>
                                                    <div className='item'>
                                                        <h4>01:20 PM</h4>
                                                        <label>Time</label>
                                                    </div>
                                                    <div className='item'>
                                                        <h4>02</h4>
                                                        <label>Person</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-bottom'>
                                                <Button type='secondary'><SvgIcon name="check" viewbox="0 0 10.289 9.742" /> Confirmed</Button>
                                                <div className='right-tag'>
                                                    <p>05</p>
                                                    <small>DAYS</small>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div>
                                    <div className='card'>
                                        <div className='amount-right'>
                                            <p>AED 340</p>
                                            <small>Paid</small>
                                        </div>
                                        <div className='card-upper'>
                                            <div className='card-image'>
                                                <img src={BookingImg3} alt='' />
                                            </div>
                                            <h3>Discover Tower Bridge</h3>
                                            <div className='address-row'><SvgIcon name="map" viewbox="0 0 8.358 12.537" /> London, United Kingdom</div>
                                            <div className='datetime'>
                                                <div className='item'>
                                                    <h4>25th Jun 22</h4>
                                                    <label>Date</label>
                                                </div>
                                                <div className='item'>
                                                    <h4>01:20 PM</h4>
                                                    <label>Time</label>
                                                </div>
                                                <div className='item'>
                                                    <h4>02</h4>
                                                    <label>Person</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-bottom'>
                                            <Button type='secondary'><SvgIcon name="check" viewbox="0 0 10.289 9.742" /> Confirmed</Button>
                                            <div className='right-tag'>
                                                <p>05</p>
                                                <small>DAYS</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='card'>
                                        <div className='amount-right'>
                                            <p>AED 340</p>
                                            <small>Paid</small>
                                        </div>
                                        <div className='card-upper'>
                                            <div className='card-image'>
                                                <img src={BookingImg4} alt='' />
                                            </div>
                                            <h3>Discover Tower Bridge</h3>
                                            <div className='address-row'><SvgIcon name="map" viewbox="0 0 8.358 12.537" /> London, United Kingdom</div>
                                            <div className='datetime'>
                                                <div className='item'>
                                                    <h4>25th Jun 22</h4>
                                                    <label>Date</label>
                                                </div>
                                                <div className='item'>
                                                    <h4>01:20 PM</h4>
                                                    <label>Time</label>
                                                </div>
                                                <div className='item'>
                                                    <h4>02</h4>
                                                    <label>Person</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-bottom'>
                                            <Button type='secondary'><SvgIcon name="check" viewbox="0 0 10.289 9.742" /> Confirmed</Button>
                                            <div className='right-tag'>
                                                <p>05</p>
                                                <small>DAYS</small>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                </div>
              </div>
            ) : (
              <NoDataFound type="booking" />
            ))}

          {value === 'Completed' &&
            (data.length !== 0 ? (
              <div className="tab-content booking-tab" data-aos="fade-in" data-aos-duration="800">
                <div className="listing-row">
                  {data.map((e, index) => (
                    <div key={index}>
                      <Link
                        to={`/discover/booking-details?rBookingID=${e.rBookingID || e.pBookingID}&type=${e.rBookingID ? 'discover' : 'global'}`}
                      >
                        <div className="card">
                          <div className="amount-right">
                            <p>
                              {e.uCurrency} {e.tServiceTotal}
                            </p>
                            <small>Paid</small>
                          </div>
                          <div className="card-upper">
                            <div className="card-image">
                              <img src={e.rTourImage || e.gProductImage} alt="" />
                            </div>
                            <h3>{e.tourName || e.productName}</h3>
                            <div className="address-row">
                              <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> {e.cityName},{' '}
                              {e.countryName}
                            </div>
                            <div className="datetime">
                              <div className="item">
                                <h4>{e.tourDate || e.visitDate}</h4>
                                <label>Date</label>
                              </div>
                              <div className="item">
                                <h4>{e.startTime || e.visitTime}</h4>
                                <label>Time</label>
                              </div>
                              <div className="item">
                                <h4>{e.tPersons}</h4>
                                <label>Person</label>
                              </div>
                            </div>
                          </div>
                          <div className="card-bottom">
                            <Button type="secondary">
                              <SvgIcon name="check" viewbox="0 0 10.289 9.742" /> Confirmed
                            </Button>
                            {Number(e.tDayRemain || e.pDayRemain) > 0 && (
                              <div className="right-tag">
                                <p>{e.tDayRemain || e.pDayRemain}</p>
                                <small>DAYS</small>
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <NoDataFound type="booking" />
            ))}
          {value === 'Cancelled' &&
            (data.length !== 0 ? (
              <div className="tab-content booking-tab" data-aos="fade-in" data-aos-duration="800">
                <div className="listing-row">
                  {data.map((e, index) => (
                    <div key={index}>
                      <Link
                        to={`/discover/booking-details?rBookingID=${e.rBookingID || e.pBookingID}&type=${e.rBookingID ? 'discover' : 'global'}`}
                      >
                        <div className="card">
                          <div className="amount-right">
                            <p>
                              {e.uCurrency} {e.tServiceTotal}
                            </p>
                            <small>Paid</small>
                          </div>
                          <div className="card-upper">
                            <div className="card-image">
                              <img src={e.rTourImage || e.gProductImage} alt="" />
                            </div>
                            <h3>{e.tourName || e.productName}</h3>
                            <div className="address-row">
                              <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> {e.cityName},{' '}
                              {e.countryName}
                            </div>
                            <div className="datetime">
                              <div className="item">
                                <h4>{e.tourDate || e.visitDate}</h4>
                                <label>Date</label>
                              </div>
                              <div className="item">
                                <h4>{e.startTime || e.visitTime}</h4>
                                <label>Time</label>
                              </div>
                              <div className="item">
                                <h4>{e.tPersons}</h4>
                                <label>Person</label>
                              </div>
                            </div>
                          </div>
                          <div className="card-bottom">
                            <Button type="secondary">
                              <SvgIcon name="check" viewbox="0 0 10.289 9.742" /> Confirmed
                            </Button>
                            {Number(e.tDayRemain || e.pDayRemain) > 0 && (
                              <div className="right-tag">
                                <p>{e.tDayRemain || e.pDayRemain}</p>
                                <small>DAYS</small>
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              /*  <Slider className="savedcard-slider" {...settings}>
                                                          {data.map((e) => (
                                                            <div>
                                                              <Link
                                                                to={`/discover/booking-details?rBookingID=${e.rBookingID || e.pBookingID}&type=${e.rBookingID ? 'discover' : 'global'}`}
                                                              >
                                                                <div className="card">
                                                                  <div className="amount-right">
                                                                    <p>
                                                                      {e.uCurrency} {e.tServiceTotal}
                                                                    </p>
                                                                    <small>Paid</small>
                                                                  </div>
                                                                  <div className="card-upper">
                                                                    <div className="card-image">
                                                                      <img src={e.rTourImage || e.gProductImage} alt="" />
                                                                    </div>
                                                                    <h3>{e.tourName || e.productName}</h3>
                                                                    <div className="address-row">
                                                                      <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> {e.cityName},{' '}
                                                                      {e.countryName}
                                                                    </div>
                                                                    <div className="datetime">
                                                                      <div className="item">
                                                                        <h4>{e.tourDate || e.visitDate}</h4>
                                                                        <label>Date</label>
                                                                      </div>
                                                                      <div className="item">
                                                                        <h4>{e.startTime || e.visitTime}</h4>
                                                                        <label>Time</label>
                                                                      </div>
                                                                      <div className="item">
                                                                        <h4>{e.tPersons}</h4>
                                                                        <label>Person</label>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="card-bottom">
                                                                    <Button type="secondary">
                                                                      <SvgIcon name="check" viewbox="0 0 10.289 9.742" /> Confirmed
                                                                    </Button>
                                                                    {Number(e.tDayRemain || e.pDayRemain) > 0 && (
                                                                      <div className="right-tag">
                                                                        <p>{e.tDayRemain || e.pDayRemain}</p>
                                                                        <small>DAYS</small>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </Link>
                                                            </div>
                                                          ))}
                                                        </Slider>*/
              <NoDataFound type="booking" />
            ))}
        </div>
      </>
    </div>
  );
};

export default MyBookingsTab;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Col,
  Container,
  DownloadSection,
  Row,
  SvgIcon,
  TourItemCard,
} from '../../components/common';
import { Button, Card, Drawer, Modal } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Sliders from 'react-slick';
import 'react-calendar/dist/Calendar.css';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import BlogImg2 from '../../assets/images/blog-2.jpg';
import BlogImg3 from '../../assets/images/blog-3.jpg';
import BlogImg4 from '../../assets/images/blog-4.jpg';
import howitworkImg from '../../assets/images/howitwork.png';
import apiClient from '../../apiConfig';
import Apis from '../../utility/apis';
import { getHomePageCategoryData, getHomePageData, toggleFav } from '../../slice/homeSlice';
import HeaderFilter from '../../components/common/HeaderFilter/HeaderFilter';
import { useLoaderContext } from '../../components/common/Loader/LoaderContext';
import { Alert } from '../../common/alert';
import { debounce } from 'lodash';
import ModalVideo from 'react-modal-video';
import HowItWorks from '../../assets/video/how_it_works.mp4';
import downloadImg from '../../assets/images/download.png';
import { logEvent } from '../../utility/analytics';
import { LoadState } from '../../constants';
import { ThreeDots } from 'react-loader-spinner';
import { PromoPopup } from '../../components/common/PromoPopup';
import UnexpectedError from '../../components/common/UnexpectedError';
import DotLoader from '../../assets/json/dot_loader.json';
import Lottie from 'react-lottie';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function NextArrowClients(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrowClients(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

const LandingPage = () => {
  const history = useHistory();
  const { setShowLoader } = useLoaderContext();
  const userData = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const selectedCity = useSelector((state) => state.countryCity.selectedCity);
  const { headerImage, groupBanner, categoryTopTen, state, state2 } = useSelector(
    (state) => state.home,
  );
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const videoRef = useRef();
  const sectionRef = useRef({});
  const [showDynamicPopup, setShowDynamicPopup] = useState(null);

  const scrollToSection = useCallback(
    debounce((sectionId) => {
      if (sectionRef.current[sectionId]) {
        sectionRef.current[sectionId].scrollIntoView({ behavior: 'smooth' });
      }
    }, 100),
    [],
  );

  const handleFavToggle = (index, innerIndex, value, tourId) => {
    if (!userData) {
      history.push('/login');
      return;
    }
    dispatch(toggleFav({ index, innerIndex, value }));
    apiClient
      .post(Apis('toggleFavTour', selectedCity.vCountryName, userData ? 'loggedIn' : 'guest'), {
        tourId,
        productId: tourId,
        favourite: value,
      })
      .then((response) => {
        if (response.data.status === 1) {
          Alert.success(response.data.MESSAGE);
        }
      });
  };

  useEffect(() => {
    if (selectedCity) {
      if (state === LoadState.IDLE) {
        dispatch(getHomePageData());

        if (selectedCity.isGlobaltix === 'Yes') {
          logEvent('global_homepage', {
            city_id: selectedCity.iCityID,
            city_name: selectedCity.vCityName,
          });
        } else {
          logEvent('discover_homepage', {
            city_id: selectedCity.iCityID,
            city_name: selectedCity.vCityName,
          });
        }
      }
      if (state === LoadState.SUCCESS && state2 === LoadState.IDLE) {
        dispatch(getHomePageCategoryData());
      }
    }
  }, [state, state2, selectedCity]);

  useEffect(() => {
    if (state === LoadState.IDLE || state === LoadState.LOADING) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [state, setShowLoader]);

  const Blogdata = [
    {
      title: 'The Ultimate Children’s Playground',
      avatar: BlogImg2,
      date: 'NOV 29 - 5 MIN READ',
    },
    {
      title: 'Time to head to The Dubai Mall',
      avatar: BlogImg3,
      date: 'DEC 02 - 9 MIN READ',
    },
    {
      title: 'Time for something new',
      avatar: BlogImg4,
      date: 'JAN 08 - 10 MIN READ',
    },
  ];

  const settingsClients = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrowClients />,
    prevArrow: <PrevArrowClients />,
  };

  const settingsHandpickedCollections = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: 10,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const settingsTourSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: 10,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    if (headerImage.length > 0 && headerImage[0].city_webimagevideo.includes('.mp4')) {
      videoRef.current.autoplay = true;
      videoRef.current.loop = true;
      videoRef.current.defaultMuted = true;
      videoRef.current.muted = true;
      videoRef.current.setAttribute('playsinline', 'true');
    }
  }, [headerImage]);

  useEffect(() => {
    const shownOnce = JSON.parse(sessionStorage.getItem('homePopupShown')) === 'true';
    if (state === LoadState.SUCCESS && !shownOnce) {
      sessionStorage.setItem('homePopupShown', JSON.stringify(String(true)));

      (async () => {
        try {
          const popupUrl = userData ? '/user/dynamicPopUp' : '/deal/dynamicPopUp';

          const popupResponse = await apiClient.post(popupUrl, {
            iCountryID: selectedCity.iCountryID,
          });
          if (popupResponse.data.status === 1) {
            const promoPopup = popupResponse.data.DATA.find((item) => item.tPromoType === 'Promo');
            if (promoPopup) {
              setShowDynamicPopup(promoPopup);
            } else {
              setShowDownloadPopup(true);
            }
          }
        } catch (e) {
          console.error('Failed fetch dynamic popups', e);
        }
      })();
    }
  }, [state, isMobile]);

  if (state === LoadState.ERROR || state2 === LoadState.ERROR) {
    return (
      <Modal
        open={true}
        onCancel={() => {}}
        closeIcon={null}
        footer={false}
        centered
        maskClosable={false}
        mask={true}
        zIndex={1100}
        wrapClassName="promo-popup"
      >
        <UnexpectedError
          onRefreshClick={() => {
            window.location.reload();
          }}
        />
      </Modal>
    );
  }

  return (
    <>
      {state !== LoadState.SUCCESS && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
          }}
        />
      )}
      <div className="twl-landing-wrapper">
        {headerImage.length > 0 && (
          <section className="landing-banner">
            <div
              className="background-image"
              style={{
                backgroundImage:
                  !headerImage[0].city_webimagevideo.includes('.mp4') &&
                  `url(${headerImage[0].city_webimagevideo})`,
              }}
            >
              {headerImage[0].city_webimagevideo.includes('.mp4') && (
                <video ref={videoRef} style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                  <source src={`${headerImage[0].city_webimagevideo}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>

            <Container>
              <HeaderFilter />
              <div className="banner-row">
                <h1 data-aos="fade-right" data-aos-duration="900">
                  Get ready for <br /> global adventures
                </h1>
              </div>
            </Container>
          </section>
        )}
        {groupBanner.length !== 0 && (
          <section
            className="handpickscollection-section"
            ref={(el) => {
              sectionRef.current['handpicked'] = el;
            }}
          >
            <Container>
              <Row>
                <Col>
                  <h2 className="heading" data-aos="fade-in" data-aos-duration="1000">
                    Something <br /> for you
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Sliders
                    className="handpicks-collection-slider"
                    {...settingsHandpickedCollections}
                  >
                    {/* <div>
                  <Card
                    cover={<img alt="example" src={somethingImg1} />}
                    data-aos="fade-in"
                    data-aos-duration="1000"
                  >
                    <div className="meta-row">
                      <div className="meta-left">
                        <h2>
                          Super 10 <br /> Attractions
                        </h2>
                      </div>
                      <Button
                        onClick={() =>
                          window.open("/group-listing-page", "_self")
                        }
                      >
                        {" "}
                        Explore{" "}
                        <SvgIcon name="arrow-right" viewbox="0 0 14 9" />
                      </Button>
                    </div>
                  </Card>
                </div>
                <div>
                  <Card
                    cover={<img alt="example" src={somethingImg2} />}
                    data-aos="fade-in"
                    data-aos-duration="1000"
                  >
                    <div className="meta-row">
                      <div className="meta-left">
                        <h2>
                          Trending <br /> places
                        </h2>
                      </div>
                      <Button
                        onClick={() =>
                          window.open("/group-listing-page", "_self")
                        }
                      >
                        {" "}
                        Explore{" "}
                        <SvgIcon name="arrow-right" viewbox="0 0 14 9" />
                      </Button>
                    </div>
                  </Card>
                </div> */}
                    {groupBanner.map((e, i) => (
                      <div key={i}>
                        <Card
                          cover={<img alt="example" src={e.discoverBannerImage} />}
                          data-aos="fade-in"
                          data-aos-duration="1000"
                        >
                          <div className="meta-row">
                            <div className="meta-left">
                              <h2>{e.rgroup_title}</h2>
                            </div>
                            <Button
                              onClick={() =>
                                history.push(`/group-listing-page?groupId=${e.rgroup_id}`)
                              }
                            >
                              Explore <SvgIcon name="arrow-right" viewbox="0 0 14 9" />
                            </Button>
                          </div>
                        </Card>
                      </div>
                    ))}
                  </Sliders>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {/* <section className="tour-section">
        <Container>
          <Row className="align-items-center mb-2">
            <Col>
              <h1>City Tour</h1>
            </Col>
            <Col className="text-right">
              <Link to="/listing-page">
                <Button className="more-btn" size="small">
                  More
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Sliders className="tour-slider" {...settingsTourSlider}>
                {TourData.map((item) => (
                  <div key={item.key}>
                    <Card
                      className="tp-item-card"
                      cover={<img alt="TP List" src={item.image} />}
                      extra={
                        <Button>
                          <SvgIcon name="heart" viewbox="0 0 10.238 9.131" />
                        </Button>
                      }
                      onClick={() => window.open("/global-details", "_self")}
                    >
                      <div className="bottom-row">
                        <div className="left-col">
                          <h3>{item.name}</h3>
                          <div className="price-col">
                            From <span className="bottomprice">AED 340</span> /
                            person <span className="off-price">AED 523</span>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </Sliders>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="tour-section">
        <Container>
          <Row className="align-items-center mb-2">
            <Col>
              <h1>City Tour</h1>
            </Col>
            <Col className="text-right">
              <Link to="/listing-page">
                <Button className="more-btn" size="small">
                  More
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Sliders className="tour-slider" {...settingsTourSlider}>
                {TourData.map((item) => (
                  <div key={item.key}>
                    <Card
                      className="tp-item-card"
                      cover={<img alt="TP List" src={item.image} />}
                      extra={
                        <Button>
                          <SvgIcon name="heart" viewbox="0 0 10.238 9.131" />
                        </Button>
                      }
                      onClick={() => window.open("/global-details", "_self")}
                    >
                      <div className="bottom-row">
                        <div className="left-col">
                          <h3>{item.name}</h3>
                          <div className="price-col">
                            From <span className="bottomprice">AED 340</span> /
                            person <span className="off-price">AED 523</span>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </Sliders>
            </Col>
          </Row>
        </Container>
      </section> */}

        {state !== LoadState.LOADING && state2 === LoadState.LOADING && (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: DotLoader,
              renderer: 'svg',
            }}
            height={160}
            width={160}
          />
        )}
        {categoryTopTen.map(
          (e, i) =>
            e.topTenDeals &&
            e.topTenDeals.length !== 0 && (
              <section
                className="tour-section"
                key={i}
                ref={(el) => {
                  sectionRef.current[`tour_${i}`] = el;
                }}
              >
                <Container>
                  <Row className="align-items-center mb-2">
                    <Col>
                      <h1>{e.rCategoryName || e.gCategoryName}</h1>
                    </Col>
                    <Col className="text-right">
                      <Link to={`/listing-page?categoryId=${e.rCategoryID || e.gCategoryID}`}>
                        <Button className="more-btn" size="small">
                          More
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Sliders className="tour-slider" {...settingsTourSlider}>
                        {e.topTenDeals.map((item, index) => (
                          <TourItemCard
                            key={index}
                            item={item}
                            handleFavToggle={() => {
                              handleFavToggle(
                                i,
                                index,
                                item.isFavouriteOffer === 0 ? 1 : 0,
                                item.tourId || item.productId,
                              );
                            }}
                          />
                        ))}
                      </Sliders>
                    </Col>
                  </Row>
                </Container>
              </section>
            ),
        )}

        <section className="howitwork-section">
          <Container>
            <Row className="align-items-center">
              <Col md="6" className="howworkimg-left">
                <label data-aos="fade-right" data-aos-duration="700">
                  HOW IT WORKS
                </label>
                <h2 data-aos="fade-right" data-aos-duration="800">
                  Experience cities across the globe like never before with Discover Your City
                </h2>
                <div className="social-links" data-aos="fade-right" data-aos-duration="900">
                  <Button
                    onClick={() =>
                      window.open(
                        'https://www.instagram.com/discoveryourcity.io/',
                        '_blank',
                        'noopener noreferrer',
                      )
                    }
                  >
                    <SvgIcon name="instagram" viewbox="0 0 17.137 17.137" />
                  </Button>
                  <Button
                    onClick={() =>
                      window.open(
                        'https://www.facebook.com/discoveryourcity.io/',
                        '_blank',
                        'noopener noreferrer',
                      )
                    }
                  >
                    <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                  </Button>
                  {/* <Button>
                  <SvgIcon name="linkedin" viewbox="0 0 16.165 16.137" />
                </Button>*/}
                  <Button
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/@discoveryourcityio',
                        '_blank',
                        'noopener noreferrer',
                      )
                    }
                  >
                    <SvgIcon name="youtube" viewbox="0 0 11 9" />
                  </Button>
                  <Button
                    onClick={() =>
                      window.open(
                        'https://www.tiktok.com/@discoveryourcity.io',
                        '_blank',
                        'noopener noreferrer',
                      )
                    }
                  >
                    <SvgIcon name="tiktok" viewbox="0 0 11 13" />
                  </Button>
                  {/* <Button>
                  <SvgIcon name="medium" viewbox="0 0 17.135 14.163" />
                </Button>*/}
                </div>
              </Col>
              <Col md="6">
                <div className="howworkimg-card" data-aos="fade-left" data-aos-duration="800">
                  <Button onClick={() => setShowHowItWorks(true)}>
                    <SvgIcon name="play-alt" viewbox="0 0 30.001 30" /> Watch
                  </Button>
                  <img src={howitworkImg} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/*     <section className="blog-section">
        <Container>
          <Row>
            <Col>
              <div className="blog-upper">
                <Row>
                  <Col lg="6" data-aos="fade-right" data-aos-duration="800">
                    <img className="blog-img" src={BlogImg1} alt="Blog" />
                  </Col>
                  <Col lg="6" data-aos="fade-left" data-aos-duration="800">
                    <label>Blogs</label>
                    <h3>Your perfect plan for an unforgettable holidays</h3>
                    <p>
                      People of the UAE, Eid Holidays are approaching. So, planning the ultimate Eid
                      weekend is what is on most of our minds as we all want to make the most out of
                      this Eid weekend.
                    </p>
                    <Button
                      type="primary"
                      className="with-icon"
                      icon={<SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                    >
                      Read More
                    </Button>
                    <div className="bottom-text">Nov 29 - 3 min read</div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <List
                data-aos="fade-up"
                data-aos-duration="800"
                itemLayout="horizontal"
                dataSource={Blogdata}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button>
                        {' '}
                        <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />{' '}
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<img src={item.avatar} alt="" />}
                      title={item.title}
                      description={item.date}
                    />
                  </List.Item>
                )}
              />
              <div className="more-blog">
                <ul>
                  <li>
                    <img src={Tpimg1} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg2} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg3} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg4} alt="Blog More" />
                  </li>
                </ul>
                <Button type="link">+ 23 Blogs</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>*/}
        <section className="letsjoin-section">
          <Container>
            <Row>
              <Col>
                <div className="letsjoin-row" data-aos="zoom-in" data-aos-duration="1200">
                  <div className="letsjoin-card">
                    <h3>
                      Thrilling <br /> Experiences
                    </h3>
                    <SvgIcon name="bus" viewbox="0 0 56 34" />
                    <p className="mb-0 upto-text">
                      <small>Up to</small>
                    </p>
                    <h2>30% OFF</h2>
                    <p>On experiences</p>
                    <Button
                      type="secondary"
                      size="medilum"
                      onClick={() => {
                        const key = Object.keys(sectionRef.current)[0];
                        if (key) {
                          scrollToSection(key);
                        }
                      }}
                    >
                      Book Now
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="testomonial-section">
        <Container>
          <ParallaxProvider>
            <Row>
              <Col sm="12" className="mb-4">
                <Parallax disabled={isMobile}>
                  <h1 className="heading mb-0">What they say</h1>
                  <p className="heading-p-tag">*App Store Reviews</p>
                </Parallax>
              </Col>
              <Col sm="12">
                <Sliders className="testomonial-slider" {...settingsClients}>
                  <div>
                    <div className="testomonial-row">
                      <Parallax speed={10} disabled={isMobile}>
                        <img className="testomonial-image" src={testimonialImg} alt="Testimonial" />
                      </Parallax>
                      <div className="right-text">
                        <Parallax speed={14} disabled={isMobile}>
                          <p>
                            I just wanted to share a quick note and let you know that you guys do a
                            really good job. I’m glad I decided to work with you. It’s really great
                            how easy your websites are to update and manage.
                          </p>
                        </Parallax>
                        <Parallax speed={10} disabled={isMobile}>
                          <h3>Amelia Kimani</h3>
                        </Parallax>
                        <Parallax speed={9} disabled={isMobile}>
                          <hr />
                        </Parallax>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="testomonial-row">
                      <Parallax speed={10} disabled={isMobile}>
                        <img
                          className="testomonial-image"
                          src={testimonialImg2}
                          alt="Testimonial"
                        />
                      </Parallax>
                      <div className="right-text">
                        <Parallax speed={14} disabled={isMobile}>
                          <p>
                            Worth it! <br />
                            Highly recommend this app over most available in the market today. I
                            have been using it for 3 years now and am super happy with my savings.
                            The new interface of the app makes it easy to navigate and find the best
                            options close to you!
                          </p>
                        </Parallax>
                        <Parallax speed={10} disabled={isMobile}>
                          <h3>Karthik</h3>
                        </Parallax>
                        <Parallax speed={9} disabled={isMobile}>
                          <hr />
                        </Parallax>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="testomonial-row">
                      <Parallax speed={10} disabled={isMobile}>
                        <img
                          className="testomonial-image"
                          src={testimonialImg3}
                          alt="Testimonial"
                        />
                      </Parallax>
                      <div className="right-text">
                        <Parallax speed={14} disabled={isMobile}>
                          <p>
                            Excellent customer service <br />
                            Had an issue with one of the restaurants while using TravellerPass.
                            TravellerPass resolved this through their persistent follow-up with the
                            restaurant. The restaurant called and even offered a free dish.
                            Five-star customer service indeed from TravellerPass.
                          </p>
                        </Parallax>
                        <Parallax speed={10} disabled={isMobile}>
                          <h3>Marvina</h3>
                        </Parallax>
                        <Parallax speed={9} disabled={isMobile}>
                          <hr />
                        </Parallax>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="testomonial-row">
                      <Parallax speed={10} disabled={isMobile}>
                        <img
                          className="testomonial-image"
                          src={testimonialImg4}
                          alt="Testimonial"
                        />
                      </Parallax>
                      <div className="right-text">
                        <Parallax speed={14} disabled={isMobile}>
                          <p>
                            Great Experience <br />
                            Have been using this app over last year and a half; amazing experience,
                            covers all the major outlet in Dubai with amazing deals in all. Strongly
                            recommend everyone to download the app and open up the great offers….
                          </p>
                        </Parallax>
                        <Parallax speed={10} disabled={isMobile}>
                          <h3>Jamie</h3>
                        </Parallax>
                        <Parallax speed={9} disabled={isMobile}>
                          <hr />
                        </Parallax>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="testomonial-row">
                      <Parallax speed={10} disabled={isMobile}>
                        <img
                          className="testomonial-image"
                          src={testimonialImg5}
                          alt="Testimonial"
                        />
                      </Parallax>
                      <div className="right-text">
                        <Parallax speed={14} disabled={isMobile}>
                          <p>
                            Awesome! <br />
                            It’s easy to use and very handy. It helps a lot to save more. Worth
                            every penny😍
                          </p>
                        </Parallax>
                        <Parallax speed={10} disabled={isMobile}>
                          <h3>Nikol</h3>
                        </Parallax>
                        <Parallax speed={9} disabled={isMobile}>
                          <hr />
                        </Parallax>
                      </div>
                    </div>
                  </div>
                </Sliders>
              </Col>
            </Row>
          </ParallaxProvider>
        </Container>
      </section>*/}
        <DownloadSection />
      </div>
      {/* <ModalVideo isOpen={true} channel="youtube" videoId="nRHdJy6idow" />*/}
      <ModalVideo
        isOpen={showHowItWorks}
        channel="custom"
        url={HowItWorks}
        onClose={() => setShowHowItWorks(false)}
      />
      <MediaQuery maxWidth={767}>
        <Drawer
          height="auto"
          placement="bottom"
          open={showDownloadPopup}
          onClose={() => setShowDownloadPopup(false)}
          title={null}
          footer={null}
          closeIcon={null}
          rootClassName="mobile-popup"
        >
          <img className="popup-image" src={downloadImg} />

          <Button
            className="play-btn"
            onClick={() => {
              setShowHowItWorks(true);
              setShowDownloadPopup(false);
            }}
            size="small"
          >
            <SvgIcon name="play-alt" viewbox="0 0 30.001 30" /> Play the Video
          </Button>
          <div className="popup-text">
            <span>Get the app today</span>
            <span>and let the adventure unfold!</span>
          </div>

          <div className="download-btns">
            <Button
              type="text"
              onClick={() =>
                window.open(
                  'https://apps.apple.com/ae/app/discover-your-city/id6473774812',
                  '_blank',
                  'noopener noreferrer',
                )
              }
            >
              <SvgIcon name="apple" viewbox="0 0 22.982 28.234" /> App Store
            </Button>
            <Button
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.traveller.discover&hl=en_IN&gl=US',
                  '_blank',
                  'noopener noreferrer',
                )
              }
            >
              <SvgIcon name="googleplay" viewbox="0 0 22.416 24.786" /> Google Play
            </Button>
            {/*<Button
            onClick={() =>
              window.open(
                'https://appgallery.huawei.com/app/C109741589',
                '_blank',
                'noopener noreferrer',
              )
            }
          >
            <SvgIcon name="app-gallery" viewbox="0 0 22.132 21.799" /> App Gallery
          </Button>*/}
          </div>
        </Drawer>
      </MediaQuery>
      {showDynamicPopup && (
        <PromoPopup data={showDynamicPopup} setShowDynamicPopup={setShowDynamicPopup} />
      )}
    </>
  );
};

export default LandingPage;

import React from 'react';
import { Col, Container, DownloadSection, Row, SvgIcon } from '../../components/common';
import { Link } from 'react-router-dom';
import './index.scss';

const TermsandConditions = () => {
  return (
    <div className="twl-terms-wrapper">
      {/*  <section className="terms-banner">
        <Container>
          <Row className="banner-row">
            <Col md="7" lg="8">
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Terms and <br /> Conditions
                  </h1>
                  <p data-aos="fade-right" data-aos-duration="1000">
                    Last Updated: October 1, 2021
                  </p>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link to="/privacy-policy">
                    Privacy Policy{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="5" lg="4">
              <div className="brand-mark" data-aos="fade-left" data-aos-duration="1000">
                <img src={LogoIcon} alt={LogoIcon} />
                <p>TravellerPass</p>
                <h1>Brand Mark</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>*/}
      <section className="terms-banner" style={{ minHeight: 'unset' }}>
        <Container>
          <Row className="banner-row">
            <Col>
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Terms and Conditions
                  </h1>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link to={window.location.pathname === '/terms' ? 'privacy' : '/privacy-policy'}>
                    Privacy Policy{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="terms-section">
        <Container>
          <Row>
            <Col>
              <div className="content-row">
                <h2>Overview</h2>
                <p>
                  This service is operated by TravellerPass LLC. Throughout the site, the terms
                  “we”, “us” and “our” refer to TravellerPass LLC. TravellerPass LLC offers this
                  website, including all information, tools, and services available from this site
                  to you, the user, conditioned upon your acceptance of all terms, conditions,
                  policies and notices stated here. <br />
                  By visiting our site and/ or purchasing something from us, you engage in our
                  “Service” and agree to be bound by the following terms and conditions (“Terms of
                  Service”, “Terms”), including those additional terms and conditions and policies
                  referenced herein and/or available by the hyperlink. These Terms of Service apply
                  to all users of the site, including without limitation users who are browsers,
                  vendors, customers, merchants, and/ or contributors of content. <br />
                  Please read these Terms of Service carefully before accessing or using our
                  website. By accessing or using any part of the site, you agree to be bound by
                  these Terms of Service. If you do not agree to all the terms and conditions of
                  this agreement, then you may not access the website or use any services. If these
                  Terms of Service are considered an offer, acceptance is expressly limited to these
                  Terms of Service. <br />
                  Any new features or tools which are added to the current store shall also be
                  subject to the Terms of Service. You can review the most current version of the
                  Terms of Service at any time on this page. We reserve the right to update, change
                  or replace any part of these Terms of Service by posting updates and/or changes to
                  our website. It is your responsibility to check this page periodically for
                  changes. Your continued use of or access to the website following the posting of
                  any changes constitutes acceptance of those changes.
                </p>
              </div>
              <div className="content-row">
                <h2>Online Store Terms & Membership</h2>
                <p>
                  By agreeing to these Terms of Service, you represent that you are at least the age
                  of majority in your country of residence, or that you are the age of majority in
                  your country of residence, and you have given us your consent to allow any of your
                  minor dependents to use this site. <br />
                  You may not use our products for any illegal or unauthorized purpose nor may you,
                  in the use of the Service, violate any laws in your jurisdiction (including but
                  not limited to copyright laws). <br />
                  TravellerPass LLC will send you email campaigns to keep you aware of the latest
                  offers. <br />
                  You must not transmit any worms or viruses or any code of a destructive nature.{' '}
                  <br />
                  A breach or violation of any of the Terms will result in an immediate termination
                  of your Services. <br />
                  TravellerPass LLC maintains the{' '}
                  <a href="http://www.discoveryourcity.io">http://www.discoveryourcity.io</a>{' '}
                  Website ("Site") <br />
                  We will not trade with or provide any services to OFAC and sanctioned countries{' '}
                  <br />
                  Customers using the website who are Minor /under the age of 18 shall not register
                  as a User of the website and shall not transact on or use the website <br />
                  Cardholders must retain a copy of transaction records and Merchant policies and
                  rules <br />
                  The user is responsible for maintaining the confidentiality of his account <br />
                </p>
              </div>
              <div className="content-row">
                <h3>Booking Section App & Web</h3>
                <ul>
                  <li>
                    Payment: When booking activities across multiple cities and segments, you may be
                    required to pay in advance. Payment methods may include credit cards, debit
                    cards, or online payment systems. The payment terms and conditions may vary
                    depending on the activity, the venue, and the location.
                  </li>

                  <li>
                    Cancellation and Refunds: If you need to cancel your booking, you may be subject
                    to cancellation fees or penalties. Refunds may be available depending on the
                    terms and conditions of the activity provider. It's important to read the
                    cancellation and refund policies carefully before booking an activity.
                  </li>

                  <li>
                    Rescheduling: If you need to reschedule your booking, you may be subject to
                    rescheduling fees or penalties. The availability of rescheduling may vary
                    depending on the activity, the venue, and the location.
                  </li>

                  <li>
                    Age and Health Restrictions: Some activities may have age or health
                    restrictions. It's important to read the terms and conditions carefully to
                    ensure that you meet the requirements for the activity you are booking.
                  </li>

                  <li>
                    Liability: Activities may involve a certain level of risk, and you may be
                    required to sign a waiver or release of liability before participating in the
                    activity. The terms and conditions may outline the extent of the activity
                    provider's liability and your responsibility in case of injury or damage.
                  </li>

                  <li>
                    Other Terms and Conditions: There may be other terms and conditions that apply
                    to booking activities across multiple cities and segments. It's important to
                    read the terms and conditions carefully to ensure that you understand your
                    rights and obligations when booking and participating in activities.
                  </li>
                </ul>
              </div>
              <div className="content-row">
                <h3>General Conditions</h3>
                <p>
                  We reserve the right to refuse service to anyone for any reason at any time.
                  <br />
                  You understand that your content (not including credit card information), may be
                  transferred unencrypted and involve (a) transmissions over various networks; and
                  (b) changes to conform and adapt to the technical requirements of connecting
                  networks or devices. Credit card information is always encrypted during transfer
                  over networks.
                  <br />
                  You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion
                  of the Service, use of the Service, or access to the Service or any contact on the
                  website through which the service is provided, without express written permission
                  by us.
                  <br />
                  The headings used in this agreement are included for convenience only and will not
                  limit or otherwise affect these Terms.
                </p>
              </div>
              <div className="content-row">
                <h2>
                  Accuracy, Completeness And
                  <br />
                  Timeliness Of Information
                </h2>
                <p>
                  We are not responsible if the information made available on this site is not
                  accurate, complete, or current. The material on this site is provided for general
                  information only and should not be relied upon or used as the sole basis for
                  making decisions without consulting primary, more accurate, more complete, or more
                  timely sources of information. Any reliance on the material on this site is at
                  your own risk.
                  <br />
                  <br />
                  This site may contain certain historical information. Historical information,
                  necessarily, is not current and is provided for your reference only. We reserve
                  the right to modify the contents of this site at any time, but we have no
                  obligation to update any information on our site. You agree that it is your
                  responsibility to monitor changes to our site.
                </p>
              </div>
              <div className="content-row">
                <h2>Modifications To The Service And Prices</h2>
                <p>
                  Prices for our products are subject to change without notice.
                  <br />
                  We reserve the right at any time to modify or discontinue the Service (or any part
                  or content thereof) without notice at any time.
                  <br />
                  We shall not be liable to you or to any third party for any modification, price
                  change, suspension, or discontinuance of the Service.
                </p>
              </div>
              <div className="content-row">
                <h2>Accuracy Of Billing And Account Information</h2>
                <p>
                  We reserve the right to refuse any order you place with us. We may, in our sole
                  discretion, limit or cancel memberships purchased per person. These restrictions
                  may include orders placed by or under the same customer account, the same credit
                  card, and/or orders that use the same billing and/or shipping address. If we make
                  a change to or cancel an order, we may attempt to notify you by contacting the
                  e-mail and/or billing address/phone number provided at the time the order was
                  made. We reserve the right to limit or prohibit orders that, in our sole judgment,
                  appear to be placed by dealers, resellers or distributors. You agree to provide
                  current, complete, and accurate purchase and account information for all purchases
                  made at our store. You agree to promptly update your account and other
                  information, including your email address and credit card numbers and expiration
                  dates, so that we can complete your transactions and contact you as needed. For
                  more detail, please review our Returns Policy.
                </p>
              </div>
              <div className="content-row">
                <h2>Optional Tools</h2>
                <p>
                  We may provide you with access to third-party tools over which we neither monitor
                  nor have any control nor input. You acknowledge and agree that we provide access
                  to such tools” as is” and “as available” without any warranties, representations,
                  or conditions of any kind and without any endorsement. We shall have no liability
                  whatsoever arising from or relating to your use of optional third-party tools. Any
                  use by you of optional tools offered through the site is entirely at your own risk
                  and discretion and you should ensure that you are familiar with and approve of the
                  terms on which tools are provided by the relevant third-party provider(s). We may
                  also, in the future, offer new services and/or features through the website
                  (including, the release of new tools and resources). Such new features and/or
                  services shall also be subject to these Terms of Service.
                </p>
              </div>
              <div className="content-row">
                <h2>Third-Party Links</h2>
                <p>
                  Certain content, products, and services available via our Service may include
                  materials from third parties.
                  <br />
                  Third-party links on this site may direct you to third-party websites that are not
                  affiliated with us. We are not responsible for examining or evaluating the content
                  or accuracy and we do not warrant and will not have any liability or
                  responsibility for any third-party materials or websites, or for any other
                  materials, products, or services of third parties.
                  <br />
                  We are not liable for any harm or damages related to the purchase or use of goods,
                  services, resources, content, or any other transactions made in connection with
                  any third-party websites. Please review carefully the third-party's policies and
                  practices and make sure you understand them before you engage in any transaction.
                  Complaints, claims, concerns, or questions regarding third-party products should
                  be directed to the third-party.
                </p>
              </div>
              <div className="content-row">
                <h2>
                  User Comments, Feedback <br />
                  And Other Submissions
                </h2>
                <p>
                  If at our request, you send certain specific submissions (for example contest
                  entries) or without a request from us you send creative ideas, suggestions,
                  proposals, plans, or other materials, whether online, by email, by postal mail, or
                  otherwise (collectively, 'comments'), you agree that we may, at any time, without
                  restriction, edit, copy, publish, distribute, translate and otherwise use in any
                  medium any comments that you forward to us. We are and shall be under no
                  obligation (1) to maintain any comments in confidence; (2) to pay compensation for
                  any comments; or (3) to respond to any comments. We may, but have no obligation
                  to, monitor, edit or remove content that we determine in our sole discretion are
                  unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene, or
                  otherwise objectionable or violates any party’s intellectual property or these
                  Terms of Service. You agree that your comments will not violate any right of any
                  third-party, including copyright, trademark, privacy, personality or other
                  personal or proprietary rights. You further agree that your comments will not
                  contain libellous or otherwise unlawful, abusive, or obscene material, or contain
                  any computer virus or other malware that could in any way affect the operation of
                  the Service or any related website. You may not use a false e-mail address,
                  pretend to be someone other than yourself, or otherwise mislead us or third
                  parties as to the origin of any comments. You are solely responsible for any
                  comments you make and their accuracy. We take no responsibility and assume no
                  liability for any comments posted by you or any third-party.
                </p>
              </div>
              <div className="content-row">
                <h2>Personal Information</h2>
                <p>
                  Your submission of personal information through the store is governed by our
                  Privacy Policy.
                </p>
              </div>
              <div className="content-row">
                <h2>Errors, Inaccuracies And Omissions</h2>
                <p>
                  Occasionally there may be information on our site or in the Service that contains
                  typographical errors, inaccuracies or omissions that may relate to product
                  descriptions, pricing, promotions, offers, product shipping charges, transit times
                  and availability. We reserve the right to correct any errors, inaccuracies, or
                  omissions, and to change or update information or cancel orders if any information
                  in the Service or on any related website is inaccurate at any time without prior
                  notice (including after you have submitted your order). We try our best to ensure
                  there are no service disruptions during the operation of our app but at times due
                  to server availability or network connectivity or other reasons, there could be an
                  unavailability of service. We will not give any refunds during this period of
                  disruption but may allow the user an extension in the membership period. We
                  undertake no obligation to update, amend or clarify information in the Service or
                  on any related website, including without limitation, pricing information, except
                  as required by law. No specified update or refresh date applied in the Service or
                  on any related website should be taken to indicate that all information in the
                  Service or on any related website has been modified or updated.
                </p>
              </div>
              <div className="content-row">
                <h2>Prohibited Uses</h2>
                <p>
                  In addition to other prohibitions as set forth in the Terms of Service, you are
                  prohibited from using the site or its content: (a) for any unlawful purpose; (b)
                  to solicit others to perform or participate in any unlawful acts; (c) to violate
                  any international, country, provincial or city regulations, rules, laws, or local
                  ordinances; (d) to infringe upon or violate our intellectual property rights or
                  the intellectual property rights of others; (e) to harass, abuse, insult, harm,
                  defame, slander, disparage, intimidate, or discriminate based on gender, sexual
                  orientation, religion, ethnicity, race, age, national origin, or disability; (f)
                  to submit false or misleading information; (g) to upload or transmit viruses or
                  any other type of malicious code that will or may be used in any way that will
                  affect the functionality or operation of the Service or of any related website,
                  other websites, or the Internet; (h) to collect or track the personal information
                  of others; (i) to spam, phish, pretext, spider, crawl, or scrape; (j) for any
                  obscene or immoral purpose; or (k) to interfere with or circumvent the security
                  features of the Service or any related website, other websites, or the Internet.
                  We reserve the right to terminate your use of the Service or any related website
                  for violating any of the prohibited uses.
                </p>
              </div>
              <div className="content-row">
                <h2>
                  Disclaimer Of Warranties; <br />
                  Limitation Of Liability
                </h2>
                <p>
                  We do not guarantee, represent, or warrant that your use of our service will be
                  uninterrupted, timely, secure, or error-free. We do not warrant that the results
                  that may be obtained from the use of the service will be accurate or reliable. You
                  agree that from time to time we may remove the service for indefinite periods of
                  time or cancel the service at any time, without notice to you. You expressly agree
                  that your use of, or inability to use, the service is at your sole risk. The
                  service and all products and services delivered to you through the service are
                  (except as expressly stated by us) provided 'as is' and 'as available' for your
                  use, without any representation, warranties, or conditions of any kind, either
                  express or implied, including all implied warranties or conditions of
                  merchantability, merchantable quality, fitness for a particular purpose,
                  durability, title, and non-infringement. In no case shall TravellerPass LLC, our
                  directors, officers, employees, affiliates, agents, contractors, interns,
                  suppliers, service providers or licensors be liable for any injury, loss, claim,
                  or any direct, indirect, incidental, punitive, special, or consequential damages
                  of any kind, including, without limitation lost profits, lost revenue, lost
                  savings, loss of data, replacement costs, or any similar damages, whether based in
                  contract, tort (including negligence), strict liability or otherwise, arising from
                  your use of any of the service or any products procured using the service, or for
                  any other claim related in any way to your use of the service or any product,
                  including, but not limited to, any errors or omissions in any content, or any loss
                  or damage of any kind incurred as a result of the use of the service or any
                  content (or product) posted, transmitted, or otherwise made available via the
                  service, even if advised of their possibility.
                </p>
              </div>
              <div className="content-row">
                <h2>Indemnification</h2>
                <p>
                  You agree to indemnify, defend and hold harmless TravellerPass LLC and our parent,
                  subsidiaries, affiliates, partners, officers, directors, agents, contractors,
                  licensors, service providers, subcontractors, suppliers, interns and employees,
                  harmless from any claim or demand, including reasonable attorneys’ fees, made by
                  any third party due to or arising out of your breach of these Terms of Service or
                  the documents they incorporate by reference or your violation of any law or the
                  rights of a third-party.
                </p>
              </div>
              <div className="content-row">
                <h2>Severability</h2>
                <p>
                  If any provision of these Terms of Service is determined to be unlawful, void or
                  unenforceable, such provision shall nonetheless be enforceable to the fullest
                  extent permitted by applicable law, and the unenforceable portion shall be deemed
                  to be severed from these Terms of Service, such determination shall not affect the
                  validity and enforceability of any other remaining provisions.
                </p>
              </div>
              <div className="content-row">
                <h2>Termination</h2>
                <p>
                  The obligations and liabilities of the parties incurred prior to the termination
                  date shall survive the termination of this agreement for all purposes. These Terms
                  of Service are effective unless and until terminated by either you or us. You may
                  terminate these Terms of Service at any time by notifying us that you no longer
                  wish to use our Services, or when you cease using our site. If in our sole
                  judgment you fail, or we suspect that you have failed, to comply with any term or
                  provision of these Terms of Service, we also may terminate this agreement at any
                  time without notice and you will remain liable for all amounts due up to and
                  including the date of termination; and/or accordingly may deny you access to our
                  Services (or any part thereof).
                </p>
              </div>
              <div className="content-row">
                <h2>Entire Agreement</h2>
                <p>
                  The failure of us to exercise or enforce any right or provision of these Terms of
                  Service shall not constitute a waiver of such right or provision. These Terms of
                  Service and any policies or operating rules posted by us on this site or in
                  respect to The Service constitutes the entire agreement and understanding between
                  you and us and govern your use of the Service, superseding any prior or
                  contemporaneous agreements, communications, and proposals, whether oral or
                  written, between you and us (including, but not limited to, any prior versions of
                  the Terms of Service). Any ambiguities in the interpretation of these Terms of
                  Service shall not be construed against the drafting party.
                </p>
              </div>
              <div className="content-row">
                <h2>Governing Law</h2>
                <p>
                  These Terms of Service and any separate agreements whereby we provide you Services
                  shall be governed by and construed in accordance with the laws of the United Arab
                  Emirates.
                </p>
              </div>
              <div className="content-row">
                <h2>Changes To Terms Of Service</h2>
                <p>
                  You can review the most current version of the Terms of Service at any time at
                  this page. We reserve the right, at our sole discretion, to update, change or
                  replace any part of these Terms of Service by posting updates and changes to our
                  website. It is your responsibility to check our website periodically for changes.
                  Your continued use of or access to our website or the Service following the
                  posting of any changes to these Terms of Service constitutes acceptance of those
                  changes.
                </p>
              </div>
              <div className="content-row">
                <h2>Payment Confirmation</h2>
                <p>
                  Once the payment for subscriptions is made, the confirmation notice will be sent
                  to the client via email within 24 hours of receipt of payment.
                </p>
              </div>
              <div className="content-row">
                <h2>Refund Policy</h2>
                <p>
                  In case the offer or booking was not redeemed/paid successfully, we will
                  investigate the issue and process a refund, or a free offer will be extended as
                  compensation if the said issue is caused by an error in our systems. For Food
                  Delivery when offered, if the order was not delivered due to the merchant or order
                  was cancelled or due to an issue with our systems, a refund will be extended to
                  the customer. Refunds are typically completed in 2 - 7 days and made to the
                  original mode of payment.
                </p>
              </div>
              <div className="content-row">
                <h2>Cancellation Policy</h2>
                <p>
                  Refunds are typically completed in 2 - 7 days from the date of processing and made
                  to the original mode of payment.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <DownloadSection />
    </div>
  );
};

export default TermsandConditions;

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCurrency } from '../../../slice/currencySlice';
import './index.scss';
import classNames from 'classnames';

const CurrenciesDropDown = (props) => {
  const { darkIconAndText } = props;
  const dispatch = useDispatch();
  const { selectedCurrency, currencies } = useSelector((state) => state.currency);

  const [currencyDropOpen, setCurrencyDropOpen] = useState(false);
  const [tmpCurrencyList, setTmpCurrencyList] = useState([]);

  const init = () => {
    const list = currencies.map((currency) => {
      if (selectedCurrency && selectedCurrency.uCurrencyID === currency.uCurrencyID) {
        return { ...currency, isSelected: true };
      } else {
        return { ...currency, isSelected: false };
      }
    });
    setTmpCurrencyList(list);
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies, selectedCurrency]);

  const handleCurrencySelect = (currencyId) => {
    const list = currencies.map((currency) => {
      if (currency.uCurrencyID === currencyId) {
        return { ...currency, isSelected: true };
      } else {
        return { ...currency, isSelected: false };
      }
    });
    setTmpCurrencyList(list);
  };

  const handleCurrencyChoose = () => {
    const currency = tmpCurrencyList.find((currency) => currency.isSelected);
    if (currency) {
      dispatch(setSelectedCurrency(currency));
    }
    setCurrencyDropOpen(false);
  };

  const handleReset = () => {
    if (tmpCurrencyList.length > 0) {
      dispatch(setSelectedCurrency(tmpCurrencyList[0]));
    }
    setCurrencyDropOpen(false);
  };

  const dropDownItems = useMemo(() => {
    return tmpCurrencyList.map((currency, index) => {
      return {
        key: index,
        label: (
          <div
            className={classNames('currency-item', { selected: currency.isSelected })}
            onClick={() => handleCurrencySelect(currency.uCurrencyID)}
          >
            <div className="left-col">
              <div className="cur-icon">
                <img src={currency.vCurrencyImage} alt={currency.uCurrencyName} />
              </div>
              <span className="falg-img" />
              {currency.uCurrency}
            </div>
            <div className="right-col">
              <span>{currency.uCurrencyName}</span> - {currency.uCurrencySymbol}
            </div>
          </div>
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpCurrencyList]);

  return (
    <Dropdown
      menu={{
        items: dropDownItems,
      }}
      trigger={['click']}
      open={currencyDropOpen}
      onOpenChange={(nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
          setCurrencyDropOpen(nextOpen);
        }
      }}
      placement="bottom"
      overlayClassName="currencyheader-drop"
      dropdownRender={(menu) => (
        <div>
          <h3>Choose currency</h3>
          {menu}
          <div className="drop-footer">
            <Button type="text" onClick={handleReset}>
              Reset all
            </Button>
            <Button type="primary" onClick={handleCurrencyChoose}>
              Choose
            </Button>
          </div>
        </div>
      )}
    >
      <div
        className={`currency-col ${darkIconAndText ? 'navigation-text-color-black' : 'navigation-text-color-white'}`}
        onClick={(e) => e.preventDefault()}
      >
        <img
          className="falg-img"
          src={selectedCurrency.vCurrencyImage}
          alt={selectedCurrency.uCurrencyName}
        />
        {selectedCurrency ? selectedCurrency.uCurrency : ''}
      </div>
    </Dropdown>
  );
};

export default CurrenciesDropDown;

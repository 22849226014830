import React from 'react';
import { Col, Container, DownloadSection, Row, SvgIcon } from '../../components/common';
import { Link } from 'react-router-dom';
import './index.scss';

import LogoIcon from '../../assets/images/logo-icon.svg';

const PrivacyPolicy = () => {
  return (
    <div className="twl-terms-wrapper">
      {/*   <section className="terms-banner">
        <Container>
          <Row className="banner-row">
            <Col md="7" lg="8">
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Privacy <br /> Policy
                  </h1>
                  <p data-aos="fade-right" data-aos-duration="1000">
                    Last Updated: October 1, 2021
                  </p>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link to="/terms-conditions">
                    Terms and Conditions{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="5" lg="4">
              <div className="brand-mark" data-aos="fade-left" data-aos-duration="1000">
                <img src={LogoIcon} alt={LogoIcon} />
                <p>TravellerPass</p>
                <h1>Brand Mark</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>*/}
      <section className="terms-banner" style={{ minHeight: 'unset' }}>
        <Container>
          <Row className="banner-row">
            <Col>
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Privacy Policy
                  </h1>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link
                    to={window.location.pathname === '/privacy' ? '/terms' : '/terms-conditions'}
                  >
                    Terms and Conditions{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="terms-section">
        <Container>
          <Row>
            <Col>
              <div className="content-row">
                <h2>Overview</h2>
                <p>
                  This Privacy Policy (“Policy”) explains in general terms how TravellerPass LLC and
                  its subsidiaries, related bodies corporate and franchisees, protects the privacy
                  of your personal information. We are firmly committed to protecting the privacy
                  and confidentiality of personal information and maintain various physical,
                  electronic and procedural safeguards to protect personal information in our care.
                  By providing personal information to us (or allowing another person to do so on
                  your behalf), you agree that this Policy will apply to how we handle your personal
                  information and you consent to us collecting, using and disclosing your personal
                  information as detailed in this Policy. If you do not agree with any part of this
                  Policy, you must not provide your personal information to us and this may affect
                  the services we can provide to you. In this Policy, where we say “disclose”, this
                  includes to transfer, share, send, or otherwise make available or accessible your
                  personal information to another person or entity.
                </p>
              </div>
              <div className="content-row">
                <h2>What is Personal Information?</h2>
                <p>
                  Personal information is information which relates to a living individual who can
                  be identified from that information, or from that information and other
                  information in a person’s possession, including any expression of opinion, whether
                  true or not, and whether recorded in a material form or not, about an identified
                  or reasonably identified individual and any indication of intention in respect of
                  an individual.
                </p>
              </div>
              <div className="content-row">
                <h2>How Do We Collect Personal Information?</h2>
                <p>
                  Where practical, we will collect personal information directly from you.
                  Generally, this collection will occur when you deal with us either in person, by
                  telephone, letter, facsimile, email or when you visit our website/app. We may
                  collect personal information about you when you purchase or make inquiry about
                  travel arrangements or other products and services, when you enter competitions,
                  when you register for promotions, when you subscribe to receive marketing from us
                  (e.g. e-newsletters) or when you request brochures or other information from us.
                  We may also collect your personal information when you complete surveys or provide
                  us with feedback. In some circumstances, it may be necessary for us to collect
                  personal information about you from a third party. <br />
                  This includes where a person makes a travel booking on your behalf which includes
                  travel arrangements to be used by you (e.g. a family or group booking). Where this
                  occurs, we will assume you have consented to your personal information being
                  collected by us and to us handling it in accordance with this Policy. You should
                  let us know immediately if you become aware that your personal information has
                  been provided to us by another person without your consent.
                </p>
              </div>
              <div className="content-row">
                <h2>What Personal Information Do We Collect?</h2>
                <p>
                  Generally, the type of personal information we collect about you is the
                  information that is needed to facilitate your travel arrangements and bookings and
                  to arrange travel-related services and products on your behalf. When you make
                  contact with us for other purposes, we may also collect personal information about
                  you in relation to those purposes. For example, we may collect your personal
                  information so we can contact you about a competition you have entered (e.g. if
                  you win) or to respond to an inquiry or feedback form you have sent to us. We also
                  collect information that is required for use in the business activities of
                  TravellerPass LLC and our related entities, including for example, financial
                  details necessary in order to process various transactions, and any other relevant
                  personal information you may elect to provide to us. In some circumstances, we may
                  collect personal information from you which is regarded as sensitive information.
                  Sensitive information includes types of personal information such as location,
                  your racial or ethnic origin, religious beliefs or affiliations, criminal record
                  and health information. We will only collect sensitive information about you with
                  your consent and where reasonably necessary for, or directly related to, one or
                  more of our functions or activities (e.g. to make travel arrangements on your
                  behalf). Where you provide sensitive information to us, you consent to us using
                  that sensitive information for the purpose for which it was collected. For
                  example, if you provide health information to us in connection with a travel
                  insurance application you would like to make, you consent to us using and
                  disclosing that health information in connection with arranging that travel
                  insurance on your behalf.
                  <br />
                  <br />
                  Some additional information is below:
                  <br />
                  <br />
                  We use your location to list out nearby offers and deals as well as location data
                  to send you nearby deal alerts even when the app is not in use, so that you never
                  miss out on an offer. This can be controlled in the notification settings section
                  of the app.
                  <br />
                  <br />
                  All credit/debit cards details will NOT be stored, sold, shared, rented or leased
                  to any third parties
                  <br />
                  <br />
                  All personally identifiable information is used in relation to rendering our
                  services and will NOT be sold, shared, rented or leased to any third parties
                  <br />
                  <br />
                  http://www.discoveryourcity.io/ will not pass any debit/credit card details to
                  third parties
                  <br />
                  <br />
                  http://www.discoveryourcity.io/ takes appropriate steps to ensure data privacy and
                  security including through various hardware and software methodologies. However,
                  (http://www.discoveryourcity.io/) cannot guarantee the security of any information
                  that is disclosed online
                  <br />
                  <br />
                  For data deletion, click on the option in the main menu of the app or make a
                  request to contact@dyc.global
                </p>
              </div>
              <div className="content-row">
                <h2>Online Store Terms & Conditions</h2>
                <p>
                  By agreeing to this agreement and using our services, you represent that you are
                  at least the age of majority in your country of residence, or that you are the age
                  of majority in your country of residence and you have given us your consent to
                  allow any of your minor dependents to use this site.
                  <br />
                  You may not use our products for any illegal or unauthorized purpose nor may you,
                  in the use of the Service, violate any laws in your jurisdiction (including but
                  not limited to copyright laws).
                  <br />
                  <br />
                  TravellerPass LLC will send you email campaigns to keep you aware of the latest
                  offers. <br />
                  <br />
                  You must not transmit any worms or viruses or any code of a destructive nature.
                  <br />
                  <br />A breach or violation of any of the Terms will result in an immediate
                  termination of your Services.
                  <br />
                  <br /> TravellerPass LLC maintains the www.discoveryourcity.io Website ("Site")
                  <br />
                  <br /> We will not trade with or provide any services to OFAC and sanctioned
                  countries
                  <br />
                  <br /> Customers using the website who are Minor /under the age of 18 shall not
                  register as a User of the website and shall not transact on or use the website
                  <br />
                  <br /> Cardholder must retain a copy of transaction records and Merchant policies
                  and rules
                  <br />
                  <br /> The user is responsible for maintaining the confidentiality of his account
                </p>
              </div>
              <div className="content-row">
                <h2>Booking related for app and web</h2>
                <p>
                  Collection of Personal Information: When booking activities across multiple cities
                  and segments, you may be required to provide personal information such as your
                  name, email address, phone number, and payment information. The activity provider
                  may also collect information about your preferences, interests, and location.
                  <br />
                  <br /> Use of Personal Information: The personal information collected may be used
                  to process your booking, communicate with you about the activity, and provide you
                  with personalized recommendations and offers. The information may also be used for
                  marketing and research purposes.
                  <br />
                  <br /> Sharing of Personal Information: The activity provider may share your
                  personal information with third-party service providers such as payment
                  processors, booking platforms, and marketing agencies. The provider may also share
                  your information with its affiliates or partners for marketing purposes.
                  <br />
                  <br /> Security of Personal Information: The activity provider should have
                  measures in place to protect your personal information from unauthorized access,
                  use, or disclosure. The provider should also have a policy for data retention and
                  disposal.
                  <br />
                  <br /> User Rights: You may have the right to access, correct, or delete your
                  personal information. You may also have the right to object to the use of your
                  personal information for certain purposes. The activity provider should provide
                  you with a way to exercise your user rights.
                  <br />
                  <br /> Compliance with Laws: The activity provider should comply with applicable
                  UAE laws and regulations related to the collection, use, and sharing of personal
                  information.
                </p>
              </div>
              <div className="content-row">
                <h2>When We Act As Agent</h2>
                <p>
                  When we book products and services for you, we usually do so as agent for or on
                  behalf of travel service providers. This means that we usually collect personal
                  information about you both for our internal purposes (e.g. to process your
                  booking), and on behalf of the parties for whom we act as agent, for their
                  internal purposes (e.g. to provide the booked services). Accordingly, the consent
                  you provide under this Policy to the collection and use of personal information by
                  us applies equally to the parties whose products and services we sell. For
                  example, if you purchase a flight from us, then under this Policy you will have
                  consented to your personal information being provided to the airline to enable
                  your flight to be booked. We act as agent for or on behalf of many thousands of
                  travel service providers, so it is not possible for us to set out in this Policy
                  all of the travel service providers for whom we act nor exactly how each of these
                  travel service providers will use your personal information. If you wish to
                  contact us for further information, please refer to the “Feedback / Complaints /
                  Contact” section below.
                </p>
              </div>
              <div className="content-row">
                <h2>How Do We Use Personal Information?</h2>
                <p>
                  Where you contact us in relation to a travel booking or query, the primary purpose
                  for which we collect your personal information is generally to provide you with
                  travel advice and/or to assist you with booking travel and/or travel-related
                  products and services. However, the purpose of collection may differ depending on
                  the particular circumstances as disclosed in this Policy.
                </p>
                <p>
                  By providing us, or otherwise allowing us to collect, your personal information,
                  you consent to us using and disclosing your personal information for the purposes
                  for which it was collected, and for related or ancillary purposes, such as any one
                  or more of the following purposes:
                </p>
                <ul>
                  <li>identification of fraud or error;</li>
                  <li>regulatory reporting and compliance;</li>
                  <li>
                    developing, improving, and marketing our products and services and those of our
                    related entities;
                  </li>
                  <li>
                    servicing our relationship with you by, among other things, providing updates on
                    promotions and services we think may interest you;
                  </li>
                  <li>
                    involving you in market research, gauging customer satisfaction, and seeking
                    feedback regarding our relationship with you;
                  </li>
                  <li>to facilitate your participation in loyalty programs;</li>
                  <li>to analyze trends in sales and travel destinations;</li>
                  <li>
                    for marketing activities including but not limited to mail-outs, emails,
                    e-newsletters, SMS notifications, and telephone calls;
                  </li>
                  <li>internal accounting and administration; and</li>
                  <li>
                    other purposes as permitted or required by law (e.g. to prevent a threat to
                    life, health, or safety).
                  </li>
                </ul>
                <p>
                  We will only send e-newsletters or other promotional materials to you when signing
                  into the app, the opt-out option is provided in the emails we sent if this does
                  not interest you. You can subscribe to receive e-newsletters and other promotional
                  materials by following the relevant links on our website. We are only allowed to
                  store your personal information for as long as necessary having regard to the
                  purpose for which it was collected or a related or ancillary purpose. We may,
                  therefore, delete your personal information after a reasonable period of time and,
                  if you have not used our services for some time, you may have to re-enter or
                  re-supply your personal information to us.
                </p>
              </div>
              <div className="content-row">
                <h2>Is Personal Information Disclosed To Third Parties?</h2>
                <p>
                  Yes, you also consent to the personal information about you that we collect or
                  receive being processed, transferred, and/or disclosed by us in the following
                  circumstances:
                </p>
                <ul>
                  <li>
                    in each of the circumstances set out in the previous section “How do we use
                    personal information?”;
                  </li>
                  <li> as permitted or required by law;</li>
                  <li>
                    to various regulatory bodies and law enforcement officials and agencies,
                    including to protect against fraud and for related security purposes;
                  </li>
                  <li>
                    to third-party suppliers of IT-based solutions that assist us in providing
                    products and services to you;
                  </li>
                  <li> to our related entities and brands (including companies based overseas);</li>
                  <li>
                    to travel service providers such as travel wholesalers, tour operators,
                    airlines, hotels, car rental companies, and other service providers for the
                    purpose for
                  </li>
                  <li>
                    which the information was collected or for a related purpose (for example to
                    facilitate and process your travel arrangements);
                  </li>
                  <li>
                    to comply with our legal obligations and any applicable customs/immigration
                    requirements relating to your travel;
                  </li>
                  <li>
                    to comply with a request from a qualified government department if we are
                    satisfied that they have the authority to make such a request;
                  </li>
                  <li>
                    where we suspect that unlawful activity has been or may be engaged in and the
                    personal information is a necessary part of our investigation or reporting of
                    the matter;
                  </li>
                  <li>
                    to third parties who may involve you in market research for the purpose of
                    servicing our relationship with you and improving the services we provide; and
                  </li>
                  <li>
                    to third parties for the purpose of analyzing trends in sales and travel
                    destinations.
                  </li>
                </ul>
                <p>
                  The third parties to whom we disclose your personal information may also be taken
                  to have collected your personal information in their own right, for their internal
                  use. For more information in relation to the collection by travel service
                  providers, see the heading above titled “When we act as agent”. Where we engage
                  travel service providers to perform services for us, those travel service
                  providers may be required to handle your personal information. Where we disclose
                  your personal information to such travel service providers, you agree that we will
                  not be required to ensure their compliance with applicable privacy laws or
                  otherwise be liable or accountable for how they handle your personal information.
                  Other than the above, we will not disclose your personal information without your
                  consent unless we reasonably believe that disclosure is necessary to lessen or
                  prevent a threat to life, health, or safety or for a certain action to be
                  undertaken by an enforcement body (e.g. prevention, detection, investigation,
                  prosecution or punishment of criminal offenses), disclosure is authorised or
                  required by law or disclosure is otherwise permitted by applicable privacy laws.
                  We may also disclose your personal information to our overseas related entities
                  and to travel service providers and other third parties who perform services for
                  us overseas. Generally, we will only disclose your personal information to these
                  overseas recipients in connection with the facilitation of your travel booking
                  and/or to enable the performance of administrative and technical services by them
                  on our behalf. We deal with many different service providers all over the world,
                  so it is not possible for us to set out in this Policy all of the different
                  countries to which we may send your personal information. However, if you have any
                  specific questions about where or to whom your personal information will be sent,
                  please refer to the “Feedback / Complaints / Contact” section below. OPT-OUT
                  Should you no longer wish to receive e-newsletters or other promotional material,
                  participate in market research or receive other types of communication from us,
                  please refer to the “Contact/Feedback” section below.
                </p>
              </div>
              <div className="content-row">
                <h2>Tracking Technologies/Cookies</h2>
                <p>
                  A cookie is a small text file that is stored on a user’s computer for
                  record-keeping purposes. We may link the information we store in cookies to any
                  personal information you submit while on our website. Cookies save data about
                  individual visitors to the website, such as the visitor's name, password,
                  username, screen preferences, the pages of a site viewed by the visitor, and the
                  advertisements viewed or clicked by the visitor. At TravellerPass LLC we use
                  cookies to identify a visitor’s browser, to anonymously track visits or to enhance
                  the experience of the website. We use both session ID cookies and persistent
                  cookies.
                </p>
                <p>
                  A session ID cookie expires when you close your browser. A persistent cookie
                  remains on your hard drive for an extended period of time. When the visitor
                  revisits the website, we may recognize the visitor by the cookie and customise the
                  visitor's experience accordingly. You can remove persistent cookies by following
                  the directions provided in your Internet browser’s “help” file. If you reject
                  cookies, you may still have access to our website, but your ability to use some
                  areas of our website, such as contests or surveys, will be limited. Google
                  Analytics When you visit certain sections of the website, Google Analytics, a
                  website analytics service provided by Google, Inc (“Google”), will place cookies
                  on your computer’s browser. The information generated by the cookies about your
                  use of the website is transmitted to Google. This information is then used for the
                  purpose of compiling statistical reports to enable us to measure and analyse the
                  number of visits to the site and its pages, the average time spent on the site and
                  the pages viewed. We will not (and will not allow any third party to) use Google
                  Analytics to track or to collect any personal data of visitors to our website. We
                  will not associate any data gathered via the use of cookies with any personal data
                  from any source as part of our use of Google Analytics.
                </p>
                <p>
                  For further information about Google Analytics please visit
                  www.google.com/analytics and for details of Google Analytics Privacy Policy,
                  please visit http://www.google.com/intl/en)ALL/privacypolicy.html. You may choose
                  to opt out of Google Analytics using the Google Analytics Opt-out Browser Add-on
                  which you may access at https://tools.google.com/dlpage/gaoptout/. Web Beacons /
                  Gifs We employ a software technology called clear gifs (a.k.a. Web Beacons), that
                  help us better manage content on our website by informing us what content is
                  effective. Clear gifs are tiny graphics with a unique identifier, similar in
                  function to cookies, and are used to track the online movements of Web users. In
                  contrast to cookies, which are stored on a user’s computer hard drive, clear gifs
                  are embedded invisibly on Web pages and are about the size of the period at the
                  end of this sentence. We do not tie the information gathered by clear gifs to our
                  customer’s personally identifiable information. 3rd Party Tracking The use of
                  cookies by our partners, affiliates, tracking utility company or service providers
                  is not covered by our Privacy Policy. We do not have access to or control over
                  these cookies. Our Third-Party partners employ clear gifs (a.k.a. Web Beacons/Web
                  Bugs), images, and scripts that help them better manage content on our website. We
                  do not tie the information gathered to our customers or users’ personally
                  identifiable information. Behavioural Targeting / Re-Targeting We partner with
                  Third Party ad networks to either display advertising on Our Site or to manage Our
                  advertising on other sites. Our ad network partners use cookies, Web beacons, and
                  other technologies to collect non-personally identifiable information about your
                  activities on this and other websites to provide you with targeted advertising
                  based on your interests. If you wish to not have this information used for the
                  purpose of serving your targeted ads, you may opt out by clicking Here and Here.
                  Please note this does not opt you out of being served to advertise. You will
                  continue to receive generic ads or targeted ads where third parties do not honour
                  opt-out capabilities.
                </p>
              </div>
              <div className="content-row">
                <h2>Linked Sites</h2>
                <p>
                  Our website may contain links to third-party sites over which we have no control.
                  We are not responsible for the privacy practices or the content of such websites.
                  We encourage you to read the privacy policies of any linked sites you visit as
                  their privacy policy and practices may differ from ours.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <DownloadSection />
    </div>
  );
};

export default PrivacyPolicy;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from 'firebase/auth';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCB1Y1x1NnWpLpB1Qj_fpadM7hhdVKaruQ',
  authDomain: 'auth.discoveryourcity.io',
  projectId: 'travellerpass-discover',
  storageBucket: 'travellerpass-discover.appspot.com',
  messagingSenderId: '271050062614',
  appId: '1:271050062614:web:d413c3a1313fe261d76e37',
  measurementId: 'G-RHFGGGEDBQ',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth();

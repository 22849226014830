// currencySlice.js

import { createSlice } from '@reduxjs/toolkit';

const loadSelectedCurrencyFromLocalStorage = () => {
  try {
    const selectedCurrency = JSON.parse(localStorage.getItem('selectedCurrency'));
    if (selectedCurrency) {
      return selectedCurrency;
    }
  } catch (e) {}
  return undefined;
};
const currencySlice = createSlice({
  name: 'currency',
  initialState: {
    currencies: [],
    selectedCurrency: loadSelectedCurrencyFromLocalStorage(),
  },
  reducers: {
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setSelectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
    },
    setInitialSelectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
    },
  },
});

export const { setSelectedCurrency, setCurrencies, setInitialSelectedCurrency } =
  currencySlice.actions;

export default currencySlice.reducer;

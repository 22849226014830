import React, { useMemo } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import { Container, DownloadAppModal, SvgIcon } from '../../common';
import './index.scss';

import logoImage from '../../../assets/images/logo-light.svg';
import darkImage from '../../../assets/images/logo.svg';

import CurrenciesDropDown from '../../common/CurrenciesDropDown';
import SearchDropDown from '../../common/SearchDropDown';
import { useDispatch, useSelector } from 'react-redux';
import userImg from '../../../assets/images/app-logo.png';
import DeleteAccountModal from '../../../containers/ProfileSetting/DeleteAccountModal';
import { removeUser } from '../../../slice/userSlice';
import { openChatSupport } from '../../../utility/chat';

const getAppItems = [
  {
    key: 1,
    label: <DownloadAppModal />,
  },
];

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);

  const hasTabs = useMemo(() => {
    return location.pathname === '/about' || location.pathname === '/blog';
  }, [location]);

  const darkIconAndText = useMemo(() => {
    return location.pathname !== '/';
  }, [location]);

  const hasSearchBar = useMemo(() => {
    return location.pathname === '/' || location.pathname.includes('listing-page');
  }, [location]);

  const handleLogout = () => {
    dispatch(removeUser());
    window.location.href = '/';
  };

  const handleNeedHelp = () => {
    openChatSupport();
  };

  const userItems = useMemo(() => {
    if (!user) {
      return [];
    }
    return [
      {
        key: '1',
        label: (
          <div className="user-upper">
            <div className="user-upper-img">
              {' '}
              <img src={user?.uProfilePicture || userImg} alt="" />{' '}
            </div>
            <h4>Hi, {`${user?.vFirstName}`}👋</h4>
          </div>
        ),
      },
      {
        key: '2',
        icon: (
          <span className="menu-icons">
            <SvgIcon name="profile-setting-icon" viewbox="0 0 9.022 9.736" />
          </span>
        ),
        label: <Link to={{ pathname: '/profile-setting' }}>Profile Setting</Link>,
      },
      {
        key: '3',
        icon: (
          <span className="menu-icons">
            <SvgIcon name="mybooking" viewbox="0 0 11.026 9.836" />
          </span>
        ),
        label: (
          <Link
            to={{
              pathname: '/profile-setting',
              state: { tab: 'bookings' },
            }}
          >
            My Bookings
          </Link>
        ),
      },
      {
        key: '4',
        icon: (
          <span className="menu-icons">
            <SvgIcon name="favourite-icon" viewbox="0 0 10.055 8.961" />
          </span>
        ),
        label: (
          <Link
            to={{
              pathname: '/profile-setting',
              state: {
                tab: 'favourites',
              },
            }}
          >
            Wishlist
          </Link>
        ),
      },
      // {
      //   key: "5",
      //   icon: (
      //     <span className="menu-icons">
      //       <SvgIcon name="mycards-icon" viewbox="0 0 10.575 7.931" />
      //     </span>
      //   ),
      //   label: <Link to="/profile-setting">My Cards</Link>,
      // },
      // {
      //   key: "6",
      //   icon: (
      //     <span className="menu-icons">
      //       <SvgIcon name="myoffers-icon" viewbox="0 0 10.083 10.096" />
      //     </span>
      //   ),
      //   label: <Link to="/profile-setting">My Offers</Link>,
      // },
      // {
      //   key: "7",
      //   icon: (
      //     <span className="menu-icons">
      //       <SvgIcon name="notification-icon" viewbox="0 0 8.315 9.262" />
      //     </span>
      //   ),
      //   label: <Link to="/profile-setting">Notification Setting</Link>,
      // },
      {
        key: '8',
        icon: (
          <span className="menu-icons">
            <SvgIcon name="terms" viewbox="0 0 7.55 9.38" />
          </span>
        ),
        label: <Link to="/terms-conditions">Terms & Conditions</Link>,
      },
      {
        key: '9',
        icon: (
          <span className="menu-icons">
            <SvgIcon name="mice" viewbox="0 0 13.753 13.407" />
          </span>
        ),
        label: 'Need Help?',
        onClick: handleNeedHelp,
      },
      {
        key: '10',
        label: 'Logout',
        onClick: handleLogout,
      },
      /* {
        key: '11',
        label: <DeleteAccountModal />,
      },*/
    ];
  }, [user]);

  return (
    <header className="landing-main-header" data-aos="fade-down" data-aos-delay="800">
      <style>
        {`
          .category-box li.selected {
            background-color: #C0C0C0;
          }
        `}
      </style>
      <Container>
        <div className="hader-inner">
          <div className="logo-left">
            <Link to="/">
              <img
                src={darkIconAndText ? darkImage : logoImage}
                alt="Discover Your City Logo"
                onClick={() => history.push('/')}
              />
            </Link>
          </div>

          {hasSearchBar && (
            <div className="center-search-menu">
              <SearchDropDown />
            </div>
          )}
          {hasTabs && (
            <div className="center-tab-menu">
              <Menu
                mode="horizontal"
                defaultSelectedKeys={['1']}
                overflowedIndicator={<SvgIcon name="menu" viewbox="0 0 24 17.42" />}
              >
                <Menu.Item key="1">
                  <NavLink
                    activeClassName={''}
                    to="/"
                    className={location.pathname === '/' ? 'active' : ''}
                  >
                    Home
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="2">
                  <NavLink to="/about" className={location.pathname === '/about' ? 'active' : ''}>
                    About Us
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="3">
                  <a
                    href="https://medium.com/@discoveryourcity.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                  {/* <NavLink to="/blog" className={location.pathname === '/blog' ? 'active' : ''}>
                    Blog
                  </NavLink>*/}
                </Menu.Item>
              </Menu>
            </div>
          )}
          {!hasSearchBar && !hasTabs && <div className="center-menu-default" />}

          <div className="hedaer-right">
            <Dropdown
              overlayClassName="tpdownload-drop"
              placement="bottomRight"
              arrow={false}
              menu={{ items: getAppItems }}
            >
              <div
                className={`download-link ${darkIconAndText ? 'navigation-text-color-black' : 'navigation-text-color-white'}`}
              >
                <SvgIcon
                  name="phone"
                  viewbox="0 0 12.18 20.438"
                  fill={darkIconAndText ? 'black' : 'white'}
                />{' '}
                Get the App
              </div>
            </Dropdown>
            {/* CurrenciesDropDown Component */}
            <CurrenciesDropDown darkIconAndText={darkIconAndText} />
            {user ? (
              <Dropdown
                overlayClassName="user-drop-menu"
                menu={{ items: userItems }}
                placement="bottomRight"
              >
                <Button className="user-right-btn" type="text">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p
                      style={{
                        margin: 0,
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#333',
                      }}
                      className={
                        darkIconAndText
                          ? 'navigation-text-color-black'
                          : 'navigation-text-color-white'
                      }
                    >
                      {user.vFirstName}
                    </p>
                    <div className="user-rightbtn-img" style={{ marginLeft: '8px' }}>
                      <img src={user?.uProfilePicture || userImg} alt="img" />
                    </div>
                  </div>
                </Button>
              </Dropdown>
            ) : (
              <Button
                onClick={() => {
                  history.push('/login');
                }}
                className={`login-btn ${darkIconAndText ? 'navigation-text-color-black' : 'navigation-text-color-white'}`}
                size="small"
              >
                Sign in{' '}
                <SvgIcon
                  name="user-alt"
                  viewbox="0 0 8 9"
                  fill={darkIconAndText ? 'black' : 'white'}
                />
              </Button>
            )}
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Navbar;

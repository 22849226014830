import React, { useEffect, useMemo, useState } from 'react';
import { SvgIcon } from '../index';
import { Button, Checkbox, Dropdown, Slider } from 'antd';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import '../../../containers/LandingPage/index.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { format, isValid, parse } from 'date-fns';
import { useSelector } from 'react-redux';
import './index.scss';
import { isNumber } from 'lodash';

const HeaderFilter = (props) => {
  const { mapListingPage, onTriggerDataFetch } = props;

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const queryCategoryId = searchParams.get('categoryId');
  const queryDate = searchParams.get('date');
  const queryPrice = searchParams.get('price');

  const _categories = useSelector((state) => state.category.categories);
  const selectedCurrency = useSelector((state) => state.currency.selectedCurrency);

  const [categories, setCategories] = useState(_categories);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [priceRange, setPriceRange] = useState({
    start: 0,
    end: 0,
  });
  const [categoryDropOpen, setCategoryDropOpen] = useState(false);
  const [dateDropOpen, setDateDropOpen] = useState(false);
  const [priceDropOpen, setPriceDropOpen] = useState(false);
  const [handleTriggerDataFetch, setHandleTriggerDataFetch] = useState(false);

  const selectedCategory = useMemo(() => {
    return categories.find((item) => item.isSelected);
  }, [categories]);

  const setSelectedCategory = (category) => {
    const updated = categories.map((item) => {
      if (
        (category.rCategoryID && item.rCategoryID && category.rCategoryID === item.rCategoryID) ||
        (category.gCategoryID && item.gCategoryID && category.gCategoryID === item.gCategoryID)
      ) {
        return { ...item, isSelected: true };
      } else {
        return { ...item, isSelected: false };
      }
    });
    setCategories(updated);
  };

  const resetSelectedCategory = () => {
    setCategories(_categories);
  };

  const handleResetClick = (name) => {
    if (name === 'categoryId') {
      resetSelectedCategory();
      removeQueryParamAndReplace(name);
    }
    if (name === 'date') {
      setSelectedDate(undefined);
      removeQueryParamAndReplace(name);
    }
    if (name === 'price') {
      setPriceRange({
        start: 0,
        end: 0,
      });
      removeQueryParamAndReplace('price');
    }
  };

  const removeQueryParamAndReplace = (paramName, value) => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.delete(paramName);
    history.replace({ search: searchParams.toString() });
  };

  const categoryItems = [
    {
      key: '1',
      label: (
        <div className="category-box" key="category-box">
          <h3>Category</h3>
          <ul>
            {categories.length > 0 ? (
              categories.map((category, i) => (
                <li
                  key={category.rCategoryID || category.gCategoryID}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category.rCategoryName || category.gCategoryName}{' '}
                  <Checkbox
                    checked={category.isSelected}
                    onClick={() => setSelectedCategory(category)}
                  />
                </li>
              ))
            ) : (
              <li>No categories found</li>
            )}
          </ul>
        </div>
      ),
    },
  ];

  const dateItems = [
    {
      key: '1',
      label: (
        <div className="category-box">
          <h3>Planning your trip?</h3>
          <div className="planning-time">
            <Calendar
              onChange={(date) => setSelectedDate(date)}
              value={selectedDate || ''} // Set the value prop to the selectedDate
              locale="en"
              minDate={new Date()}
              maxDate={((d) => new Date(d.getFullYear() + 1, d.getMonth(), d.getDate()))(
                new Date(),
              )}
            />
          </div>
        </div>
      ),
    },
  ];

  const marks = useMemo(() => {
    let obj = {};
    for (let i = 0; i <= 10000; i++) {
      if (i % 500 === 0) {
        obj = { ...obj, [i]: i.toString() };
      }
    }
    return obj;
  }, []);

  const priceRangeItems = [
    {
      key: '1',
      label: (
        <div className="category-box">
          <h3>Price Range</h3>
          <div className="price-range">
            <div className="minmax-row">
              <div className="column">
                <label>{`${selectedCurrency.uCurrency} ${priceRange.start}`}</label>
                <p>Min</p>
              </div>
              <div style={{ width: 16 }} />
              <div className="column">
                <label>{`${selectedCurrency.uCurrency} ${priceRange.end}`}</label>
                <p>Max</p>
              </div>
            </div>
            <Slider
              range
              min={0}
              max={10000}
              marks={marks}
              value={[priceRange.start, priceRange.end]}
              onChange={(values) => {
                setPriceRange({ start: values[0], end: values[1] });
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const handleShowResultsClick = () => {
    const searchParams = new URLSearchParams();

    if (selectedCategory) {
      searchParams.set('categoryId', selectedCategory.rCategoryID || selectedCategory.gCategoryID);
    }

    // Check if date is selected
    if (selectedDate) {
      searchParams.set('date', format(selectedDate, 'yyyy-MM-dd'));
    }

    // Check if price range is selected
    if (priceRange.start !== 0 || priceRange.end !== 0) {
      const { start, end } = priceRange;
      searchParams.set('price', `${start}-${end}`);
    }

    setCategoryDropOpen(false);
    setDateDropOpen(false);
    setPriceDropOpen(false);

    if (mapListingPage) {
      history.replace({ pathname: '/listing-page-map', search: searchParams.toString() });
    } else {
      history.replace({ pathname: '/listing-page', search: searchParams.toString() });
    }
  };

  // second useEffect is so the values set by first useEffect can be send to the onTriggerDataFetch
  // function
  useEffect(() => {
    if (handleTriggerDataFetch && onTriggerDataFetch) {
      setHandleTriggerDataFetch(false);
      onTriggerDataFetch(selectedCategory, selectedDate, priceRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTriggerDataFetch]);

  useEffect(() => {
    if (onTriggerDataFetch) {
      let queryDataValid = true;
      const searchParams = new URLSearchParams(history.location.search);

      if (queryCategoryId) {
        const foundCategory = categories.find((item) => {
          return (
            (item.rCategoryID && item.rCategoryID === queryCategoryId) ||
            (item.gCategoryID && item.gCategoryID === queryCategoryId)
          );
        });
        if (!foundCategory) {
          console.log(`Query has invalid categoryId removing from params.`);
          searchParams.delete('categoryId');
          queryDataValid = false;
        } else {
          setSelectedCategory(foundCategory);
        }
      } else {
        resetSelectedCategory();
      }

      if (queryDate) {
        const parsedDate = parse(queryDate, 'yyyy-MM-dd', new Date());
        if (!isValid(parsedDate)) {
          console.log(`Query has invalid date removing from params.`);
          searchParams.delete('date');
          queryDataValid = false;
        } else {
          setSelectedDate(parsedDate);
        }
      } else {
        setSelectedDate(undefined);
      }

      if (queryPrice) {
        const [start, end] = queryPrice.split('-').map((s) => +s);
        if (isNumber(start) && isFinite(end) && start < end) {
          setPriceRange({ start, end });
        } else {
          console.log(`Query has invalid price removing from params.`);
          searchParams.delete('price');
          queryDataValid = false;
        }
      } else {
        setPriceRange({
          start: 0,
          end: 0,
        });
      }
      if (!queryDataValid) {
        console.log('History replace ', searchParams);
        history.replace({ search: searchParams.toString() });
      } else {
        console.log('trigger fetch data');
        setCategoryDropOpen(false);
        setDateDropOpen(false);
        setPriceDropOpen(false);
        setHandleTriggerDataFetch(true);
      }
    }
    // eslint-disable-next-line
  }, [queryCategoryId, queryDate, queryPrice]);

  return (
    <div className="upperfilters-row">
      <Dropdown
        menu={{
          items: categoryItems,
        }}
        open={categoryDropOpen}
        onOpenChange={(nextOpen, info) => {
          if (info.source === 'trigger' || nextOpen) {
            setCategoryDropOpen(nextOpen);
          }
        }}
        overlayClassName="filter-drop"
        placement="bottom"
        trigger={['click']}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <div className="drop-footer">
              <Button type="text" onClick={() => handleResetClick('categoryId')}>
                Reset all
              </Button>
              <Button type="primary" onClick={handleShowResultsClick}>
                Show results
              </Button>
            </div>
          </div>
        )}
      >
        <button onClick={(e) => e.preventDefault()}>
          {selectedCategory?.rCategoryName || selectedCategory?.gCategoryName || `Category`}{' '}
          <SvgIcon name="chevron-bottom" viewbox="0 0 13 8" />
        </button>
      </Dropdown>
      <Dropdown
        menu={{ items: dateItems }}
        open={dateDropOpen}
        onOpenChange={(nextOpen, info) => {
          if (info.source === 'trigger' || nextOpen) {
            setDateDropOpen(nextOpen);
          }
        }}
        overlayClassName="filter-drop"
        placement="bottom"
        trigger={['click']}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <div className="drop-footer">
              <Button type="text" onClick={() => handleResetClick('date')}>
                Reset all
              </Button>
              <Button type="primary" onClick={handleShowResultsClick}>
                Show results
              </Button>
            </div>
          </div>
        )}
      >
        <button onClick={(e) => e.preventDefault()}>
          {selectedDate ? format(selectedDate, 'yyyy-MM-dd') : `Date`}{' '}
          <SvgIcon name="chevron-bottom" viewbox="0 0 13 8" />
        </button>
      </Dropdown>
      <Dropdown
        menu={{ items: priceRangeItems }}
        open={priceDropOpen}
        onOpenChange={(nextOpen, info) => {
          if (info.source === 'trigger' || nextOpen) {
            setPriceDropOpen(nextOpen);
          }
        }}
        overlayClassName="filter-drop"
        placement="bottom"
        trigger={['click']}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <div className="drop-footer">
              <Button type="text" onClick={() => handleResetClick('price')}>
                Reset all
              </Button>
              <Button type="primary" onClick={handleShowResultsClick}>
                Show results
              </Button>
            </div>
          </div>
        )}
      >
        <button onClick={(e) => e.preventDefault()}>
          {priceRange.start >= 0 && priceRange.end > 1
            ? `${selectedCurrency.uCurrency} ${priceRange.start} - ${selectedCurrency.uCurrency} ${priceRange.end}`
            : `Price Range`}{' '}
          <SvgIcon name="chevron-bottom" viewbox="0 0 13 8" />
        </button>
      </Dropdown>
    </div>
  );
};

export default HeaderFilter;

import React, { useEffect, useState } from 'react';
import { TourItemCard } from '../../components/common';
import Lottie from 'react-lottie';
import './index.scss';
import AnimationOne from '../../assets/json/empty-offer.json';
import Apis from '../../utility/apis';
import { useSelector } from 'react-redux';
import apiClient from '../../apiConfig';
import CustomLoader from '../../components/common/Loader/CustomLoader';

const FavouriteTab = () => {
  const currencyData = useSelector((state) => state.currency.selectedCurrency);

  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };
  const [isShowData, setIsShowData] = useState(false);
  const [listData, setListData] = useState([]);
  const selectedCity = useSelector((state) => state.countryCity.selectedCity);
  const currency = useSelector((state) => state.currency);
  // console.log((currency, "currency"));
  const [showLoader, setshowLoader] = useState(false);

  const userData = useSelector((state) => state.user.user);

  const showData = () => {
    setIsShowData(true);
  };

  // console.log((listData, "listdata"));
  const handleFavToggle = (value, item) => {
    setshowLoader(true);
    apiClient
      .post(Apis('toggleFavTour', item.country, userData ? 'loggedIn' : 'guest'), {
        tourId: item.tourId,
        productId: item.productId,
        favourite: value,
      })
      .then(() => fetchData());
  };
  const fetchData = async () => {
    try {
      setshowLoader(true);
      const response = await apiClient.post(
        // Fetch data from API
        '/discover/favouriteTourList',
        // Apis("listByGroup", selectedCity.vCountryName, "guest"),
        {
          dCurrentLat: selectedCity.vCityLatitude,
          dCurrentLong: selectedCity.vCityLongitude,
        },
        {
          headers: {
            uCurrency: currency.uCurrency,
          },
        },
      );

      const response2 = await apiClient.post(
        // Fetch data from API
        '/globaltix/favouriteProductList',
        // Apis("listByGroup", selectedCity.vCountryName, "guest"),
        {
          dCurrentLat: selectedCity.vCityLatitude,
          dCurrentLong: selectedCity.vCityLongitude,
        },
        {
          headers: {
            uCurrency: currency.uCurrency,
          },
        },
      );
      // setListData((state) => {
      //    return [...response.data?.DATA,...response2.data?.DATA]
      // });
      setListData((prevData) => {
        const newData = [];

        if (response.data?.DATA?.length > 0) {
          newData.push(
            ...response.data?.DATA.map((e) => {
              return { ...e, country: 'UAE' };
            }),
          );
        }

        if (response2.data?.DATA?.length > 0) {
          newData.push(
            ...response2.data?.DATA.map((e) => {
              return { ...e, country: 'OTHERS' };
            }),
          );
        }

        return [...newData];
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setshowLoader(false);
  };
  useEffect(() => {
    fetchData();
    return () => {
      setListData([]);
    };
  }, []);

  return (
    <div className="right-tab-inner">
      {showLoader && <CustomLoader />}
      {listData.length !== 0 ? (
        <>
          <h1 className="tab-heading" data-aos="fade-in" data-aos-duration="800">
            Favorite
          </h1>
          <div className="tab-content favourite-tab" data-aos="fade-in" data-aos-duration="800">
            <div className="listing-row">
              {listData.map((item, index) => (
                <TourItemCard
                  key={index}
                  item={item}
                  handleFavToggle={() => {
                    handleFavToggle(item.isFavouriteOffer === 0 ? 1 : 0, item);
                  }}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="empty-data-card-wrapper">
          <div
            className="empty-data-card empty-favourite"
            onClick={showData}
            data-aos="fade-in"
            data-aos-duration="800"
          >
            <Lottie options={animationLottie} height={280} width={280} />
            <h2>Sorry, its empty!</h2>
            <p>
              We will make sure to notify you when you have free vouchers, promotions and goodies to
              avail!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FavouriteTab;

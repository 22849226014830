import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Footer, Navbar } from '../components/layout';

const DetailsLayoutContext = createContext();

const DefaultDetailsLayout = ({ children, navbar, footer }) => {
  const [showDetailsFooter, setShowDetailsFooter] = useState(false);

  return (
    <DetailsLayoutContext.Provider value={{ setShowDetailsFooter }}>
      <React.Fragment>
        <div className="detailpage-wrapper">
          {!navbar && <Navbar />}
          <main>{children}</main>
          {!footer && showDetailsFooter && <Footer />}
        </div>
      </React.Fragment>
    </DetailsLayoutContext.Provider>
  );
};

DefaultDetailsLayout.propTypes = {
  navbar: PropTypes.bool,
  footer: PropTypes.bool,
};

DefaultDetailsLayout.defaultProps = {
  navbar: false,
  footer: false,
};

export const useDetailsLayoutContext = () => {
  const { setShowDetailsFooter } = useContext(DetailsLayoutContext);
  return {
    setShowDetailsFooter,
  };
};

export default DefaultDetailsLayout;

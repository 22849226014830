import MediaQuery from 'react-responsive';
import { Drawer, Modal } from 'antd';
import { SvgIcon } from '../index';
import React from 'react';
import './index.scss';
import { Alert } from '../../../common/alert';

const PromoPopupContent = (props) => {
  const { data, setShowDynamicPopup } = props;
  return (
    <>
      <img className="popup-image" src={data.iPopupImage} alt="Popup Image" />
      <div className="popup-text">
        <span>{data.iPopupTitle}</span>
        <span>{data.tDescription}</span>
      </div>
      <div
        className="promo"
        onClick={() => {
          navigator.clipboard
            .writeText(data.tPromoCode)
            .then(() => {
              Alert.success('Promo code copied to clipboard');
              // console.log(('URL copied to clipboard'));
            })
            .catch((error) => {
              console.error('Failed to copy promo code: ', error);
            });
          setShowDynamicPopup(null);
        }}
      >
        <div>{data.tPromoCode}</div>
        <div>
          <span>Copy</span>
          <SvgIcon name="copy" viewbox="0 0 16 16" />
        </div>
      </div>
    </>
  );
};
export const PromoPopup = (props) => {
  const { setShowDynamicPopup } = props;
  return (
    <>
      <MediaQuery minWidth={768}>
        <Modal
          open={true}
          onCancel={() => {
            setShowDynamicPopup(null);
          }}
          closeIcon={<SvgIcon name="close" viewbox="0 0 7.481 7.521" />}
          footer={false}
          centered
          maskClosable={false}
          mask={true}
          zIndex={1100}
          wrapClassName="promo-popup"
        >
          <PromoPopupContent {...props} />
        </Modal>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <Drawer
          height="auto"
          placement="bottom"
          open={true}
          onClose={() => setShowDynamicPopup(null)}
          title={null}
          footer={null}
          closeIcon={null}
          rootClassName="promo-popup"
        >
          <PromoPopupContent {...props} />
        </Drawer>
      </MediaQuery>
    </>
  );
};

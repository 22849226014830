import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiClient from '../../../apiConfig';
import Apis from '../../../utility/apis';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { setSelectedCity } from '../../../slice/countryCitySlice';
import { SvgIcon } from '../index';
import { Button, Input, Select, Spin } from 'antd';
import './index.scss';
import { CityItemCard } from '../CityItemCard';
import Lottie from 'react-lottie';
import DotLoader from '../../../assets/json/dot_loader.json';

const SearchDropDown = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const latLng = useSelector((state) => state.user.latLng);
  const selectedCity = useSelector((state) => state.countryCity.selectedCity);
  const cities = useSelector((state) => state.countryCity.cities);

  const [searchDropOpen, setSearchDropOpen] = useState(false);
  const inputRef = useRef(null);
  const [searchInput, setSearchInput] = useState('');
  const [citiesWithSelection, setCitiesWithSelection] = useState([]);
  const [filteredCityList, setFilteredCityList] = useState([]);
  const [tourSearchResults, setTourSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCitySelection, setShowCitySelection] = useState(true);

  const settingsCitySlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: 10,
  };

  const countryCities = useMemo(() => {
    const countryCityMap = citiesWithSelection.reduce((arr, city) => {
      const key = city.vCountryName;
      arr[key] = (arr[key] || []).concat(city);
      return arr;
    }, {});

    return Object.keys(countryCityMap).map((cityName) => {
      return { cityName, cities: countryCityMap[cityName] };
    });
  }, [citiesWithSelection]);

  const setTempSelectedCity = (iCityID) => {
    setCitiesWithSelection(
      cities.map((item) => {
        return { ...item, isSelected: item.iCityID === iCityID };
      }),
    );
  };

  const filterCities = (query) => {
    let list = cities;
    if (query) {
      list = cities.filter(
        (item) =>
          item.vCityName.toLowerCase().includes(query.toLowerCase()) ||
          item.vCountryName.toLowerCase().includes(query.toLowerCase()),
      );
    }
    setFilteredCityList(
      list.map((item) => {
        return { ...item, key: `city_${item.iCityID}` };
      }),
    );
  };

  const handleSearch = async (inputValue) => {
    try {
      const [discoverResponse, globalResponse] = await Promise.all([
        apiClient.post(Apis('tourSearch', 'UAE', user ? 'loggedIn' : 'guest'), {
          sType: 'All',
          Language: 'en',
          dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
          dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
          searchKey: inputValue.toLowerCase(),
        }),
        await apiClient.post(Apis('tourSearch', 'OTHERS', user ? 'loggedIn' : 'guest'), {
          sType: 'All',
          Language: 'en',
          dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
          dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
          searchKey: inputValue.toLowerCase(),
        }),
      ]);

      const results = [];

      if (discoverResponse.data.status === 1) {
        results.push(
          ...discoverResponse.data.DATA.map((item) => {
            return {
              ...item,
              key: `discover_${item.sType.toLowerCase()}_${item.tourId || item.rCategoryID}`,
            };
          }),
        );
      }
      if (globalResponse.data.status === 1) {
        results.push(
          ...globalResponse.data.DATA.map((item) => {
            return {
              ...item,
              key: `global_${item.sType.toLowerCase()}_${item.productId || item.gCategoryID}`,
            };
          }),
        );
      }

      setTourSearchResults(results);
    } catch (error) {
      console.error('Error searching for tours:', error);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((inputValue) => handleSearch(inputValue), 700),
    [],
  );

  const handleSearchInput = (inputValue) => {
    setSearchInput(inputValue);

    const query = inputValue.trim();
    if (query.length > 2) {
      setLoading(true);
      setShowCitySelection(false);
      filterCities(query);
      debounceSearch(query);
    } else {
      setShowCitySelection(true);
      filterCities();
      setTourSearchResults([]);
    }
  };

  const handleSelectChange = (value, option) => {
    const parts = value.split('_');
    if (parts.length === 3) {
      if (parts[0] === 'discover') {
        history.push(`/discover-details?tourId=${parts[2]}`);
      } else if (parts[0] === 'global') {
        history.push(`/global-details?productId=${parts[2]}`);
      }
    } else if (parts.length === 2) {
      if (parts[0] === 'city') {
        const foundItem = cities.find((city) => +city.iCityID === +parts[1]);
        if (foundItem) {
          dispatch(setSelectedCity(foundItem));
        }
      }
    }
  };

  useEffect(() => {
    filterCities();
    setTempSelectedCity(selectedCity.iCityID);
  }, [selectedCity]);

  useEffect(() => {
    if (!searchDropOpen) {
      inputRef.current?.blur();
      handleSearchInput('');
    } else {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 150);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDropOpen]);

  return (
    <div className="landing-search">
      <div className="search-icon">
        <SvgIcon name="search" viewbox="0 0 12.901 12.905" />
      </div>
      <div className="landing-search-right">
        <label className="select-label">Where to?</label>
        <Select
          value="dummy"
          open={searchDropOpen}
          onDropdownVisibleChange={(open) => setSearchDropOpen(open)}
          popupClassName="country-drop-search"
          listHeight={420}
          suffixIcon={<SvgIcon name="search" viewbox="0 0 12.901 12.905" />}
          dropdownRender={(menu) => (
            <>
              {showCitySelection ? (
                <>
                  <h3 className="choose-city">Choose City</h3>
                  <div className="city-list">
                    {countryCities.map((value, index) => {
                      return (
                        <div className="city-row" key={index}>
                          <h3>{value.cityName}</h3>
                          <div>
                            {value.cities.map((city, cityIndex) => {
                              return (
                                <CityItemCard
                                  key={index + '_' + cityIndex}
                                  item={city}
                                  onClick={() => {
                                    setTempSelectedCity(city.iCityID);
                                  }}
                                />
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="drop-footer">
                    <Button
                      type="text"
                      onClick={() => {
                        setTempSelectedCity(selectedCity.iCityID);
                      }}
                    >
                      Reset all
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        const foundItem = citiesWithSelection.find((city) => city.isSelected);
                        if (foundItem) {
                          dispatch(setSelectedCity(foundItem));
                        }
                      }}
                    >
                      Choose
                    </Button>
                  </div>
                </>
              ) : (
                { ...menu }
              )}
            </>
          )}
          options={[
            ...filteredCityList.map((city) => ({
              value: city.key,
              label: (
                <div className="drop-item">
                  <div className="city-icon">
                    <SvgIcon name="map-marker" viewbox="0 0 34 48" />
                  </div>
                  {`${city.vCityName}, ${city.vCountryName}`}
                </div>
              ),
            })),
            ...tourSearchResults.map((tour) => ({
              value: tour.key,
              label: (
                <div className="drop-item">
                  <div className="city-icon">
                    <img src={tour.rTourImage || tour.gProductImage} alt="Tour" />{' '}
                  </div>{' '}
                  {tour.tourName || tour.productName} {/* Use tour name here */}
                </div>
              ),
            })),
            ...(loading
              ? [
                  {
                    value: 'loader',
                    label: (
                      <div className="drop-item" style={{ justifyContent: 'center' }}>
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: DotLoader,
                            renderer: 'svg',
                          }}
                          height={150}
                          width={150}
                        />
                      </div>
                    ),
                  },
                ]
              : []),
          ]}
          onChange={handleSelectChange}
          labelRender={(props) => {
            return (
              <Input
                ref={inputRef}
                placeholder={
                  searchDropOpen
                    ? 'Search...'
                    : `${selectedCity.vCityName}, ${selectedCity.vCountryName}`
                }
                value={searchInput}
                readOnly={!searchDropOpen}
                className={searchDropOpen ? 'expanded' : ''}
                onChange={(e) => handleSearchInput(e.target.value)}
              />
            );
          }}
        />
      </div>
    </div>
  );
};
export default SearchDropDown;

import { createSlice } from '@reduxjs/toolkit';

const loadSelectedCityFromLocalStorage = () => {
  try {
    let serializedCity = JSON.parse(localStorage.getItem('selectedCity'));
    if (serializedCity) {
      return serializedCity;
    }
  } catch (err) {
    console.error(err);
  }
  return undefined;
};

const countryCitySlice = createSlice({
  name: 'city',
  initialState: {
    cities: [],
    selectedCity: loadSelectedCityFromLocalStorage(),
  },
  reducers: {
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    setInitialSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
    },
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
    },
  },
});

export const { setSelectedCity, setInitialSelectedCity, setCities } = countryCitySlice.actions;
export default countryCitySlice.reducer;

import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import store from './store';
import { insertUrlParam } from './utility/util';

class AddCityName extends PureComponent {
  componentDidMount = () => {
    const { selectedCity } = store.getState().countryCity;
    if (selectedCity) {
      insertUrlParam('vCityName', selectedCity.vCityName);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const { selectedCity } = store.getState().countryCity;
      if (selectedCity) {
        insertUrlParam('vCityName', selectedCity.vCityName);
      }
    }
  };

  render = () => this.props.children;
}

export default withRouter(AddCityName);

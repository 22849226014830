// checkedItemsSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: [],
    loading: true,
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
      state.loading = false;
    },
  },
});

export const { setCategories } = categorySlice.actions;

export default categorySlice.reducer;

import React, { useEffect, useState } from 'react';
import { Col, Container, Row, SvgIcon } from '../../../components/common';
import { Button, Form } from 'antd';
import { Link, Redirect, useHistory } from 'react-router-dom';
import './index.scss';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import apiClient from '../../../apiConfig';
import QRCode from 'react-qr-code';
import CancelModal from './CancelErrorModal';
import { htmlToList } from '../../../utility/parser';
import { isFinite } from 'lodash';
import { useLoaderContext } from '../../../components/common/Loader/LoaderContext';
import { openChatSupport } from '../../../utility/chat';

const { format } = require('date-fns');

const MyBookingDetails = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const rBookingID = searchParams.get('rBookingID');
  const type = searchParams.get('type');
  const [ticketData, setticketData] = useState();
  const { setShowLoader } = useLoaderContext();
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState('');

  const fetchData = async (status) => {
    setShowLoader(true);
    try {
      let response;
      if (type === 'discover') {
        response = await apiClient.post('/discover/myTicket', {
          rBookingID: rBookingID,
          pBookingID: rBookingID,
        });
      } else {
        response = await apiClient.post('/globaltix/myTicket', {
          rBookingID: rBookingID,
          pBookingID: rBookingID,
        });
      }
      if (response.data.status === 1) {
        setticketData(response.data);
      } else {
        return [];
      }
    } catch (error) {
      // console.log((error));
    } finally {
      setShowLoader(false);
    }
  };
  useEffect(() => {
    if (rBookingID && type) {
      fetchData();
    }
    return () => {};
  }, []);

  const downloadTicket = async () => {
    setShowLoader(true);
    try {
      let response;
      if (type === 'discover') {
        response = await apiClient.post('/discover/downloadTicket', {
          rBookingID: rBookingID,
          pBookingID: rBookingID,
        });
      } else {
        response = await apiClient.post('/globaltix/downloadTicket', {
          pBookingID: rBookingID,
        });
      }
      if (response.data.status === 1) {
        window.open(response.data.DATA.pdfLink, '_blank');
      } else {
        return [];
      }
    } catch (error) {
      // console.log((error));
    } finally {
      setShowLoader(false);
    }
  };
  const handleDownload = () => {
    downloadTicket();
  };
  const handleCancel = async () => {
    setShowLoader(true);
    try {
      let response;
      if (type === 'discover') {
        response = await apiClient.post('/discover/cancelBooking', {
          rBookingID: rBookingID,
          currency: ticketData.DATA.uCurrency,
        });
      } else {
        response = await apiClient.post('/globaltix/cancelBooking', {
          pBookingID: rBookingID,
          currency: ticketData.DATA.uCurrency,
        });
      }
      if (response.data.status === 0 || response.data.status === 1) {
        setMsg(response.data.MESSAGE);
        setShowModal(true);
      } else {
      }
    } catch (error) {
      // console.log((error.response, "statttus"));
    } finally {
      setShowLoader(false);
    }
  };
  if (!rBookingID && isFinite(+rBookingID) && !type) {
    return <Redirect to="/" />;
  }
  return (
    <div className="mybookingdetails-wrapper">
      {showModal && (
        <CancelModal
          setShowModal={(open) => {
            setShowModal(open);
            if (!open) {
              history.replace('/profile-setting', { tab: 'bookings' });
            }
          }}
          msg={msg}
        />
      )}
      <div className="mybookingdetails-inner">
        <Container>
          <Row>
            <Col>
              <Form name="basic" autoComplete="off" layout="vertical">
                <div className="mybookingdetails-row">
                  <div className="mybookingdetails-left">
                    <div className="twl-header" data-aos="fade-in" data-aos-duration="800">
                      <Link to="/profile-setting">
                        <SvgIcon name="arrow-left" viewbox="0 0 12.251 8.653" />
                      </Link>
                      <h2>Your Ticket</h2>
                    </div>
                    <div className="twl-content">
                      <div className="twlcontent-inner">
                        <div className="confirm-details pt-3">
                          <div className="dtl-row">
                            <div className="dtl-left">
                              <p>Booking Date and time</p>
                            </div>
                            <div className="dtl-right">
                              {ticketData?.DATA.tourDate ||
                                (ticketData?.DATA.bookingTime &&
                                  format(
                                    new Date(ticketData?.DATA.bookingTime),
                                    'dd MMM yy hh:mm a',
                                  ))}
                              {/* 25<sup>th</sup> Jun 2022 */}
                            </div>
                          </div>
                          <div className="dtl-row">
                            <div className="dtl-left">
                              <p>Payment method</p>
                            </div>
                            <div className="dtl-right">
                              <div className="d-flex align-items-center">
                                {/* ****{ticketData?.DATA.last4} <div className='card-icon'> <img src={ticketData?.DATA.scheme === 'visa' ? VisaCard : MaterCard} alt='' /> </div> */}
                                ****{ticketData?.DATA.last4}{' '}
                                <div className="card-icon">
                                  {' '}
                                  <img src={ticketData?.DATA.paymentIconImage} alt="" />{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="dtl-row">
                            <div className="dtl-left">
                              <p>Email</p>
                            </div>
                            <div className="dtl-right">{ticketData?.DATA.email}</div>
                          </div>
                          <div className="dtl-row">
                            <div className="dtl-left">
                              <p>Mobile</p>
                            </div>
                            <div className="dtl-right">{ticketData?.DATA.mobile}</div>
                          </div>
                        </div>
                        <Row>
                          <Col className="cancelpolicy-info">
                            {ticketData?.DATA.termsAndConditions && (
                              <>
                                <h3>Terms and Condition</h3>
                                <ul>
                                  {ticketData?.DATA.termsAndConditions
                                    .split('\n')
                                    .map((e) => e.length !== 0 && <li>{e}</li>)}
                                  {/* <li>Non cancellable and non refundable</li>
                                                                    <li>All baggage and personal items MUST go through the Security Scanning Machine</li>
                                                                    <li>Eating, drinking, chewing gum and smoking are not allowed during the visit</li> */}
                                </ul>
                              </>
                            )}
                            {/* <div>
                                                            <h3>How to Use</h3>
                                                            <p>
                                                                Get Ready to enjoy At The Top Level 124th Floor & Sky View Observatory tickets at the sky view Address Towers
                                                            </p>
                                                        </div> */}
                            {ticketData?.DATA.cancellationPolicyDescription && (
                              <div className="mt-4">
                                <h3>Cancellation Policy</h3>
                                {/* <p>5% cancellation fees will be charged for all applicable bookings.</p> */}
                                {/* {ticketData?.DATA.cancellationPolicyDescription} */}
                                <ul>
                                  {htmlToList(ticketData.DATA.cancellationPolicyDescription).map(
                                    (text, index) => (
                                      <li key={index}>{text}</li>
                                    ),
                                  )}
                                </ul>
                              </div>
                            )}
                            {ticketData?.DATA.childCancellationPolicyDescription && (
                              <div className="mt-4">
                                <h3>{ticketData?.DATA.childCancellationPolicyName}</h3>
                                <ul>
                                  {htmlToList(
                                    ticketData.DATA.childCancellationPolicyDescription,
                                  ).map((text, index) => (
                                    <li key={index}>{text}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            <div>
                              <Button
                                className="needhelp-btn"
                                type="primary"
                                ghost
                                onClick={openChatSupport}
                              >
                                <SvgIcon name="mice" viewbox="0 0 13.753 13.407" /> Need Help?
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="mybookingdetails-right">
                    <div className="twl-content">
                      <div className="twlcontent-inner">
                        <ul className="summary-list" data-aos="fade-left" data-aos-duration="800">
                          <div className="right-tag">
                            <p>{ticketData?.DATA.tDayRemain || ticketData?.DATA.pDayRemain}</p>
                            <small>DAYS</small>
                          </div>
                          <li className="pt-0">
                            <div className="image-col">
                              <img
                                src={ticketData?.DATA.rTourImage || ticketData?.DATA.gProductImage}
                                alt="Dolphinarium Regular"
                              />
                            </div>
                            <div className="list-left">
                              <h3>{ticketData?.DATA.tourName || ticketData?.DATA.productName}</h3>
                              <p>
                                <SvgIcon name="map" viewbox="0 0 8.358 12.537" />{' '}
                                {ticketData?.DATA.cityName || ticketData?.DATA.vCityName},{' '}
                                {ticketData?.DATA.countryName || ticketData?.DATA.vCountryName}
                              </p>
                            </div>
                          </li>
                        </ul>
                        <div
                          className="payment-details"
                          data-aos="fade-left"
                          data-aos-duration="1200"
                        >
                          <Row className="discount-row">
                            <Col>
                              <b>
                                {ticketData?.DATA.prefix || ticketData?.DATA.uPrefix}{' '}
                                {ticketData?.DATA.firstName || ticketData?.DATA.customerName}{' '}
                                {ticketData?.DATA.lastName}
                              </b>
                              <label>Lead Participant</label>
                            </Col>
                            <Col className="text-right">
                              <b>
                                {ticketData?.DATA.uCurrency} {ticketData?.DATA.tServiceTotal}
                              </b>
                              <label>Paid</label>
                            </Col>
                          </Row>
                          <Row className="discount-row">
                            <Col>
                              <b>{ticketData?.DATA.tourDate || ticketData?.DATA.visitDate}</b>
                              <label>Date</label>
                            </Col>
                            <Col className="text-right">
                              <b>{ticketData?.DATA.startTime || ticketData?.DATA.visitTime}</b>
                              <label>startTime</label>
                            </Col>
                          </Row>
                          <Row className="discount-row">
                            <Col>
                              {/* <b>(10) Adult, (1) Child</b> */}
                              <b>{ticketData?.DATA.tPersons}</b>

                              <label>Persons</label>
                            </Col>
                            <Col className="text-right">
                              <b>
                                {ticketData?.DATA.uniqueNo || ticketData?.DATA.confirmationCode}
                              </b>
                              <label>Confirmation Code</label>
                            </Col>
                          </Row>
                          <Row className="discount-row">
                            <Col>
                              <b>{ticketData?.DATA.optionName}</b>
                              <label>Option</label>
                            </Col>
                          </Row>
                          <div className="bottom-details">
                            {ticketData?.DATA.rBookingTickets?.ticketDetails.map((e) => (
                              <div className="barcode-row" style={{ marginBottom: '30px' }}>
                                {/* <img src={qrCode} alt='qrCode' /> */}
                                <QRCode
                                  size={256}
                                  style={{
                                    height: 'auto',
                                    maxWidth: '100%',
                                    width: '100%',
                                    //width: '100px',
                                  }}
                                  value={e.barCode}
                                  viewBox={`0 0 256 256`}
                                />
                                <h3>Scan the code</h3>
                                <p>Scan the QR code at the venue</p>
                              </div>
                            ))}
                            {ticketData?.DATA?.confirmationCode && (
                              <div className="barcode-row" style={{ marginBottom: '30px' }}>
                                {/* <img src={qrCode} alt='qrCode' /> */}
                                <QRCode
                                  size={256}
                                  style={{
                                    height: 'auto',
                                    maxWidth: '100%',
                                    width: '100%',
                                    // width: '100px',
                                  }}
                                  value={ticketData?.DATA?.confirmationCode}
                                  viewBox={`0 0 256 256`}
                                />
                                <h3>Scan the code</h3>
                                <p>Scan the QR code at the venue</p>
                              </div>
                            )}
                            {/* <div className='text-center mt-3'>
                                                            <Button className='applewallet-btn' type='primary' ghost>Add to Apple Wallet</Button>
                                                        </div> */}
                          </div>
                        </div>
                        <div className="download-row" data-aos="fade-left" data-aos-duration="1250">
                          {type !== 'discover' && ticketData?.DATA.isCancellable && (
                            <Button
                              className="cancel-btn"
                              type="primary"
                              ghost
                              onClick={handleCancel}
                            >
                              Cancel Ticket
                            </Button>
                          )}
                          {type === 'discover' && (
                            <Button
                              className="cancel-btn"
                              type="primary"
                              ghost
                              onClick={handleCancel}
                            >
                              Cancel Ticket
                            </Button>
                          )}
                          <Button className="pay-btn" type="primary" block onClick={handleDownload}>
                            Download Ticket
                            <SvgIcon name="download" viewbox="0 0 15.624 15.996" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MyBookingDetails;

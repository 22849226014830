import { Card } from 'antd';
import React from 'react';
import './index.scss';
import { SvgIcon } from '../index';

export function CityItemCard(props) {
  const { item, onClick } = props;

  return (
    <div style={{ position: 'relative' }}>
      <Card
        className="city-item-card"
        key={item.productId}
        cover={
          <>
            <img
              alt="City List"
              className={`${item.isSelected ? `selected` : ``}`}
              src={item.city_banner}
            />
            {item.isSelected && (
              <span className="check-icon">
                <SvgIcon name="check" viewbox="0 0 10.289 9.742" fill="#fff" />
              </span>
            )}
          </>
        }
        onClick={onClick}
      >
        <div className="bottom-row">
          <p className={`${item.isSelected ? `selected` : ``}`}>{item.vCityName}</p>
        </div>
      </Card>
    </div>
  );
}

import React, { useState } from 'react';
import { FloatLabel } from '../../components/common';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import './index.scss';

import userImg from '../../assets/images/app-logo.png';
import apiClient from '../../apiConfig';
import CustomLoader from '../../components/common/Loader/CustomLoader';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../slice/userSlice';
import { Alert } from '../../common/alert';

const dateFormat = 'YYYY-MM-DD';

const ProfileSettingTab = () => {
  const generateNameFromEmail = (email) => {
    const parts = email?.split('@');
    if (!parts || parts.length < 2) {
      return ''; // or any default value you prefer
    }
    const username = parts[0];
    // Capitalize the first letter of each word
    return username
      .split('.')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const [name, setName] = useState(
    user?.vUserName ? user.vUserName : generateNameFromEmail(user?.vEmail),
  );
  const [email, setEmail] = useState(user?.vEmail);
  const [mobile, setMobile] = useState(` ${user?.vMobileNo}`);

  const [dob, setDob] = useState(
    user?.dBirthdate && user?.dBirthdate !== '' ? user?.dBirthdate : null,
  );
  const [gender, setGender] = useState(user?.eGender);
  const [maritalStatus, setmaritalStatus] = useState(user?.eMaritalStatus);
  const [minimumSpend, setminimumSpend] = useState(user?.tCurrency);
  const [showLoader, setshowLoader] = useState(false);

  // console.log((gender, "GENDER"));
  const handleSubmit = async () => {
    if (name && name.trim().length === 0) {
      Alert.error('Invalid name');
      return;
    }
    const countryCode = user?.vMobileCountryCode;
    if (
      countryCode &&
      mobile &&
      mobile.match(/[0-9]+/) &&
      ((countryCode === '+971' && mobile.length !== 9) ||
        (countryCode === '+968' && mobile.length !== 8) ||
        (countryCode !== '+971' && countryCode !== '+968' && mobile.length !== 10))
    ) {
      Alert.error('Invalid phone number.');
      return;
    }

    setshowLoader(true);
    try {
      let response = await apiClient.post('/user/editProfile', {
        vEmail: email,
        dBirthdate: dob,
        vMobileNo: mobile,
        // "vCountry": "1990-04-19",
        eGender: gender,
        eMaritalStatus: maritalStatus,
        vUserName: name,
      });
      if (response.data.status === 1) {
        try {
          const res = await apiClient.get('/user/setting');
          if (res.data.status === 1) {
            dispatch(setUser(res.data.DATA));
          }
        } catch (error) {
          if (error.response.status) {
            // localStorage.removeItem("userData");
            // localStorage.removeItem("selectedCity");
          }
        }
      }
    } catch (error) {
      // console.log((error));
    }

    setshowLoader(false);
  };

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      const formdata = new FormData();
      formdata.append('uProfilePicture', file);

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const response = await apiClient.post('/user/uploadProfilePicture', formdata, config);
      if (response.data.status === 1) {
        dispatch(setUser({ ...user, uProfilePicture: response.data.DATA.uProfilePicture }));
      }
    } catch (error) {
      // console.log((error));
    }
  };

  return (
    <div className="right-tab-inner">
      {showLoader && <CustomLoader />}
      <h1 className="tab-heading">Profile Setting</h1>
      <div className="tab-content profile-setting" data-aos="fade-in" data-aos-duration="800">
        <Form name="basic" autoComplete="off" layout="vertical">
          <div className="change-profile-row">
            <div className="profile-img">
              <img src={user?.uProfilePicture || userImg} alt="" />
            </div>
            <input
              type="file"
              id="PF-PHOTO"
              accept="image/*"
              onChange={handleFileChange}
              style={{ opacity: '0', position: 'absolute', left: '-120px' }}
            />
            <label type="text" htmlFor="PF-PHOTO" style={{ cursor: 'pointer' }}>
              Change Photo
            </label>
          </div>
          <Form.Item className="icon-less">
            <FloatLabel label="Name" name="name" value={name}>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less">
            <FloatLabel label="Email" name="email" value={email}>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} disabled />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less">
            <FloatLabel label="Mobile" name="mobile" value={mobile}>
              <Input
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less">
            <FloatLabel label="Date of Birth" name="dob" value={dob}>
              <DatePicker
                placeholder=""
                value={dob ? dayjs(dob, dateFormat) : ''}
                onChange={(e, V) => setDob(V)}
                format={dateFormat}
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less gender-font">
            <FloatLabel label="Gender" name="gender" value={gender}>
              <Select
                value={gender}
                onChange={(val) => setGender(val)}
                suffixIcon={false}
                options={[
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' },
                ]}
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less">
            <FloatLabel label="Marital Status" name="maritalStatus" value={maritalStatus}>
              <Select
                value={maritalStatus}
                onChange={(val) => setmaritalStatus(val)}
                suffixIcon={false}
                options={[
                  { value: 'single', label: 'Single' },
                  { value: 'married', label: 'Married' },
                ]}
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less">
            <FloatLabel label="Minimum Spend" name="minimumSpend" value={minimumSpend}>
              <Input
                value={minimumSpend}
                onChange={(e) => setminimumSpend(e.target.value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="m-0 py-3">
            <Button type="secondary" htmlType="submit" onClick={handleSubmit}>
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ProfileSettingTab;

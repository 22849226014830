import store from '../store';

export const formatCurrency = (amount) => {
  const num = Number(amount);
  if (!Number.isNaN(num) && Number.isFinite(num)) {
    let fractionDigits = 2;
    const currency = store.getState().currency.selectedCurrency?.uCurrency;
    if (
      currency &&
      (currency === 'BHD' ||
        currency === 'JOD' ||
        currency === 'KWD' ||
        currency === 'LYD' ||
        currency === 'OMR' ||
        currency === 'TND')
    ) {
      fractionDigits = 3;
    }
    return (Math.floor(num * 100) / 100).toFixed(fractionDigits);
  }
  return num;
};

export const toCamelCase = (s) => {
  if (!s) return s;
  return s
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

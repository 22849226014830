import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Container, DownloadSection, Row, SvgIcon } from '../../../components/common';
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  Modal,
  Popover,
  Select,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { Gallery } from 'react-grid-gallery';
import Slider from 'react-slick';
import '../../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import 'react-calendar/dist/Calendar.css';
import ReviewImg1 from '../../../assets/images/top-creator2.png';
import ReviewImg2 from '../../../assets/images/top-creator3.png';
import ReviewImg3 from '../../../assets/images/top-creator4.png';
import ReviewImg4 from '../../../assets/images/top-creator5.png';
import ReviewImg5 from '../../../assets/images/top-creator6.png';
import ReviewImg6 from '../../../assets/images/top-creator7.png';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import apiClient from '../../../apiConfig';
import Apis from '../../../utility/apis';
import { useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import { addDays, endOfMonth, format, parseISO, startOfMonth } from 'date-fns';
import BookingErrorModal from '../RaynaDetailsPage/BookingErrorModal';
import { debounce } from 'lodash';
import { formatCurrency, toCamelCase } from '../../../utility/formatter';
import { useLoaderContext } from '../../../components/common/Loader/LoaderContext';
import { htmlToList } from '../../../utility/parser';
import dayjs from 'dayjs';
import Zoom from 'react-medium-image-zoom';
import { useDetailsLayoutContext } from '../../../layouts/defaultdetails';
import { Alert } from '../../../common/alert';
import { useIsVisible } from '../../../hooks/useIsVisible';

const QaModal2 = ({
  selectedOption,
  counts,
  setshowQa,
  showQa,
  questionAnsArrRef,
  handleRedirect,
  createPayload,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const showModal = () => {
    setshowQa(true);
  };

  const handleOk = () => {
    setshowQa(false);
  };

  const handleCancel = () => {
    setshowQa(false);
  };

  const handleChange = (val, index, qid) => {
    questionAnsArr.map((e) => {
      if (e.index === index && e.qid === qid) {
        e.answer = val;
      }
    });
  };

  const handleSubmitFaq = () => {
    const allAnswersFilled = questionAnsArr.every((question) => {
      return question.optional || question.answer.trim() !== '';
    });

    // console.log((questionAnsArr, "questionAnsArr"));
    questionAnsArrRef.current = questionAnsArr;
    if (allAnswersFilled) {
      setIsError(false);
      createPayload();
      handleRedirect();
    } else {
      setIsError(true);
    }
  };

  const renderComponents = [];
  // Iterate over the keys of the object
  let questionAnsArr = [];
  // console.log(("selectComponents", questionAnsArr));

  Object.keys(counts).forEach((key, index) => {
    // Get the value for the current key
    const value = counts[key];

    // Push a Select component to the array for each value
    for (let i = 0; i < value; i++) {
      renderComponents.push(
        <div>
          <h2>
            {key} {i + 1}{' '}
          </h2>
          {selectedOption?.pQuestions &&
            selectedOption?.pQuestions.map((e, qid) => {
              questionAnsArr = [
                ...questionAnsArr,
                {
                  question: e.question,
                  answer: '',
                  id: e.id,
                  index: index,
                  key: i,
                  ticketIndex: index + i,
                  qid: qid,
                  optional: e.optional,
                },
              ];

              return (
                <>
                  {e.type === 'FREETEXT' && (
                    <Form.Item label={`${e.question}${!e.optional ? '*' : ''}`}>
                      {/* <div className="small-label">Against COVID-19</div> */}
                      <Input
                        placeholder={e.question}
                        onChange={(eve) => handleChange(eve.target.value, index, qid)}
                      />
                    </Form.Item>
                  )}

                  {e.type === 'OPTION' && (
                    <Form.Item label={`${e.question}${!e.optional ? '*' : ''}`}>
                      {/* <div className="small-label">Against COVID-19</div> */}
                      <Select
                        placeholder="Select option"
                        popupClassName="qamodal-drop"
                        onChange={(val) => handleChange(val, index, qid)}
                        suffixIcon={<SvgIcon name="down-arrow" viewbox="0 0 18 9" />}
                        options={e.options.map((q) => ({
                          value: q,
                          label: q,
                        }))}
                      />
                    </Form.Item>
                  )}
                  {e.type === 'DATE' && (
                    <Form.Item label={`${e.question}${!e.optional ? '*' : ''}`}>
                      <DatePicker
                        onChange={(EVE, val) => handleChange(val, index, qid)}
                        popupClassName="pickdate-drop"
                        icon={false}
                        suffixIcon={false}
                        placeholder="DD / MM / YYYY"
                      />
                    </Form.Item>
                  )}
                </>
              );
            })}
          {/* <Form.Item label="Your nationality?">
          <Select
            placeholder="Select option"
            popupClassName="qamodal-drop"
            suffixIcon={<SvgIcon name="down-arrow" viewbox="0 0 18 9" />}
            options={[
              {
                value: "yn1",
                label: "United Emirates Arab",
              },
              {
                value: "yn2",
                label: "India",
              },
              {
                value: "yn3",
                label: " USA",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="Your Date of Birth?">
          <DatePicker
            popupClassName="pickdate-drop"
            icon={false}
            suffixIcon={false}
            placeholder="DD / MM / YYYY"
          />
        </Form.Item> */}
        </div>,
      );
    }
  });
  return (
    <>
      <Modal
        centered
        title="Q & A"
        footer={false}
        className="qa-modal"
        open={true}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <Form name="basic" autoComplete="off" layout="vertical">
            {renderComponents}
            {isError && (
              <div style={{ marginBottom: '10px', color: 'red' }}>
                Please fill all the required fields
              </div>
            )}
            <Button
              type="primary"
              onClick={() => {
                handleSubmitFaq();
              }}
            >
              Book Now
            </Button>
          </Form>
        </div>
      </Modal>
    </>
  );
};

const reviewerData = [
  {
    key: 1,
    image: ReviewImg1,
    title: 'Awesome Place!!!!👌',
    description: 'Customers will experience a complete photography trip in London.',
    name: 'Jerry123',
    date: '20th Mar 2022',
  },
  {
    key: 2,
    image: ReviewImg2,
    title: 'Such a heaven Place 🔥',
    description: 'Customers will experience a complete photography trip in London.',
    name: 'Jerry123',
    date: '20th Mar 2022',
  },
  {
    key: 3,
    image: ReviewImg3,
    title: 'Such a heaven Place 🔥',
    description: 'Customers will experience a complete photography trip in London.',
    name: 'Jerry123',
    date: '20th Mar 2022',
  },
  {
    key: 4,
    image: ReviewImg4,
    title: 'Such a heaven Place 🔥',
    description: 'Customers will experience a complete photography trip in London.',
    name: 'Jerry123',
    date: '20th Mar 2022',
  },
  {
    key: 5,
    image: ReviewImg5,
    title: 'Ambience is so beautifulllll',
    description: 'Customers will experience a complete photography trip in London.',
    name: 'Jerry123',
    date: '20th Mar 2022',
  },
  {
    key: 6,
    image: ReviewImg6,
    title: 'Ambience is so beautifulllll',
    description: 'Customers will experience a complete photography trip in London.',
    name: 'Jerry123',
    date: '20th Mar 2022',
  },
];

const OutsideClickHandler = ({ onOutsideClick, children }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onOutsideClick();
    }
  };

  return <div ref={wrapperRef}>{children}</div>;
};

const DetailsPage = () => {
  const [counts, setCounts] = useState({
    // adult: 1,
    // child: 0,
    // perPax: 0,
  });

  const handleIncrement = (type) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [type]: prevCounts[type] + 1,
    }));
  };

  const handleDecrement = (type) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [type]: Math.max(prevCounts[type] - 1, 0),
    }));
  };

  const { setShowLoader } = useLoaderContext();
  const { setShowDetailsFooter } = useDetailsLayoutContext();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  const [tourDetails, setTourDetails] = useState(null);
  const [showAllImages, setShowAllImages] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [descriptionLines, setDescriptionLines] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const productId = searchParams.get('productId');
  // console.log(("productId: ", productId));

  const userData = useSelector((state) => state.user.user);
  const selectedCity = useSelector((state) => state.countryCity.selectedCity);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowLoader(true);
        setShowDetailsFooter(false);
        const response = await apiClient.post(
          // Fetch data from API
          Apis('tourDetails', 'OTHERS', userData ? 'loggedIn' : 'guest'),
          {
            iCountryID: selectedCity.iCountryID,
            dCurrentLat: selectedCity.vCityLatitude,
            dCurrentLong: selectedCity.vCityLongitude,
            productId: productId,
          },
        );

        // Set the fetched tour details to the state
        setTourDetails(response.data?.DATA || null);
        // console.log(("response tourDetails: ", response));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setShowLoader(false);
        setShowDetailsFooter(true);
      }
    };

    fetchData();
  }, []);

  const [selectedOption, setSelectedOption] = useState(null);
  // // console.log(((selectedOption, "selectedOptionselectedOption")));
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  // Define state variables for availability data and disabled dates
  const [availabilityData, setAvailabilityData] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [discoverOptions, setDiscoverOptions] = useState(null);
  const [availabilityTimes, setAvailabilityTimes] = useState([]);
  const [selectedavailabilityTimes, setselectedAvailabilityTimes] = useState();
  const [showAll, setShowAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const tourOptionsRef = useRef();
  const tourOptionsVisible = useIsVisible(tourOptionsRef);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (!tourOptionsVisible && !selectedOption) {
      setShowBtn(true);
      return;
    }

    // Check if any value is greater than 1
    const hasGreaterThanOne = Object.values(counts).some((count) => count > 0);
    if (
      hasGreaterThanOne &&
      selectedavailabilityTimes &&
      (!selectedOption.pTimeSlot ? true : Boolean(selectedTimeSlot))
    ) {
      // if (selectedTimeSlot) {
      setShowBtn(true);
      // }
      // else {
      // setShowBtn(false)
      // }
    } else {
      setShowBtn(false);
    }
  }, [counts, selectedavailabilityTimes, selectedOption, selectedTimeSlot, tourOptionsVisible]);

  useEffect(() => {
    const fetchData = async () => {
      setShowLoader(true);
      try {
        const response = await apiClient.post('/globaltix/productOptions', {
          productId: productId,
        });

        // Set the fetched tour details to the state
        const updatedResponse = response.data?.DATA.map((obj) => ({
          ...obj,
          isChecked: false,
        }));
        setDiscoverOptions(updatedResponse);
        // console.log(("response tourDetails: ", response));

        // Get the current date and format it as "yyyy-MM-dd"
        const currentDate = format(new Date(), 'yyyy-MM-dd');
        const endDateOfMonth = format(endOfMonth(new Date()), 'yyyy-MM-dd');
        const pTicketTypeID = response.data?.DATA[0]?.pTicketType[0]?.pTicketTypeID;

        // if (pTicketTypeID) {
        //   // Fetch productAvailability using pTicketTypeID
        //   const availabilityResponse = await apiClient.post(
        //     "/globaltix/productAvailability",
        //     {
        //       pTicketTypeID: pTicketTypeID,
        //       dateFrom: currentDate,
        //       dateTo: endDateOfMonth,
        //     }
        //   );
        //   // Set the availability data to state
        //   setAvailabilityData(availabilityResponse.data.DATA);

        //   const availabilityTimes = availabilityResponse.data.DATA.map(
        //     (item) => {
        //       const timeParts = item.time.split("T"); // Splitting the string at 'T'
        //       return timeParts[1]; // Extracting the time part
        //     }
        //   );
        //   setAvailabilityTimes(availabilityTimes); // Update the availabilityTimes state

        //   // Extract disabled dates
        //   const disabled = availabilityResponse.data.DATA.map(
        //     (item) => new Date(item.time)
        //   );
        //   setDisabledDates(disabled);
        //   // Handle the productAvailability response
        //   // console.log(("productAvailability: ", availabilityResponse.data.DATA));
        // } else {
        //   console.error(
        //     "Error: pTicketTypeID not found in productOptions response."
        //   );
        // }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setShowLoader(false);
      }
    };

    if (userData) {
      fetchData();
    }
  }, [userData]);
  // console.log((availabilityData, "availabilityTimesss"));

  // Function to check if a date is disabled
  const isDateDisabled = (date) => {
    const today = new Date();
    // Set hours, minutes, seconds, and milliseconds to 0 to compare dates without time
    today.setHours(0, 0, 0, 0);
    // Disable dates before today
    if (date < today) {
      return true;
    }
    if (availabilityData.length === 0) return true;
    const enabledDates = availabilityData.map((date) => new Date(date.time));
    // console.log((availabilityData, "disabledddd"));
    return !enabledDates.some(
      (enabledDate) => format(enabledDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'),
    );

    // Check if the date exists in disabledDates array
    return (
      disabledDates.findIndex((d) => format(d, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')) === -1
    );
  };

  const productOPtionId =
    discoverOptions && discoverOptions.length > 0 ? discoverOptions[0].productOPtionId : null;

  // You can then use productOptionId in your component logic as needed
  // console.log(("productOptionId:", productOPtionId));

  // Function to handle checkbox click
  const handleCheckboxClick = async (option, index) => {
    setShowLoader(true);
    setAvailabilityData([]);
    const updatedData = discoverOptions.map((opt, idx) => {
      if (idx === index) {
        return {
          ...opt,
          isChecked: !opt.isChecked,
        };
      } else {
        return {
          ...opt,
          isChecked: false,
        };
      }
    });
    setDiscoverOptions(updatedData);
    setSelectedOption(option);
    setSelectedDate();
    let cnt = {};
    option.pTicketType.map((e, index) => (cnt[e.ticketTypename] = index === 0 ? 1 : 0));
    setCounts(cnt);
    setSelectedTimeSlot();
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    const endDateOfMonth = format(endOfMonth(new Date()), 'yyyy-MM-dd');
    const availabilityResponse = await apiClient.post('/globaltix/productAvailability', {
      pTicketTypeID: option.pTicketType[0].pTicketTypeID,
      dateFrom: currentDate,
      dateTo: endDateOfMonth,
    });
    if (availabilityResponse.data.status === 0) {
      const availabilityResponse2 = await apiClient.post('/globaltix/productAvailability', {
        pTicketTypeID: option.pTicketType[0].pTicketTypeID,
        dateFrom: format(addDays(currentDate, 1), 'yyyy-MM-dd'),
        dateTo: endDateOfMonth,
      });
      if (availabilityResponse2.data.status === 1) {
        setAvailabilityData(availabilityResponse2.data.DATA);
      } else {
        setShowModal(true);
        setErrorMessage(availabilityResponse2.data?.MESSAGE || 'Oops! Something went wrong.');
        const updatedData = discoverOptions.map((opt, idx) => {
          return {
            ...opt,
            isChecked: false,
          };
        });
        setDiscoverOptions(updatedData);
        setSelectedOption(null);
      }
    } else {
      setAvailabilityData(availabilityResponse.data.DATA);
    }

    setShowLoader(false);

    // if (showCalendar) {
    //   // If showCalendar is already true, close it
    //   setShowCalendar(false);
    // } else {
    //   // If showCalendar is false, open it
    //   setSelectedOption(option);
    //   setShowCalendar(true);
    // }
  };
  // Function to handle unchecking of checkbox
  const handleCheckboxUncheck = () => {
    setSelectedDate(null); // Clear the selectedDate when the checkbox is unchecked
  };

  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [reducedBy, setreducedBy] = useState(0);

  const handlePromoCodeChange = (e) => {
    // console.log(("Entered Promo Code:", promoCodeValue));
    setPromoCode(e.target.value);
    debouncedHandleChange(e.target.value);
  };

  const debouncedHandleChange = useCallback(
    debounce(async (value) => {
      // Dispatch the action to update the Redux state
      const response = await apiClient.post('/discover/checkPromoCode', {
        tPromoCode: value,
        tPromoProductId: productId,
        tSource: 'Discover',
      });
      if (response.data.status === 1) {
        setPromoCodeValue(response.data.DATA);
      } else {
        setPromoCodeValue(null);
      }
    }, 1000), // Adjust the debounce delay as needed (e.g., 300 milliseconds)
    [],
  );

  const [bookingData, setBookingData] = useState(null);

  // Function to fetch booking data
  const fetchBookingData = async () => {
    setShowLoader(true);
    try {
      const response = await apiClient.post('/globaltix/productBookingData', {
        productId: productId,
        productOPtionId: selectedOption.productOPtionId,
      });
      setBookingData(response.data?.DATA || null);
      if (response.data.status === 0) {
        setShowModal(true);
        setErrorMessage(
          response.data?.MESSAGE ||
            'You cannot book this tour on selected date due to cutoff time.',
        );
      }
      setDropdownVisible2(false);
    } catch (error) {
      console.error('Error fetching booking data:', error);
    }
    setShowLoader(false);
  };

  // Function to handle calendar change
  const handleCalendarChange = (date) => {
    const offset2 = date.getTimezoneOffset() * 60000;
    const localDate2 = new Date(date.getTime() - offset2);
    const formattedDate3 = localDate2.toISOString().slice(0, 10);
    setSelectedDate(date);
    setShowCalendar(false);
    const filteredObj = availabilityData.find((obj) => obj.time.split('T')[0] === formattedDate3);
    setselectedAvailabilityTimes(filteredObj);
    // Get the local time zone offset
    const offset = date.getTimezoneOffset() * 60000;

    // Adjust the date to the local time zone
    const localDate = new Date(date.getTime() - offset);

    // Format the date as "YYYY-MM-DD"
    const formattedDate = localDate.toISOString().slice(0, 10);

    // Fetch booking data if the date is enabled
    if (selectedOption) {
      fetchBookingData(selectedOption.tourOptionId, formattedDate);
    }
  };

  const handleOutsideClick = () => {
    setShowCalendar(false);
  };

  // const pTicketTypeID =
  //   bookingData && bookingData.pTicketType && bookingData.pTicketType.length > 0
  //     ? bookingData.pTicketType[0].id
  //     : null;

  // // console.log(("bookingData pTicketTypeID: ", pTicketTypeID));

  const [errorMessage, setErrorMessage] = useState('');
  const currencyData = JSON.parse(localStorage.getItem('selectedCurrency'));
  const questionAnsArrRef = useRef([]);

  const createPayload = () => {
    let arr = [];
    let pTicketTypeIdDetails;
    Object.keys(counts).map((count, key) => {
      for (let index = 0; index < counts[count]; index++) {
        arr.push({
          // "event_id": selectedavailabilityTimes.event_id ? selectedavailabilityTimes.event_id : 0,
          event_id: selectedTimeSlot
            ? availabilityData.find(
                (item) =>
                  item.time ===
                  `${format(parseISO(selectedavailabilityTimes.time), 'yyyy-MM-dd')}T${selectedTimeSlot}:00`,
              )?.id ?? 0
            : 0,
          fromResellerId: '',
          id: selectedOption.pTicketType.find((e) => e.ticketTypename === count).pTicketTypeID,
          packageItems: [],
          quantity: counts[count],
          questionList: questionAnsArrRef.current.filter((e) => e.index === key && e.key === index),
          sellingPrice: Number(
            selectedOption.pTicketType.find((e) => e.ticketTypename === count).nettPrice,
          ),
          typeName: count,
          visitDate: !selectedTimeSlot
            ? selectedavailabilityTimes.time
            : `${format(parseISO(selectedavailabilityTimes.time), 'yyyy-MM-dd')}T${selectedTimeSlot}:00`,
          visitDateSettings: [],
        });
      }
      return [...arr];
    });
    pTicketTypeIdDetails = arr;
    let payload = {
      productId,
      productOPtionId: selectedOption.productOPtionId,
      visitDate: !selectedTimeSlot
        ? selectedavailabilityTimes.time
        : `${format(parseISO(selectedavailabilityTimes.time), 'yyyy-MM-dd')}T${selectedTimeSlot}:00`,
      pTicketTypeIdDetails: pTicketTypeIdDetails,
      // eventId: selectedavailabilityTimes.event_id ? selectedavailabilityTimes.event_id : '0',
      eventId: selectedTimeSlot
        ? availabilityData.find(
            (item) =>
              item.time ===
              `${format(parseISO(selectedavailabilityTimes.time), 'yyyy-MM-dd')}T${selectedTimeSlot}:00`,
          )?.id ?? 0
        : 0,
      available: selectedavailabilityTimes.available,
    };

    localStorage.setItem('payload', JSON.stringify(payload));
  };

  const handleRedirect = () => {
    const offset = selectedDate.getTimezoneOffset() * 60000;
    const localDate = new Date(selectedDate.getTime() - offset);
    const formattedDate = localDate.toISOString().slice(0, 10);
    let total = 0;

    for (const key in counts) {
      if (Object.hasOwnProperty.call(counts, key)) {
        total += counts[key];
      }
    }
    const itemCounts = Object.keys(counts).reduce((obj, key) => {
      const ticketType = selectedOption.pTicketType.find((el) => el.ticketTypename === key);
      return {
        ...obj,
        [key]: { price: Number(ticketType?.nettPrice), count: counts[key] },
      };
    }, {});

    history.push(
      `/discover/checkout?productOPtionId=${selectedOption.productOPtionId}&productId=${productId}&countryType=global`,
      {
        itemCounts: itemCounts,
        ...(promoCodeValue && { promo: { ...promoCodeValue, tPromoCode: promoCode } }),
      },
    );
  };
  const handleBookNow = () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const urlWithoutDomain = url.pathname + url.search;
    // console.log(("URL without domain:", urlWithoutDomain));
    if (!userData) {
      history.push(`/login?redirect_to=${urlWithoutDomain}`);
      return;
    }
    if (!tourOptionsVisible && !selectedOption) {
      setTimeout(() => {
        tourOptionsRef.current?.scrollIntoView(true);
      }, 150);

      return;
    }

    if (
      selectedOption.pQuestions &&
      !selectedOption.pQuestions.every((item) => item.optional === true)
    ) {
      setshowQa(true);
    } else {
      createPayload();
      handleRedirect();
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownVisible2, setDropdownVisible2] = useState(false);
  const [totalPrice, settotalPrice] = useState();

  const showDrawer = () => {
    setShowAllImages(true);
  };

  const onClose = () => {
    setShowAllImages(false);
  };

  // const calculateTotalPrice = (adultCount, childCount, productOptions) => {
  //   if (
  //     !productOptions ||
  //     !Array.isArray(productOptions) ||
  //     productOptions.length === 0
  //   ) {
  //     return 0;
  //   }

  //   const adultPrice =
  //     parseFloat(productOptions[0]?.pTicketType[0]?.nettPrice) || 0;
  //   const childPrice = adultPrice; // Assuming child price is the same as adult price, modify this if needed

  //   const totalAdultPrice = adultCount * adultPrice;
  //   const totalChildPrice = childCount * childPrice;

  //   const grandTotal = totalAdultPrice + totalChildPrice;

  //   return grandTotal;
  // };
  useEffect(() => {
    calculateTotalPrice2();
    return () => {};
  }, [counts, selectedOption, promoCodeValue]);

  const calculateTotalPrice2 = () => {
    if (!selectedOption) {
      settotalPrice(0);
      return 0;
    }

    const perTicketTypePrice = Object.keys(counts).reduce((obj, key) => {
      const ticketType = selectedOption.pTicketType.find((el) => el.ticketTypename === key);
      return {
        ...obj,
        [key]: Number(ticketType?.nettPrice),
      };
    }, {});

    let grandTotal = Object.keys(perTicketTypePrice).reduce((total, key) => {
      return total + counts[key] * perTicketTypePrice[key];
    }, 0);

    if (promoCodeValue) {
      Object.keys(perTicketTypePrice).forEach((key) => {
        let discount = perTicketTypePrice[key] * (promoCodeValue.tPromoDiscount / 100);
        if (discount > promoCodeValue.tMaxDiscount) {
          discount = promoCodeValue.tMaxDiscount;
        }
        perTicketTypePrice[key] = perTicketTypePrice[key] - discount;
      });

      let discountedGrandTotal = Object.keys(perTicketTypePrice).reduce((total, key) => {
        return total + counts[key] * perTicketTypePrice[key];
      }, 0);

      setreducedBy(grandTotal - discountedGrandTotal);
    } else {
      setreducedBy(0);
    }

    settotalPrice(grandTotal);

    return grandTotal;
  };
  // const grandTotal = calculateTotalPrice(
  //   counts.adult,
  //   counts.child,
  //   discoverOptions
  // );
  // calculateTotalPrice2()
  // const selectedData = `${counts.adult} Adult${counts.adult > 1 ? "s" : ""}, ${counts.child
  //   } Child${counts.child > 1 ? "ren" : ""}, ${counts.perPax} perPax${counts.perPax > 1 ? "s" : ""
  //   }`;

  function convertDataToString(data) {
    let stringArray = [];
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const value = data[key];
        stringArray.push(`${value} ${toCamelCase(key)}`);
      }
    }
    return stringArray.join(', ');
  }

  const selectedData = convertDataToString(counts);

  const ticketTypes = discoverOptions
    ? discoverOptions.reduce((types, option) => {
        const optionTicketTypes = option.pTicketType.map((ticketType) => ticketType.ticketTypename);
        return types.concat(optionTicketTypes);
      }, [])
    : [];

  // const items = ticketTypes.map((ticketType, index) => ({
  //   key: index.toString(),
  //   label: (
  //     <div className="participants-select">
  //       <h3>Participants</h3>
  //       <div className="participants-row">
  //         <div className="left-col">
  //           <h4>{ticketType}</h4>
  //           <label>
  //             {ticketType === "Per Pax"
  //               ? "Under 2"
  //               : ticketType === "Adult"
  //                 ? "Age 13+"
  //                 : "Ages 2-12"}
  //           </label>
  //         </div>
  //         <div className="plusminus">
  //           <Button onClick={() => handleDecrement(ticketType)}> - </Button>
  //           <div className="counts">{counts[ticketType]}</div>
  //           <Button onClick={() => handleIncrement(ticketType)}> + </Button>
  //         </div>
  //       </div>
  //     </div>
  //   ),
  // }));

  // console.log((counts, "asddsaf"));
  const items = [
    {
      key: '1',
      label: (
        <div className="participants-select">
          <h3>Participants</h3>
          {selectedOption?.pTicketType.map((e) => (
            <div className="participants-row">
              <div className="left-col">
                <h4>{toCamelCase(e.ticketTypename)}</h4>
                {/* <label>Age 13+</label> */}
              </div>
              <div className="plusminus">
                <Button onClick={() => handleDecrement(e.ticketTypename)}>
                  <SvgIcon name="minus" viewbox="0 0 16.638 2.405" />
                </Button>
                <div className="counts">{counts[e.ticketTypename]}</div>
                <Button onClick={() => handleIncrement(e.ticketTypename)}>
                  <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
                </Button>
              </div>
            </div>
          ))}
          {/* <div className="participants-row">
            <div className="left-col">
              <h4>Children</h4>
              <label>Ages 2-12</label>
            </div>
            <div className="plusminus">
              <Button onClick={() => handleDecrement("child")}>
                <SvgIcon name="minus" viewbox="0 0 16.638 2.405" />
              </Button>
              <div className="counts">{counts.child}</div>
              <Button onClick={() => handleIncrement("child")}>
                <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
              </Button>
            </div>
          </div> */}
          {/* <div className="participants-row">
            <div className="left-col">
              <h4>Infants</h4>
              <label>Under 2</label>
            </div>
            <div className="plusminus">
              <Button onClick={() => handleDecrement("infant")}>
                <SvgIcon name="minus" viewbox="0 0 16.638 2.405" />
              </Button>
              <div className="counts">{counts.infant}</div>
              <Button onClick={() => handleIncrement("infant")}>
                <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
              </Button>
            </div>
          </div> */}
        </div>
      ),
    },
  ];

  const detailSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleDateChange = (date, dateString) => {
    // console.log(("Selected Date:", dateString));
  };

  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [prevMonthStart, setPrevMonthStart] = useState(null);
  const [prevMonthEnd, setPrevMonthEnd] = useState(null);
  const [showQa, setshowQa] = useState(false);

  const handleMonthChange = async ({ activeStartDate }) => {
    try {
      setShowLoader(true);
      // console.log(("Month changed to:", activeStartDate));

      const startDate = format(startOfMonth(activeStartDate), 'yyyy-MM-dd');
      const endDate = format(endOfMonth(activeStartDate), 'yyyy-MM-dd');

      setActiveStartDate(activeStartDate);

      let fetchStartDate = startDate;
      let fetchEndDate = endDate;

      // Check if the user has moved back to the current month
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1; // Month is zero-indexed

      const activeYear = activeStartDate.getFullYear();
      const activeMonth = activeStartDate.getMonth() + 1; // Month is zero-indexed

      if (activeYear === currentYear && activeMonth === currentMonth) {
        // If the user has moved back to the current month, use the current date and end date
        fetchStartDate = format(today, 'yyyy-MM-dd');
        fetchEndDate = format(endOfMonth(today), 'yyyy-MM-dd');
      }

      const availabilityResponse = await apiClient.post('/globaltix/productAvailability', {
        pTicketTypeID: selectedOption.pTicketType[0].pTicketTypeID,
        dateFrom: fetchStartDate,
        dateTo: fetchEndDate,
      });

      // setAvailabilityData(availabilityResponse.data.DATA);

      setAvailabilityData(availabilityResponse.data?.DATA || []);
      setSelectedDate(null);
      setselectedAvailabilityTimes(null);

      const disabled = availabilityResponse.data.DATA.map((item) => new Date(item.time));
      // setDisabledDates(disabled);

      // console.log(("productAvailability:", availabilityResponse.data.DATA));

      // Extract available times from the response and set them in state
      const availableTimes = availabilityResponse.data.DATA.map((item) => item.time.split('T')[1]);
      setAvailabilityTimes(availableTimes);
      // Update previous month's start and end dates
      setPrevMonthStart(activeStartDate);
      setPrevMonthEnd(activeStartDate);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setShowLoader(false);
    }
  };
  const items2 = [
    {
      key: '1',
      // disabled:true,
      label: (
        <div className="participants-select planning-time">
          <Calendar
            onChange={handleCalendarChange}
            value={selectedDate}
            onActiveStartDateChange={handleMonthChange}
            tileDisabled={({ date }) => isDateDisabled(date)}
          />
        </div>
      ),
    },
  ];

  const handleFavToggle = (value, tourId) => {
    if (!userData) {
      window.location.href = `${window.location.origin}/login`;
    }
    setTourDetails((state) => {
      return { ...state, isFavouriteOffer: value };
    });
    apiClient.post(
      Apis('toggleFavTour', selectedCity.vCountryName, userData ? 'loggedIn' : 'guest'),
      {
        tourId,
        productId: tourId,
        favourite: value,
      },
    );
  };

  const copyUrl = () => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Copy the URL to the clipboard
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        Alert.success('URL copied to clipboard');
        // console.log(('URL copied to clipboard'));
      })
      .catch((error) => {
        console.error('Failed to copy URL: ', error);
      });
  };

  function convertToEmbedUrl(youtubeUrl) {
    const videoId = youtubeUrl.split('v=')[1];
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  }

  useEffect(() => {
    if (tourDetails && tourDetails.description) {
      const lines = htmlToList(tourDetails.description);
      if (showFullDescription) {
        setDescriptionLines(lines);
      } else {
        setDescriptionLines(lines.slice(0, 3));
      }
      if (lines.length < 2) {
        setShowFullDescription(true);
      }
    } else {
      setDescriptionLines([]);
    }
  }, [tourDetails, showFullDescription]);

  return (
    <div className="twl-details-wrapper">
      {showQa && (
        <QaModal2
          selectedOption={selectedOption}
          counts={counts}
          showQa={showQa}
          setshowQa={setshowQa}
          questionAnsArrRef={questionAnsArrRef}
          handleRedirect={handleRedirect}
          createPayload={createPayload}
        />
      )}

      {showModal && <BookingErrorModal setShowModal={setShowModal} errorMessage={errorMessage} />}

      {tourDetails && (
        <>
          <MediaQuery maxWidth={767}>
            <div className="details-mobile-header">
              <Link
                to="#"
                className="back-arrow"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (location.key) {
                    history.goBack();
                  } else {
                    history.replace('/');
                  }
                }}
              >
                <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
              </Link>
              <div className="right-action">
                <Button
                  type="link"
                  onClick={() => {
                    handleFavToggle(
                      tourDetails?.isFavouriteOffer === 0 ? 1 : 0,
                      tourDetails.tourId || tourDetails.productId,
                    );
                  }}
                >
                  {/* <SvgIcon name="heart-outline" viewbox="0 0 13.269 12.168" /> */}
                  <SvgIcon
                    name={tourDetails.isFavouriteOffer === 0 ? 'heart-outline' : 'heart'}
                    viewbox="0 0 10.238 9.131"
                    fill={tourDetails.isFavouriteOffer === 0 ? '#000' : '#FF5D5D'}
                  />
                </Button>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <div className="mobile-redeem-action">
              <div className="left-actions">
                <Button type="link" onClick={copyUrl}>
                  <SvgIcon name="share-icon" viewbox="0 0 10.314 11.942" />
                </Button>
                {/*<Button type="link">
                  <SvgIcon name="call-icon" viewbox="0 0 14.993 14.993" />
                </Button>*/}
              </div>
              <Button
                type="primary"
                onClick={handleBookNow}
                disabled={!showBtn && userData ? true : false}
              >
                Book Now
              </Button>
            </div>
          </MediaQuery>
          <section>
            <Drawer
              className="moreimages-drawer"
              height={'100vh'}
              placement="bottom"
              title={
                <div className="right-action">
                  <Button
                    type="link"
                    onClick={() => {
                      handleFavToggle(
                        tourDetails?.isFavouriteOffer === 0 ? 1 : 0,
                        tourDetails.tourId || tourDetails.productId,
                      );
                    }}
                  >
                    Favorite {/* <SvgIcon name="heart-outline" viewbox="0 0 13.269 12.168" /> */}
                    <SvgIcon
                      name={tourDetails.isFavouriteOffer === 0 ? 'heart-outline' : 'heart'}
                      viewbox="0 0 10.238 9.131"
                      fill={tourDetails.isFavouriteOffer === 0 ? '#000' : '#FF5D5D'}
                    />
                  </Button>
                  <Button type="link" onClick={copyUrl}>
                    Share <SvgIcon name="share-icon" viewbox="0 0 10.314 11.942" />
                  </Button>
                </div>
              }
              closeIcon={<SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />}
              onClose={onClose}
              open={showAllImages}
            >
              <Gallery
                images={
                  tourDetails &&
                  tourDetails.gProductImageList &&
                  tourDetails.gProductImageList.map((image, index) => ({
                    src: image,
                    alt: `Image ${index + 1}`,
                  }))
                }
                enableImageSelection={false}
              />
            </Drawer>
            <Container className="container-upper">
              {/* <MediaQuery minWidth={768}>
                <div className="details-images">
                  <div className="showphotos-btn" onClick={showDrawer}>
                    <span>10</span> SHOW PHOTOS
                  </div>
                  <div className="left-image">
                    <img src={DetailsImg1} alt="Experience London skyline" />
                  </div>
                  <div className="right-image">
                    <img src={DetailsImg2} alt="Experience London skyline" />
                    <img src={DetailsImg3} alt="Experience London skyline" />
                    <img src={DetailsImg4} alt="Experience London skyline" />
                    <img src={DetailsImg5} alt="Experience London skyline" />
                  </div>
                </div>
              </MediaQuery> */}
              {tourDetails &&
                tourDetails.gProductImageList &&
                tourDetails.gProductImageList.length > 1 && (
                  <MediaQuery minWidth={768}>
                    <div className="details-images">
                      {tourDetails.gProductImageList.length > 5 && (
                        <div className="showphotos-btn" onClick={showDrawer}>
                          <span>{tourDetails.gProductImageList.length}</span> SHOW PHOTOS
                        </div>
                      )}
                      <div className="left-image">
                        <Zoom>
                          <div className="square-image-container">
                            <img
                              src={tourDetails.gProductImageList[0]}
                              alt="Tour Image 1"
                              className="detail-img1"
                            />
                          </div>
                        </Zoom>
                      </div>
                      <div className="right-image">
                        {showAllImages
                          ? tourDetails.gProductImageList.map((image, index) => (
                              <Zoom key={index}>
                                <div className="square-image-container">
                                  <img
                                    src={image}
                                    alt={`Tour Image ${index + 1}`}
                                    className="detail-img-sub"
                                  />
                                </div>
                              </Zoom>
                            ))
                          : tourDetails.gProductImageList.slice(1, 5).map((image, index) => (
                              <Zoom key={index}>
                                <div className="square-image-container">
                                  <img
                                    src={image}
                                    alt={`Tour Image ${index + 2}`}
                                    className="detail-img-sub"
                                  />
                                </div>
                              </Zoom>
                            ))}
                      </div>
                    </div>
                  </MediaQuery>
                )}
              {/* <MediaQuery maxWidth={767}>
                <div className="mobile-details-slider">
                  <Slider {...detailSlider}>
                    <div>
                      <img src={DetailsImg1} alt="Experience London skyline" />
                    </div>
                    <div>
                      <img src={DetailsImg2} alt="Experience London skyline" />
                    </div>
                    <div>
                      <img src={DetailsImg3} alt="Experience London skyline" />
                    </div>
                    <div>
                      <img src={DetailsImg4} alt="Experience London skyline" />
                    </div>
                    <div>
                      <img src={DetailsImg5} alt="Experience London skyline" />
                    </div>
                  </Slider>
                </div>
              </MediaQuery> */}

              <MediaQuery maxWidth={767}>
                <div className="mobile-details-slider">
                  <Slider {...detailSlider}>
                    {tourDetails.gProductImageList &&
                      tourDetails.gProductImageList.map((image, index) => (
                        <div key={index}>
                          <img src={image} alt={`Experience London skyline ${index + 1}`} />
                        </div>
                      ))}
                  </Slider>
                </div>
              </MediaQuery>

              <div className="details-row">
                <div className="details-left">
                  {tourDetails &&
                    tourDetails.gProductImageList &&
                    tourDetails.gProductImageList.length === 1 && (
                      <MediaQuery minWidth={767}>
                        <div className="md-4">
                          <div className="square-image-container">
                            <img
                              src={tourDetails.gProductImageList[0]}
                              alt={`Tour Image`}
                              className="detail-img-sub"
                            />
                          </div>
                        </div>
                      </MediaQuery>
                    )}
                  <div className="dtl-upper">
                    <div className="dtl-upperleft">
                      {tourDetails.productRating && (
                        <div className="rating">
                          <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                          <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                          <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                          <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                          <SvgIcon name="star-outline" viewbox="0 0 15.999 16" />
                        </div>
                      )}
                      <h1>{tourDetails.productName}</h1>
                      <div className="location">
                        <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                        {tourDetails.areaName}
                      </div>
                    </div>
                    <MediaQuery minWidth={768}>
                      <div className="dtl-upperright">
                        <Button
                          type="link"
                          onClick={() => {
                            handleFavToggle(
                              tourDetails?.isFavouriteOffer === 0 ? 1 : 0,
                              tourDetails.tourId || tourDetails.productId,
                            );
                          }}
                        >
                          Favorite{' '}
                          {/* <SvgIcon
                            name="heart-outline"
                            viewbox="0 0 13.269 12.168"
                          /> */}
                          <SvgIcon
                            name={tourDetails.isFavouriteOffer === 0 ? 'heart-outline' : 'heart'}
                            viewbox="0 0 10.238 9.131"
                            fill={tourDetails.isFavouriteOffer === 0 ? '#000' : '#FF5D5D'}
                          />
                        </Button>
                        <Button type="link" onClick={copyUrl}>
                          Share <SvgIcon name="share-icon" viewbox="0 0 10.314 11.942" />
                        </Button>
                      </div>
                    </MediaQuery>
                  </div>
                  {descriptionLines.length > 0 && (
                    <div className="overview-row">
                      <h2>Overview</h2>
                      <div>
                        <div style={{ display: 'inline-block' }}>
                          {descriptionLines.map((text, index) => (
                            <p key={index}>{text}</p>
                          ))}
                        </div>
                        {!showFullDescription && (
                          <span>
                            <a onClick={toggleDescription} to="#" style={{ cursor: 'pointer' }}>
                              Read More
                            </a>
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {tourDetails.highlights && tourDetails.highlights.length !== 0 && (
                    <div className="highlights-row">
                      <h2>Highlights</h2>
                      <ul>
                        {tourDetails.highlights.map((e) => (
                          <li>
                            <div className="left-icon">
                              <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                            </div>
                            <div>{e}</div>
                          </li>
                        ))}
                        {/* <li>
                        <div className="left-icon">
                          <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                        </div>
                        <div>
                          Immerse yourself into discovery in the Observation
                          Deck on the 86th Floor
                        </div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                        </div>
                        <div>
                          Standing as one of best spots to view the city of
                          Kuala Lumpur
                        </div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                        </div>
                        <div>
                          Immerse yourself into discovery in the Observation
                          Deck on the 86th Floor
                        </div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                        </div>
                        <div>
                          Standing as one of best spots to view the city of
                          Kuala Lumpur
                        </div>
                      </li> */}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="details-right">
                  <div className="details-right-inner">
                    <Form name="basic" autoComplete="off" layout="vertical">
                      <div className="prive-details-form">
                        <div className="heading">
                          <h3>
                            <span>
                              {' '}
                              {discoverOptions?.length > 1 ? 'FROM' : ''} {currencyData?.uCurrency}
                              {formatCurrency(tourDetails.adultPrice)}{' '}
                            </span>
                            / Person
                          </h3>
                        </div>
                        {/* <div className="options-colum">
                          <h4>Options</h4>
                          <ul>
                            <li>
                              <Popover
                                placement="bottomLeft"
                                arrow={false}
                                overlayClassName="options-list-popover"
                                content={
                                  "The Tower of London, officially His Majesty's Royal Palace and Fortress of the Tower of London, is a historic castle on the north bank of the River Thames in central London, England"
                                }
                                title="Explore Magic Imagica"
                              >
                                <Checkbox>Explore Magic Imagica</Checkbox>
                              </Popover>
                              <div className="right-col">
                                <span className="off-price">AED 523</span>
                                $50 <span>/ Person</span>
                              </div>
                            </li>
                            <li>
                              <Popover
                                placement="bottomLeft"
                                arrow={false}
                                overlayClassName="options-list-popover"
                                content={
                                  "The Tower of London, officially His Majesty's Royal Palace and Fortress of the Tower of London, is a historic castle on the north bank of the River Thames in central London, England"
                                }
                                title="Tower Bridge"
                              >
                                <Checkbox>Tower Bridge</Checkbox>
                              </Popover>
                              <div className="right-col">
                                <span className="off-price">AED 523</span>
                                $50 <span>/ Person</span>
                              </div>
                            </li>
                            <li>
                              <Popover
                                placement="bottomLeft"
                                arrow={false}
                                overlayClassName="options-list-popover"
                                content={
                                  "The Tower of London, officially His Majesty's Royal Palace and Fortress of the Tower of London, is a historic castle on the north bank of the River Thames in central London, England"
                                }
                                title="London Eye"
                              >
                                <Checkbox>London Eye</Checkbox>
                              </Popover>
                              <div className="right-col">
                                <span className="off-price">AED 523</span>
                                $50 <span>/ Person</span>
                              </div>
                            </li>
                          </ul>
                        </div> */}
                        {discoverOptions && (
                          <div ref={tourOptionsRef} className="options-colum">
                            <h4>Options</h4>
                            {/* <Checkbox.Group
                              onChange={(checkedValues) => {
                                const option = discoverOptions.find(
                                  (option) =>
                                    option.optionName === checkedValues[0]
                                );
                                option
                                  ? handleCheckboxClick(option)
                                  : handleCheckboxUncheck();
                              }}
                            >
                              <ul>
                                {discoverOptions.map((option, index) => (
                                  <li key={index}>
                                    <Checkbox checked={option.isChecked}>
                                      {option.optionName}
                                    </Checkbox>
                                    <div className="right-col">
                                      <span className="off-price">
                                        {option.price}
                                      </span>
                                      $
                                      {option.pTicketType[0]?.nettPrice ||
                                        "Price not available"}
                                      <span>/ Person</span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </Checkbox.Group> */}
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                              {discoverOptions.map((option, index) => (
                                <li key={index} style={{ position: 'relative' }}>
                                  <Popover
                                    placement="bottomLeft"
                                    arrow={false}
                                    overlayClassName="options-list-popover"
                                    content={
                                      // <span dangerouslySetInnerHTML={{ __html: tourDetails?.description }} />
                                      option.pDescription
                                    }
                                    title={option.optionName}
                                  >
                                    <Checkbox
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          handleCheckboxClick(option, index);
                                        } else {
                                          handleCheckboxUncheck(index);
                                        }
                                      }}
                                      // checked={selectedOption === option && selectedDate !== null}
                                      checked={option.isChecked}
                                    >
                                      {option.optionName.length < 2
                                        ? option.optionName
                                        : `${option.optionName.slice(0, 20)}...`}
                                    </Checkbox>
                                  </Popover>

                                  <div className="right-col">
                                    <span className="off-price">{option.price}</span>
                                    {currencyData?.uCurrency}
                                    {formatCurrency(option.pTicketType[0]?.nettPrice) ||
                                      'Price not available'}
                                    <span>/ Person</span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        {/* Render the Calendar component conditionally */}
                        {/* <OutsideClickHandler
                          onOutsideClick={handleOutsideClick}
                        >
                          {showCalendar && (
                            <div className="calendar-container">
                              <Calendar
                                onChange={handleCalendarChange}
                                value={selectedDate}
                                onActiveStartDateChange={handleMonthChange}
                                tileDisabled={({ date }) =>
                                  isDateDisabled(date)
                                }
                              />
                            </div>
                          )}
                        </OutsideClickHandler> */}
                        {selectedOption && (
                          <>
                            <div className="availability-colum">
                              <h4>Availability</h4>
                              {/* <Row>
                            <Col>
                              <Form.Item name="date" label="DATE">
                                <DatePicker
                                  popupClassName="pickdate-drop"
                                  onChange={handleDateChange}
                                  value={selectedDate}
                                  icon={false}
                                  suffixIcon={false}
                                  placeholder="DD / MM / YYYY"
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item name="time" label="TIME">
                                <Select
                                  value={selectedTimeSlot || null}
                                  onChange={(value) =>
                                    setSelectedTimeSlot(value)
                                  }
                                  placement="bottomRight"
                                  defaultValue="12:00"
                                  popupMatchSelectWidth={false}
                                  popupClassName="timeselect"
                                  suffixIcon={
                                    <SvgIcon
                                      name="down-arrow"
                                      viewbox="0 0 18 9"
                                    />
                                  }
                                  dropdownRender={(menu) => (
                                    <>
                                      <h3 className="title">Pick the Time</h3>
                                      {menu}
                                    </>
                                  )}
                                  options={
                                    availabilityTimes.map((time, index) => ({
                                      value: time,
                                      label: (
                                        <div className="time-row" key={index}>
                                          <div className="time-left">
                                            {time}
                                          </div>
                                        </div>
                                      ),
                                    })) || []
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row> */}
                              <Row>
                                <Col>
                                  <Form.Item name="date" label="DATE">
                                    <Dropdown
                                      menu={{ items: items2 }}
                                      overlayClassName="participants-drop"
                                      trigger={['click']}
                                      visible={dropdownVisible2}
                                      onVisibleChange={(flag) => setDropdownVisible2(flag)}
                                      // visible={dropdownVisible}
                                      // onVisibleChange={(flag) =>
                                      //   setDropdownVisible(flag)
                                      // }
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <Input
                                          value={
                                            selectedDate &&
                                            format(new Date(selectedDate), 'dd/MM/yyyy')
                                          }
                                          readOnly
                                          suffix={<SvgIcon name="down-arrow" viewbox="0 0 18 9" />}
                                          placeholder="DD/MM/YYYY"
                                        />
                                      </div>
                                    </Dropdown>
                                  </Form.Item>
                                </Col>
                                {selectedOption.pTimeSlot && (
                                  <Col>
                                    <Form.Item label="TIME">
                                      <Select
                                        value={selectedTimeSlot}
                                        onChange={(value) => setSelectedTimeSlot(value)}
                                        placement="bottomRight"
                                        popupMatchSelectWidth={false}
                                        popupClassName="timeselect"
                                        suffixIcon={
                                          <SvgIcon name="down-arrow" viewbox="0 0 18 9" />
                                        }
                                        dropdownRender={(menu) => (
                                          <>
                                            <h3 className="title">Pick the Time</h3>
                                            {menu}
                                          </>
                                        )}
                                        options={
                                          selectedOption.pTimeSlot
                                            ? [...new Set(selectedOption.pTimeSlot)]
                                                .sort((a, b) => a.localeCompare(b))
                                                .map((time, index) => ({
                                                  value: time,
                                                  label: (
                                                    <div className="time-row" key={index}>
                                                      <div className="time-left">
                                                        {dayjs(time, 'HH:mm').format('hh:mm A')}
                                                      </div>
                                                    </div>
                                                  ),
                                                }))
                                            : []
                                        }
                                        placeholder="00:00"
                                      />
                                    </Form.Item>
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                <Col>
                                  <Form.Item name="person" label="COUNT">
                                    <Dropdown
                                      menu={{ items }}
                                      overlayClassName="participants-drop"
                                      trigger={['click']}
                                      visible={dropdownVisible}
                                      onVisibleChange={(flag) => setDropdownVisible(flag)}
                                    >
                                      <div style={{ position: 'relative' }}>
                                        <Input
                                          value={selectedData}
                                          readOnly
                                          suffix={<SvgIcon name="down-arrow" viewbox="0 0 18 9" />}
                                        />
                                      </div>
                                    </Dropdown>
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Form.Item name="pcode" label="PROMO CODE">
                                    <Input
                                      value=""
                                      placeholder="Enter"
                                      onChange={handlePromoCodeChange}
                                      onInput={(e) =>
                                        (e.target.value = e.target.value.toUpperCase())
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <div className="price-info">
                              {Boolean(reducedBy) && reducedBy !== 0 && (
                                <>
                                  <Row className="total-row">
                                    <Col className="price-left">Calculated Amount</Col>
                                    <Col className="price-right">
                                      {currencyData?.uCurrency}
                                      <b>{formatCurrency(totalPrice)}</b>{' '}
                                    </Col>
                                  </Row>
                                  <Row className="total-row">
                                    <Col className="price-left">Coupon Amount</Col>
                                    <Col className="price-right">
                                      {currencyData?.uCurrency}
                                      <b> -{formatCurrency(reducedBy)}</b>{' '}
                                    </Col>
                                  </Row>
                                </>
                              )}
                              <Row className="total-row">
                                <Col className="price-left">Grand Total</Col>
                                <Col className="price-right">
                                  {currencyData?.uCurrency}{' '}
                                  <b>{formatCurrency(totalPrice - reducedBy)}</b>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="bottom-action mt-3">
                        <MediaQuery minWidth={767}>
                          <Button
                            type="primary"
                            onClick={handleBookNow}
                            block
                            disabled={!showBtn && userData ? true : false}
                          >
                            Book Now
                          </Button>
                        </MediaQuery>
                        {/* <Button
                          type="link"
                          icon={<SvgIcon name="play" viewbox="0 0 41.93 41.965" />}
                          block
                        >
                          Learn How to book
                        </Button>*/}
                      </div>
                      <div className="getit-touch" data-aos="fade-in" data-aos-duration="1400">
                        <div className="upper-row">
                          <h3>Get in touch</h3>
                        </div>
                        <p>
                          If you have any doubts, please reach out at{' '}
                          <a target="_blank" href="mailto:contact@dyc.global">
                            contact@dyc.global
                          </a>
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="details-experiences">
                {tourDetails &&
                  tourDetails.gProductImageList &&
                  tourDetails?.gProductImageList?.length > 1 && (
                    <div className="gallery-row">
                      <h2>Gallery</h2>
                      <div className="details-images">
                        <div className="showphotos-btn" onClick={showDrawer}>
                          <span>{tourDetails?.gProductImageList?.length}</span> SHOW PHOTOS
                        </div>
                        <div className="left-image">
                          {tourDetails.videoUrl ? (
                            <div className="square-image-container">
                              <iframe
                                width="560"
                                height="315"
                                src={convertToEmbedUrl(tourDetails.videoUrl)}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className="detail-img1"
                              ></iframe>
                            </div>
                          ) : (
                            <Zoom>
                              <div className="square-image-container">
                                <img
                                  src={tourDetails.gProductImageList[0]}
                                  className="detail-img1"
                                  alt="Tour Image 1"
                                />
                              </div>
                            </Zoom>
                          )}
                          {/* <img
                        src={tourDetails.gProductImageList[0]}
                        alt="Tour Image 1"
                      /> */}
                        </div>
                        <div className="right-image">
                          {showAllImages
                            ? tourDetails.gProductImageList.map((image, index) => (
                                <Zoom key={index}>
                                  <div className="square-image-container">
                                    <img
                                      src={image}
                                      alt={`Tour Image ${index + 1}`}
                                      className="detail-img-sub"
                                    />
                                  </div>
                                </Zoom>
                              ))
                            : tourDetails.gProductImageList.slice(1, 5).map((image, index) => (
                                <Zoom key={index}>
                                  <div className="square-image-container">
                                    <img
                                      src={image}
                                      alt={`Tour Image ${index + 2}`}
                                      className="detail-img-sub"
                                    />
                                  </div>
                                </Zoom>
                              ))}
                        </div>
                      </div>
                      {/* <MediaQuery maxWidth={767}>
                            <div className='mobile-details-slider'>
                                <Slider {...detailSlider}>
                                    <div>
                                        <img src={DetailsImg1} alt="Experience London skyline" />
                                    </div>
                                    <div>
                                        <img src={DetailsImg2} alt="Experience London skyline" />
                                    </div>
                                    <div>
                                        <img src={DetailsImg3} alt="Experience London skyline" />
                                    </div>
                                    <div>
                                        <img src={DetailsImg4} alt="Experience London skyline" />
                                    </div>
                                    <div>
                                        <img src={DetailsImg5} alt="Experience London skyline" />
                                    </div>
                                </Slider>
                            </div>
                        </MediaQuery> */}
                    </div>
                  )}
                {tourDetails.operatingHours && tourDetails.operatingHours.length !== 0 && (
                  <div className="operatinghours-row">
                    <h2>Operating Hours</h2>
                    <ul>
                      {tourDetails?.operatingHours.map((operatingHour, index) => (
                        <li key={index}>
                          {operatingHour.day}{' '}
                          <label>
                            {operatingHour.startHour} - {operatingHour.endHour}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* <div className="whatexpect-row">
                  <h2>What to Expect</h2>
                  <ul>
                    <li>
                      <div className="check-circle">
                        <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                      </div>
                      Chance to see the world’s tallest building up-close{" "}
                    </li>
                    <li>
                      <div className="check-circle">
                        <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                      </div>
                      Get access to the observation decks at 124th and 125th
                      levels{" "}
                    </li>
                    <li>
                      <div className="check-circle">
                        <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                      </div>
                      Use the cutting-edge telescopes for closer views of
                      Dubai’s major landmarks{" "}
                    </li>
                    <li>
                      <div className="check-circle">
                        <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                      </div>
                      Safety Verified as it complies with all COVID-19
                      preventive measures imposed by both WHO and the government
                    </li>
                  </ul>
                </div> */}

                {tourDetails?.thingsToNote && tourDetails?.thingsToNote.length !== 0 && (
                  <div className="whatexpect-row thing-note">
                    <h2>Things to Note</h2>
                    <ul>
                      {tourDetails.thingsToNote.map((e) => (
                        <li>
                          <div className="check-circle">
                            <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                          </div>
                          {e}
                        </li>
                      ))}
                      {/* <li>
                      <div className="check-circle">
                        <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                      </div>
                      Check if the package can be customized to your needs.{" "}
                    </li>
                    <li>
                      <div className="check-circle">
                        <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                      </div>
                      Compare the bundled price with individual costs.{" "}
                    </li>
                    <li>
                      <div className="check-circle">
                        <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                      </div>
                      Take note of the package’s usage timeframe.
                    </li> */}
                    </ul>
                  </div>
                )}
                {/* <div className="visitorreview-row">
                  <h2>Visitor Reviews</h2>
                  <Row className="rating-section">
                    <Col sm="6" className="rating-left">
                      <div className="rating-row">
                        <label className="left-label">5 Stars</label>
                        <Progress
                          strokeColor="#18D39E"
                          trailColor="#F7F7F7"
                          size="small"
                          percent={84}
                        />
                      </div>
                      <div className="rating-row">
                        <label className="left-label">4 Stars</label>
                        <Progress
                          strokeColor="#18D39E"
                          trailColor="#F7F7F7"
                          size="small"
                          percent={65}
                        />
                      </div>
                      <div className="rating-row">
                        <label className="left-label">3 Stars</label>
                        <Progress
                          strokeColor="#18D39E"
                          trailColor="#F7F7F7"
                          size="small"
                          percent={40}
                        />
                      </div>
                      <div className="rating-row">
                        <label className="left-label">2 Stars</label>
                        <Progress
                          strokeColor="#18D39E"
                          trailColor="#F7F7F7"
                          size="small"
                          percent={32}
                        />
                      </div>
                      <div className="rating-row">
                        <label className="left-label">1 Stars</label>
                        <Progress
                          strokeColor="#18D39E"
                          trailColor="#F7F7F7"
                          size="small"
                          percent={11}
                        />
                      </div>
                    </Col>
                    <Col sm="6" className="rating-right">
                      <h3>4.5 Out of 5</h3>
                      <div className="rating">
                        <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                        <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                        <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                        <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                        <SvgIcon name="star-outline" viewbox="0 0 15.999 16" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ul className="reviewer-list">
                        {reviewerData
                          .slice(0, showAll ? reviewerData.length : 3)
                          .map((item) => (
                            <li key={item.key}>
                              <div className="reviewer-img">
                                <img src={item.image} alt={item.name} />
                              </div>
                              <h3>{item.title}</h3>
                              <p>{item.description}</p>
                              <label>
                                BY {item.name} / {item.date}
                              </label>
                            </li>
                          ))}
                      </ul>
                      {!showAll &&
                        <div className="allreviewbtn-row">
                          <Button type="primary" ghost onClick={toggleShowAll}>
                            View all Reviews
                          </Button>
                        </div>
                      }
                    </Col>
                  </Row>
                </div> */}

                <div className="address-row">
                  <h2 className="mb-2">Address</h2>
                  <p className="mb-4 pb-2">{tourDetails.areaAddress}</p>
                  {/* <img className="w-100" src={MapAddress} alt="map" /> */}
                  <div className="map-row">
                    <div className="map-area">
                      <iframe
                        className="map-area-detail"
                        title="map"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        src={`https://maps.google.com/maps?q=${tourDetails.latitude},${tourDetails.longitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>
          <DownloadSection />
        </>
      )}
    </div>
  );
};

export default DetailsPage;

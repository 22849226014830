import Lottie from 'react-lottie';
import React from 'react';
import AnimationOne from '../../../assets/json/error.json';
import './index.scss';
import { Button } from 'antd';

export default function UnexpectedError(props) {
  const { onRefreshClick } = props;
  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };

  return (
    <div className="unexpected-error-card-wrapper">
      <div data-aos="fade-in" data-aos-duration="800">
        <Lottie options={animationLottie} />
        <h2>Oh nooo!</h2>
        <p>
          Something went wrong.
          <br />
          Refresh the page or try again later.
        </p>
        <Button type="secondary" onClick={onRefreshClick}>
          Refresh
        </Button>
      </div>
    </div>
  );
}

import Lottie from 'react-lottie';
import React from 'react';
import AnimationOne from '../../../assets/json/empty-offer.json';
import './index.scss';

export default function NoDataFound(props) {
  const { type } = props;

  const getTitle = () => {
    return 'Oops, its empty!';
  };

  const getBody = () => {
    if (type === 'booking') {
      return 'Once you start booking with us,\nwe will make sure it shows up here';
    }
    return 'Start tagging your favourite places to create\nyour perfect bucket list of adventures!';
  };

  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };

  return (
    <div className="empty-data-card-wrapper">
      <div data-aos="fade-in" data-aos-duration="800">
        <Lottie options={animationLottie} height={280} width={280} />
        <h2>{getTitle()}</h2>
        <p>{getBody()}</p>
      </div>
    </div>
  );
}

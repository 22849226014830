import { Button, Card } from 'antd';
import { SvgIcon } from '../index';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './index.scss';
import { formatCurrency } from '../../../utility/formatter';
import { logEvent } from '../../../utility/analytics';

export function TourItemCard(props) {
  const history = useHistory();
  const { item, handleFavToggle } = props;
  const selectedCurrency = useSelector((state) => state.currency.selectedCurrency);

  return (
    <div style={{ position: 'relative' }}>
      <Button
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '30px',
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: '15px',
          right: '15px',
          zIndex: '1',
        }}
        onClick={() => {
          if (item.isFavouriteOffer === 0) {
            if (item.tourId) {
              logEvent('add_to_wishlist', { tour_id: item.tourId });
            } else {
              logEvent('add_to_wishlist', { product_id: item.productId });
            }
          } else {
            if (item.tourId) {
              logEvent('remove_from_wishlist', { tour_id: item.tourId });
            } else {
              logEvent('remove_from_wishlist', { product_id: item.productId });
            }
          }

          handleFavToggle();
        }}
      >
        <SvgIcon
          name="heart"
          viewbox="0 0 10.238 9.131"
          fill={item.isFavouriteOffer === 0 ? '#000' : '#FF5D5D'}
        />
      </Button>
      <Card
        className="tp-item-card"
        key={item.productId}
        cover={
          <img
            alt="TP List"
            className="image-list-card"
            src={item.rTourImage || item.gProductImage}
          />
        }
        // extra={
        //   <Button>
        //     <SvgIcon name="heart" viewbox="0 0 10.238 9.131" />
        //   </Button>
        // }
        onClick={() => {
          if (item.tourId) {
            history.push(`/discover-details?tourId=${item.tourId}`);
          } else {
            history.push(`/global-details?productId=${item?.productId}`);
          }
        }}
      >
        <div className="bottom-row">
          <div className="left-col">
            <h3>{item.tourName || item.productName}</h3>
            <div className="price-col">
              From{' '}
              <span className="bottomprice">
                {selectedCurrency.uCurrency} {formatCurrency(item.adultPrice)}
              </span>{' '}
              / person
              {item.originalPrice && +item.originalPrice !== 0 && (
                <span className="off-price">
                  {selectedCurrency.uCurrency} {formatCurrency(item.originalPrice)}
                </span>
              )}
              {item.orginalPrice && +item.orginalPrice !== 0 && (
                <span className="off-price">
                  {selectedCurrency.uCurrency} {formatCurrency(item.orginalPrice)}
                </span>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

import { useEffect, useState } from 'react';

export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });

    const elem = ref.current;
    if (elem) {
      observer.observe(elem);
    }
    return () => {
      if (elem) {
        observer.unobserve(elem);
      }
      observer.disconnect();
    };
  }, [ref.current]);

  return isIntersecting;
}
